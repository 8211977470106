import { createSlice } from '@reduxjs/toolkit';

const finessRecordSlice = createSlice({
  name: 'fitnessRecords',
  initialState: {
    personalDetails: {
      age: null,
      gender: null,
      weight: {
        value: null,
        unit: 'kg',
      },
      height: {
        value: null,
        unit: 'cm',
      },
      activityLevel: null,
      goal: null,
      expertiseLevel: null,
    },
    mealDetails: {
      breakfast: [],
      lunch: [],
      dinner: [],
    },
    totalCalories: 0,
    breakfastCalories: 0,
    lunchCalories: 0,
    dinnerCalories: 0,
    caloryRequirements: {
      perMealRequirement: 0,
      perMealLowerLimit: 0,
      perMealUpperLimit: 0,
      totalLowerLimit: 0,
      totalUpperLimit: 0,
    },
  },
  reducers: {
    updatePersonalDetails(state, action) {
      state.personalDetails = action.payload;
    },
    updateMealFood(state, action) {
      const { meal, foodId, quantity, unitCalories, unitAmount } =
        action.payload;

      const mealArray = state.mealDetails[meal];
      const index = mealArray.findIndex((item) => item.foodId === foodId);
      if (index !== -1) mealArray[index].quantity = quantity;
      else mealArray.push({ foodId, quantity, unitCalories, unitAmount });

      let calories = 0;
      for (const item of mealArray) {
        calories += (item.quantity * item.unitCalories) / item.unitAmount;
      }
      state[`${meal}Calories`] = calories;
      state.totalCalories =
        state.breakfastCalories + state.lunchCalories + state.dinnerCalories;
    },
    removeMealFood(state, action) {
      const { meal, foodId } = action.payload;
      const mealArray = state.mealDetails[meal];
      const index = mealArray.findIndex((item) => item.foodId === foodId);
      if (index === -1) return;
      mealArray.splice(index, 1);

      let calories = 0;
      for (const item of mealArray) {
        calories += item.quantity * item.unitCalories;
      }
      state[`${meal}Calories`] = calories;
      state.totalCalories =
        state.breakfastCalories + state.lunchCalories + state.dinnerCalories;
    },
    updateCaloriesRequirenment(state, action) {
      const { perMealRequirement, totalLowerLimit, totalUpperLimit } =
        action.payload;

      state.caloryRequirements.perMealRequirement = perMealRequirement;
      state.caloryRequirements.perMealLowerLimit = Number(
        totalLowerLimit / 3
      ).toFixed(2);
      state.caloryRequirements.perMealUpperLimit = Number(
        totalUpperLimit / 3
      ).toFixed(2);

      state.caloryRequirements.totalLowerLimit = totalLowerLimit;
      state.caloryRequirements.totalUpperLimit = totalUpperLimit;
    },
    resetMeals(state, action) {
      state.mealDetails = {
        breakfast: [],
        lunch: [],
        dinner: [],
      };
      state.totalCalories = 0;
      state.breakfastCalories = 0;
      state.lunchCalories = 0;
      state.dinnerCalories = 0;
    },
  },
});

export const finessRecordsActions = finessRecordSlice.actions;
export default finessRecordSlice;
