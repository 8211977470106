import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getApi } from '../../utils/axios';
import { popAlert } from '../../utils/alert';
import ProfileImg from '../profile-card/ProfileImg';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/authSlice';

const SingleTrainerCard = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userstate = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);

  const isTrainerAssigned = () => {
    return userstate?.client?.trainers?.includes(data.id);
  };
  const handleAssignTrainer = async () => {
    setIsLoading(true);
    if (data.id) {
      getApi()
        .patch(`/users/trainers/${data.id}`)
        .then((res) => {
          if (res.data) dispatch(authActions.setUser(res.data));
          setIsLoading(false);
        })
        .catch((err) => {
          popAlert('Error!', err.response?.data?.message, 'error', 'OK');
        });
    }
  };

  const handleUnAssignTrainer = async () => {
    setIsLoading(true);
    if (data.id) {
      getApi()
        .delete(`/users/trainers/${data.id}`)
        .then((res) => {
          if (res.data) dispatch(authActions.setUser(res.data));
          setIsLoading(false);
        })
        .catch((err) => {
          popAlert('Error!', err.response?.data?.message, 'error', 'OK');
        });
    }
  };

  return (
    <Card
      sx={{
        // maxWidth: 445,
        borderRadius: 1,
        backgroundColor: '#373736',
        padding: '1rem 1rem',
      }}
    >
      <CardActionArea>
        <CardContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ProfileImg
            data={{
              size: '120px',
              image: data?.image,
              fullName: `${data.name}`,
            }}
          />
        </CardContent>
        <CardContent sx={{ backgroundColor: '#373736', textAlign: 'center' }}>
          <Typography
            gutterBottom
            variant="h6"
            color="white"
            style={{ fontWeight: 'bold', margin: 0 }}
          >
            {data.name}
          </Typography>
          <Typography gutterBottom variant="h6" color="white">
            {data.experience} Years Experience
          </Typography>

          {isTrainerAssigned() ? (
            <Button
              style={{
                color: '#d33',
                border: '1px solid #d33',
                width: '100%',
                borderRadius: '10px',
                margin: '10px 0px 10px 0px',
              }}
              onClick={handleUnAssignTrainer}
            >
              Remove Me
              {isLoading && (
                <CircularProgress
                  color="error"
                  size={20}
                  style={{ marginLeft: '10px' }}
                />
              )}
            </Button>
          ) : (
            <Button
              style={{
                color: '#a0e220',
                border: '1px solid #a0e220',
                width: '100%',
                borderRadius: '10px',
                margin: '10px 0px 10px 0px',
              }}
              onClick={handleAssignTrainer}
            >
              Train With Me
              {isLoading && (
                <CircularProgress
                  color="primary"
                  size={20}
                  style={{ marginLeft: '10px' }}
                />
              )}
            </Button>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default SingleTrainerCard;
