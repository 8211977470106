import {
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { getApi } from '../../utils/axios';
import axios from 'axios';
import mealPlan from '../../assets/meal-plan.jpg';
import Workout from '../../assets/workout-routine.jpg';
import ClientProfileCard from '../../components/profile-card/ClientProfileCard';
import { capitalizeWords } from '../../utils/helpers';

const Container = styled('div')({
  margin: '3rem 9rem',
  '@media (max-width:600px)': {
    margin: '1rem 2rem',
  },
  '@media (max-width:480px)': {
    margin: '1rem',
  },
  '@media (max-width:1391px)': {
    margin: '1rem',
  },
});

const styles = {
  itemValue: {
    backgroundColor: '#1D1D1D',
    color: '#a0e220',
    padding: '0.5rem',
  },
  itemHeading: { backgroundColor: '#1D1D1D', textAlign: 'center' },
  itemHeadingContainer: {
    backgroundColor: '#1D1D1D',
    color: 'white',
    padding: '0.5rem',
  },
};

const ClientProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [client, setClient] = useState();
  const [clientMealDetails, setClientMealDetails] = useState();

  const roundedBMR = client?.calculatedMetrics?.bmr.toFixed(1);
  const roundedDCL = client?.calculatedMetrics?.dci.toFixed(1);

  useEffect(() => {
    const source = axios.CancelToken.source();
    //
    getApi()
      .get(`/trainer-request/${id}`)
      .then((res) => setClient(res?.data?.data))
      .catch((err) => console.error(err?.response?.data?.message));

    return () => {
      source.cancel();
    };
  }, [id]);
  //
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (client) {
      getApi()
        .get(`/meal-plan/by-trainer/${client?.clientId}`)
        .then((res) => setClientMealDetails(res?.data?.data))
        .catch((err) => console.error(err?.response?.data?.message));
    }

    return () => {
      source.cancel();
    };
  }, [client]);

  return (
    <div>
      <Container>
        <Typography
          variant="h4"
          sx={{
            marginBottom: 4,
            color: '#a0e220',
            fontWeight: 'bold',
          }}
        >
          {capitalizeWords(client?.fullName)}
          's Profile
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={5} md={6}>
            <ClientProfileCard
              user={{
                name: capitalizeWords(client?.fullName),
                from: 'ToDo',
                mobile: 'ToDo',
                email: 'ToDo',
                image: '',
                goal: client?.goal,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={7} md={6}>
            <Card
              sx={{
                backgroundColor: '#373736',
                padding: '1.5rem 1rem',
                height: '90%',
              }}
            >
              {!clientMealDetails ? (
                <Card
                  sx={{
                    backgroundColor: '#373736',
                    padding: '1.5rem 1rem',
                    border: 'dotted',
                    borderColor: '#818180',
                    height: '90%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        mt: 1,
                        backgroundImage: '#a0e220',
                        color: 'black',
                        '&:hover': {
                          backgroundImage: '#a0e220',
                        },
                        fontWeight: 'bold',
                        padding: '0.7rem 3rem',
                        width: '50%',
                      }}
                      onClick={() => {
                        navigate(`/client/profile/${client?._id}`);
                      }}
                    >
                      <Typography>GET STARTED</Typography>
                    </Button>
                    <Typography
                      variant="p"
                      sx={{
                        margin: 4,
                        color: 'white',
                        textAlign: 'center',
                        lineHeight: '1.5rem',
                      }}
                    >
                      By getting started, you can create a meal plan and workout
                      plan for your client, as well as view their details.{' '}
                    </Typography>
                  </div>
                </Card>
              ) : (
                <div style={{ paddingBottom: '20px' }}>
                  <Card
                    sx={{
                      borderRadius: '20px',
                      backgroundColor: '#1D1D1D',
                    }}
                  >
                    <Card
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: '#1D1D1D',
                        padding: '2rem',
                        borderRadius: '20px',
                      }}
                    >
                      <div style={styles.itemHeading}>
                        <div style={styles.itemHeadingContainer}>BMR</div>
                        <div style={styles.itemValue}>{`${roundedBMR}Cal`}</div>
                      </div>
                      <Divider
                        orientation="vertical"
                        variant="fullWidth"
                        flexItem
                        sx={{ backgroundColor: '#4d4c4c' }}
                      />
                      <div style={styles.itemHeading}>
                        <div style={styles.itemHeadingContainer}>
                          Daily Calorie Intake
                        </div>
                        <div style={styles.itemValue}>{`${roundedDCL}Cal`}</div>
                      </div>
                    </Card>
                  </Card>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingTop: '2rem',
                      marginTop: '1rem',
                    }}
                  >
                    <Grid container spacing={6}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Card
                          style={{ borderRadius: 10 }}
                          onClick={() => {
                            navigate(`/meal-plan/${id}/${client.clientId}`);
                          }}
                        >
                          <div style={{ position: 'relative' }}>
                            <CardMedia
                              component="img"
                              height="200"
                              image={mealPlan}
                              alt="Card 1"
                            />
                            <Typography
                              variant="h6"
                              component="div"
                              style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                background:
                                  'linear-gradient(to bottom, #a0e220, rgba(112,179,128,0.8))',
                                color: 'black',
                                padding: '10px',
                                boxSizing: 'border-box',
                                textAlign: 'center',
                              }}
                            >
                              Meal Plan
                            </Typography>
                          </div>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Card
                          style={{ borderRadius: 10 }}
                          onClick={() => {
                            navigate(`/workout-plan/${id}/${client.clientId}`);
                          }}
                        >
                          <div style={{ position: 'relative' }}>
                            <CardMedia
                              component="img"
                              height="200"
                              image={Workout}
                              alt="Card 1"
                            />
                            <Typography
                              variant="h6"
                              component="div"
                              style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                background:
                                  'linear-gradient(to bottom, #a0e220, rgba(112,179,128,0.8))',
                                color: 'black',
                                padding: '10px',
                                boxSizing: 'border-box',
                                textAlign: 'center',
                              }}
                            >
                              Workout Routine
                            </Typography>
                          </div>
                        </Card>
                      </Grid>
                    </Grid>
                  </div>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'right',
                      paddingTop: '2rem',
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ color: '#a0e220', cursor: 'pointer' }}
                      onClick={() => {
                        navigate(`/client/profile/${client?._id}`);
                      }}
                    >
                      See client's details ➡️
                    </Typography>
                  </Box>
                </div>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ClientProfile;
