import { Card, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container } from '../../components/commonStyle';
import UserProfileCard from '../../components/profile-card/UserProfileCard';
import YourClientCard from '../../components/profile-card/YourClientCard';
import Portfolio from '../../components/trainer-info/Portfolio';
import PortfolioPending from '../../components/trainer-info/PortfolioPending';
import Rejected from '../../components/trainer-info/Rejected';
import VerificationSubmitted from '../../components/trainer-info/VerificationSubmitted';
import { getApi } from '../../utils/axios';

const TrainerProfile = () => {
  const userState = useSelector((state) => state?.auth?.user);
  const [clients, setClients] = useState([]);

  const formattedDate = new Date(userState?.createdAt).toLocaleString('en-US', {
    month: 'short',
    year: 'numeric',
  });

  useEffect(() => {
    const fetchClients = async () => {
      try {
        if (userState?.clientIds?.length) {
          const clientData = await getApi().get(`/trainer-request`);
          setClients(clientData.data.data);
        }
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
    };
    fetchClients();
  }, [userState?.clientIds]);

  console.log(clients);

  const isFirstTimeVisit = (userState) => {
    return (
      !userState?.portfolioFileData?.length &&
      !userState?.certificationFileUrl &&
      !userState?.trainingFor &&
      !userState?.yearsOfExperience
    );
  };

  const hasPendingPortfolio = (userState) => {
    return (
      !userState?.portfolioFileData?.length &&
      userState?.certificationFileUrl &&
      userState?.trainingFor &&
      userState?.yearsOfExperience
    );
  };

  const hasCompleteProfile = (userState) => {
    return (
      userState?.portfolioFileData?.length > 0 &&
      userState?.certificationFileUrl &&
      userState?.trainingFor &&
      userState?.yearsOfExperience
    );
  };

  const isProfileRejected = (userState) => {
    return userState?.trainer?.isRejected;
  };

  return (
    <div style={{ minHeight: '1vh' }}>
      <Container>
        <Typography
          variant="h4"
          sx={{
            marginBottom: 4,
            color: '#a0e220',
            fontWeight: 'bold',
          }}
        >
          Trainer Profile
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={12} md={5} sm={12}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <UserProfileCard
                  user={{
                    name: `${userState?.firstName} ${userState?.lastName} `,
                    from: userState?.address,
                    image: userState?.image?.firebaseStorageRef,
                    since: formattedDate,
                    type: userState?.role,
                    profileImg: userState.profileImageFileUrl,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={5} sx={{ mt: 0 }}>
                  {clients.length > 0 && (
                    <Grid item md={12} lg={12} sm={12}>
                      <Card
                        sx={{
                          backgroundColor: '#373736',
                          padding: '1.5rem 1rem',
                        }}
                      >
                        <Typography
                          sx={{ color: 'white', ml: 2, fontSize: '20px' }}
                        >
                          Your Clients
                        </Typography>
                        {clients.length === 0 ? (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginTop: '2rem',
                              flexDirection: 'column',
                              paddingBottom: '1rem',
                            }}
                          >
                            <Typography
                              sx={{ color: 'white', ml: 2 }}
                              variant="h6"
                            >
                              No Clients History
                            </Typography>
                          </div>
                        ) : (
                          <div
                            style={{
                              marginTop: '1rem',
                              width: '100%',
                              overflowX: 'scroll',
                              overflowY: 'hidden',
                              scrollbarWidth: 'none',
                              '-ms-overflow-style': 'none',
                            }}
                          >
                            <Grid
                              container
                              spacing={1}
                              style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                flexDirection: 'row',
                              }}
                            >
                              {clients.map((client, index) => (
                                <Grid item key={index}>
                                  <YourClientCard
                                    data={{
                                      name: client?.fullName,
                                      image: client?.image?.firebaseStorageRef,
                                      client: client,
                                    }}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </div>
                        )}
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7} sm={12}>
            <Card
              sx={{
                backgroundColor: '#373736',
                padding: '1.5rem 2rem',
                height: '90%',
              }}
            >
              {isFirstTimeVisit(userState) && <VerificationSubmitted />}
              {hasPendingPortfolio(userState) && <PortfolioPending />}
              {hasCompleteProfile(userState) && (
                <Portfolio
                  image={
                    Array.isArray(userState?.portfolioFileData) &&
                    userState?.portfolioFileData
                  }
                  trainerId={userState?._id}
                />
              )}
              {isProfileRejected(userState) && <Rejected />}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default TrainerProfile;
