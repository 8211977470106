import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import img2 from '../../assets/v2/landing-top-background.jpg';
import ResponsiveGridLayout from '../../components/landing-page/ResponsiveGridLayout';
import { Container } from '../../components/commonStyle';
import { useMediaQuery, useTheme } from '@mui/material';

function Landing() {
  const theme = useTheme();
  const navigate = useNavigate();

  const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div>
      <Card sx={{ width: '100%' }}>
        <CardMedia
          component="div"
          image={img2}
          alt="fitnessguru-landing"
          sx={{
            position: 'relative',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1,
            },
          }}
        >
          <CardContent
            sx={{
              position: 'relative',
              zIndex: 2,
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h1"
              component="div"
              sx={{
                color: 'white',
                fontSize: '7.2vw',
                letterSpacing: matchesXs ? 1 : matchesSm ? 1 : 1,
                fontWeight: 'bold',
                textShadow: {
                  xs: '1px 1px 5px rgba(0, 0, 0, 0.5)',
                  sm: '1.5px 1.5px 6px rgba(0, 0, 0, 0.5)',
                  md: '2px 2px 7px rgba(0, 0, 0, 0.5)',
                  lg: '2.5px 2.5px 8px rgba(0, 0, 0, 0.5)',
                  xl: '3px 3px 9px rgba(0, 0, 0, 0.5)',
                },
                textTransform: 'uppercase',
                textAlign: 'center',
              }}
            >
              Empowering trainers <br />
              Transforming lives
            </Typography>

            <Typography
              sx={{
                color: 'white',
                fontSize: 'clamp(1rem, 2vw, 4rem)',
                textTransform: 'capitalize',
                mb: '2rem',
                fontStyle: 'italic',
                color: '#a0e220',
                textShadow: '2px 2px 9px rgba(0, 0, 0, 0.5)',
              }}
            >
              Join the revolution in Sri Lankan's fitness industry
            </Typography>

            <Button
              variant="contained"
              sx={{
                mt: 1,
                backgroundColor: '#a0e220',
                color: 'black',
                '&:hover': {
                  backgroundImage: '#a0e220',
                },
                fontWeight: 'bold',
                padding: '0.7rem 3rem',
              }}
              onClick={() => navigate('/trainer-profile')}
            >
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: 'bold',
                  fontSize: matchesXs ? '1rem' : matchesSm ? '0.8rem' : '1.3vw',
                  width: '18vw',
                }}
              >
                get started
              </Typography>
            </Button>
          </CardContent>
        </CardMedia>
      </Card>
      <Container>
        <ResponsiveGridLayout />
      </Container>
    </div>
  );
}

export default Landing;
