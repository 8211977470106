import {
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserProfileCard from '../../components/profile-card/UserProfileCard';
import { getApi } from '../../utils/axios';
import axios from 'axios';
import YourTrainerCard from '../../components/trainer-card/YourTrainerCard';
import mealPlanIcon from '../../assets/Meal-icon.png';
import WorkoutIcon from '../../assets/Workout-Icon.png';
import mealPlan from '../../assets/meal-plan.jpg';
import Workout from '../../assets/workout-routine.jpg';
import Pagination from '@mui/material/Pagination';
import { useSelector } from 'react-redux';
import ProfileImg from '../../components/profile-card/ProfileImg';

const Container = styled('div')({
  margin: '3rem 9rem',
  '@media (max-width:600px)': {
    margin: '1rem 2rem',
  },
  '@media (max-width:480px)': {
    margin: '1rem',
  },
  '@media (max-width:1391px)': {
    margin: '1rem',
  },
});

const styles = {
  itemValue: {
    backgroundColor: '#1D1D1D',
    color: '#a0e220',
    padding: '0.5rem',
  },
  itemHeading: { backgroundColor: '#1D1D1D', textAlign: 'center' },
  itemHeadingContainer: {
    backgroundColor: '#1D1D1D',
    color: 'white',
    padding: '0.5rem',
  },
};

const Profile = () => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.auth.user);
  const [latestRecord, setLatestRecord] = useState();
  const [previousActivities, setPreviousActivities] = useState([]);
  const [trainers, setTrainers] = useState();
  const [supplements, setSupplements] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const formattedDate = new Date(userState?.createdAt).toLocaleString('en-US', {
    month: 'short',
    year: 'numeric',
  });
  const formattedDate2 = new Date(latestRecord?.createdAt).toLocaleDateString(
    'en-US',
    {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }
  );
  const roundedBMR = latestRecord?.BMR.toFixed(1);
  const roundedDCL = latestRecord?.DCL.toFixed(1);
  //
  const handlePFRecordChange = (event, value) => {
    setPage(value);
  };
  //
  useEffect(() => {
    const source = axios.CancelToken.source();
    //
    getApi()
      .get(`fitness-data/latest/users/${userState?._id}`)
      .then((res) => setLatestRecord(res.data))
      .catch((err) => {
        console.error(err?.response?.data?.message);
      });
    getApi()
      .get('/users/trainers')
      .then((res) => {
        setTrainers(res?.data);
      })
      .catch((err) => {
        console.error(err?.response?.data?.message);
      });
    //
    getApi()
      .get(
        `fitness-data/previous/users/${userState?._id}?page=${page}&limit=4&orderBy=desc`
      )
      .then((res) => {
        setPreviousActivities(res?.data?.content);
        setTotalPages(res?.data?.totalPages || 0);
      })
      .catch((err) => {
        console.error(err?.response?.data?.message);
      });

    getApi()
      .get(`products?keyword=&type=supplement&page=1&limit=3&orderBy=desc`)
      .then((res) => setSupplements(res?.data?.content))
      .catch((err) => {
        console.error(err?.response?.data?.message);
      });

    return () => {
      source.cancel();
    };
  }, [page]);
  //
  useEffect(() => {
    const source = axios.CancelToken.source();
    async function fetchLatestRecord() {
      if (userState) {
        try {
          const res = await getApi().get(
            `fitness-data/latest/users/${userState?._id}`
          );
          setLatestRecord(res.data);
        } catch (err) {
          console.error('Error!', err?.response?.data?.message, 'error', 'ok');
        }
      }
    }
    fetchLatestRecord();
    return () => {
      source.cancel();
    };
  }, [userState]);
  return (
    <div>
      <Container>
        <Typography
          variant="h4"
          sx={{
            marginBottom: 4,
            color: '#a0e220',
            fontWeight: 'bold',
          }}
        >
          Client Profile
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={5}>
            <UserProfileCard
              user={{
                name: `${userState?.firstName} ${userState?.lastName} `,
                from: userState?.address,
                image: userState?.image?.firebaseStorageRef,
                since: formattedDate,
                type: userState?.type,
                ...(latestRecord?.goal && { goal: latestRecord.goal }), // only pass goal if it exists
              }}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Card
              sx={{
                backgroundColor: '#373736',
                padding: '1.5rem 1rem',
                height: '91%',
              }}
            >
              {latestRecord === null ? (
                <Card
                  sx={{
                    backgroundColor: '#373736',
                    padding: '1.5rem 1rem',
                    border: 'dotted',
                    borderColor: '#818180',
                    height: '90%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        mt: 1,
                        backgroundImage: '#a0e220',
                        color: 'black',
                        '&:hover': {
                          backgroundImage: '#a0e220',
                        },
                        fontWeight: 'bold',
                        padding: '0.7rem 3rem',
                        width: '50%',
                      }}
                      href="/check-injuries"
                    >
                      <Typography>GET STARTED</Typography>
                    </Button>
                    <Typography
                      variant="p"
                      sx={{
                        margin: 4,
                        color: 'white',
                        textAlign: 'center',
                        lineHeight: '1.5rem',
                      }}
                    >
                      By getting started, you will get to choose your preferred
                      meal plane according to your body statistics and you will
                      be provided with a Workout Routing based on your expertise
                      level.{' '}
                    </Typography>
                  </div>
                </Card>
              ) : (
                <div style={{ paddingBottom: '20px' }}>
                  <Card
                    sx={{
                      borderRadius: '20px',
                      backgroundColor: '#1D1D1D',
                    }}
                  >
                    <Card
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: '#1D1D1D',
                        padding: '2rem',
                        borderRadius: '20px',
                      }}
                    >
                      <div style={styles.itemHeading}>
                        <div style={styles.itemHeadingContainer}>Date</div>
                        <div style={styles.itemValue}>{formattedDate2}</div>
                      </div>
                      <Divider
                        orientation="vertical"
                        variant="fullWidth"
                        flexItem
                        sx={{ backgroundColor: '#4d4c4c' }}
                      />
                      <div style={styles.itemHeading}>
                        <div style={styles.itemHeadingContainer}>BMR</div>
                        <div style={styles.itemValue}>{`${roundedBMR}Cal`}</div>
                      </div>
                      <Divider
                        orientation="vertical"
                        variant="fullWidth"
                        flexItem
                        sx={{ backgroundColor: '#4d4c4c' }}
                      />
                      <div style={styles.itemHeading}>
                        <div style={styles.itemHeadingContainer}>
                          Daily Calorie Intake
                        </div>
                        <div style={styles.itemValue}>{`${roundedDCL}Cal`}</div>
                      </div>
                    </Card>
                  </Card>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingTop: '2rem',
                      marginTop: '1rem',
                    }}
                  >
                    <Grid container spacing={6}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Card
                          style={{ borderRadius: 10, cursor: 'pointer' }}
                          onClick={() => {
                            navigate(
                              `/meal-plan/${latestRecord?._id}/${userState._id}`
                            );
                          }}
                        >
                          <div style={{ position: 'relative' }}>
                            <CardMedia
                              component="img"
                              height="200"
                              image={mealPlan}
                              alt="Card 1"
                            />
                            <Typography
                              variant="h6"
                              component="div"
                              style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                background:
                                  'linear-gradient(to bottom, #a0e220, rgba(112,179,128,0.8))',
                                color: '#fff',
                                padding: '10px',
                                boxSizing: 'border-box',
                                textAlign: 'center',
                              }}
                            >
                              Meal Plan
                            </Typography>
                          </div>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Card
                          style={{ borderRadius: 10, cursor: 'pointer' }}
                          onClick={() => {
                            navigate(
                              `/workout-plan/${latestRecord?._id}/${latestRecord?.expertiseLevel}/${userState._id}`
                            );
                          }}
                        >
                          <div style={{ position: 'relative' }}>
                            <CardMedia
                              component="img"
                              height="200"
                              image={Workout}
                              alt="Card 1"
                            />
                            <Typography
                              variant="h6"
                              component="div"
                              style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                background:
                                  'linear-gradient(to bottom, #a0e220, rgba(112,179,128,0.8))',
                                color: '#fff',
                                padding: '10px',
                                boxSizing: 'border-box',
                                textAlign: 'center',
                              }}
                            >
                              Workout Routine
                            </Typography>
                          </div>
                        </Card>
                      </Grid>
                    </Grid>
                  </div>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: 'right',
                      color: '#a0e220',
                      mt: 4,
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      navigate('/personal-info', { state: latestRecord })
                    }
                  >
                    Update Body Statistics
                  </Typography>
                </div>
              )}
            </Card>
          </Grid>
        </Grid>
        {latestRecord !== null ? (
          <>
            <Grid container spacing={5} sx={{ mt: 0 }}>
              <Grid item xs={12} sm={5}>
                <Card
                  sx={{
                    backgroundColor: '#373736',
                    padding: '2rem 1rem',
                    height: '90%',
                  }}
                >
                  <Typography sx={{ color: 'white', ml: 2, fontSize: '20px' }}>
                    Your Trainers
                  </Typography>
                  {userState?.client?.trainers.length === 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '2rem',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography
                        sx={{ color: 'white', ml: 2, fontSize: '13px' }}
                      >
                        No Trainer History
                      </Typography>
                      <Button
                        style={{
                          color: '#a0e220',
                          border: '1px solid #a0e220',
                          width: '50%',
                          borderRadius: '10px',
                          margin: '10px 0px 0px 0px',
                        }}
                        onClick={() => navigate('/trainers')}
                      >
                        Search Trainers
                      </Button>
                    </div>
                  ) : (
                    // TODO:height should be fixed and should be added horizontal scrolling
                    <div
                      style={{
                        marginTop: '1rem',
                        width: '100%',
                        overflowX: 'scroll',
                        overflowY: 'hidden',
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                        style={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          flexDirection: 'row',
                        }}
                      >
                        {trainers?.map((trainer, index) => {
                          return (
                            <Grid item key={index}>
                              <YourTrainerCard
                                data={{
                                  name:
                                    trainer?.firstName +
                                    ' ' +
                                    trainer?.lastName,
                                  date: '22 Feb 2023',
                                  trainer: trainer,
                                }}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  )}
                </Card>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Card
                  sx={{
                    backgroundColor: '#373736',
                    padding: '1.5rem 1rem',
                    height: '93%',
                  }}
                >
                  <Typography sx={{ color: 'white', ml: 2, fontSize: '20px' }}>
                    My Dashboard
                  </Typography>
                  {previousActivities.length === 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '2rem',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography
                        sx={{ color: 'white', ml: 2, fontSize: '13px' }}
                      >
                        No Previous Activities
                      </Typography>
                    </div>
                  ) : (
                    // NOTE: MY DASHBOARD
                    <>
                      {previousActivities?.map((activity, index) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              backgroundColor: '#1D1D1D',
                              p: 2,
                              borderRadius: '10px',
                              display: 'flex',
                              m: 2,
                              flexDirection: 'column',
                            }}
                          >
                            <Box sx={{ textAlign: 'left' }}>
                              <Typography sx={{ color: 'white', pb: 1 }}>
                                {new Date(activity?.createdAt).toLocaleString(
                                  'en-US',
                                  {
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric',
                                  }
                                )}
                              </Typography>
                            </Box>
                            <Grid container spacing={1}>
                              <Grid
                                item
                                sx={5}
                                md={5}
                                lg={5}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundColor: 'white',
                                    padding: 1,
                                    borderRadius: '10px',
                                    minWidth: '150px',
                                  }}
                                >
                                  <Typography sx={{ fontSize: '15px' }}>
                                    BMR :{' '}
                                    <span style={{ color: '#a0e220' }}>
                                      {activity?.BMR.toFixed(1)}Cal
                                    </span>
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid
                                item
                                sx={5}
                                md={5}
                                lg={5}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Box
                                  sx={{
                                    backgroundColor: 'white',
                                    padding: 1,
                                    borderRadius: '10px',
                                    minWidth: '150px',
                                  }}
                                >
                                  <Typography sx={{ fontSize: '15px' }}>
                                    DCL :{' '}
                                    <span style={{ color: '#a0e220' }}>
                                      {activity?.DCL}Cal
                                    </span>
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item sx={2} md={2} lg={2}>
                                <Tooltip title="Meal Plan" placement="top">
                                  <img
                                    src={mealPlanIcon}
                                    style={{
                                      width: 30,
                                      marginRight: 15,
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      navigate(
                                        `/meal-plan/${activity?._id}/${userState?._id}`
                                      );
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip
                                  title="Workout Routine"
                                  placement="top"
                                >
                                  <img
                                    src={WorkoutIcon}
                                    style={{ width: 30, cursor: 'pointer' }}
                                    onClick={() => {
                                      navigate(
                                        `/workout-plan/${activity?._id}/${activity?.expertiseLevel}/${userState?._id}`
                                      );
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      })}
                    </>
                    // *****************************************************************************************************
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      marginTop: '20px',
                    }}
                  >
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handlePFRecordChange}
                      fontWeight={'bold'}
                      color="primary"
                      shape="rounded"
                    />
                  </div>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={5} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={5}>
                <Card
                  sx={{
                    backgroundColor: '#373736',
                    padding: '1.5rem 1rem',
                    height: '90%',
                  }}
                >
                  <Typography sx={{ color: 'white', ml: 2, fontSize: '20px' }}>
                    Recommended Supplements
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      marginTop: '2rem',
                      flexDirection: 'row',
                    }}
                  >
                    {supplements?.map((suplement, index) => {
                      return (
                        <ProfileImg
                          key={index}
                          data={{
                            size: '100px',
                            image: suplement?.image?.firebaseStorageRef,
                            fullName: `${suplement?.name}`,
                          }}
                        />
                      );
                    })}
                  </div>
                  <Button
                    style={{
                      color: '#a0e220',
                      border: '1px solid #a0e220',
                      width: '100%',
                      borderRadius: '10px',
                      margin: '20px 0px 0px 0px',
                    }}
                    onClick={() => navigate('/shop')}
                  >
                    Shop now
                  </Button>
                </Card>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Container>
    </div>
  );
};

export default Profile;
