import React, { useEffect, useState } from 'react';
import { getApi } from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from '../../store/authSlice';
import { popAlert } from '../../utils/alert';
import {
  Grid,
  Paper,
  Typography,
  OutlinedInput,
  Button,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import loginImg from '../../assets/login-img.png';
import { resetWorkout } from '../../store/workoutSlice';
import { finessRecordsActions } from '../../store/finessRecordSlice';

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  //
  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    const api = getApi();
    api
      .post('/trainer/login', { email, password })
      .then((res) => {
        const user = res?.data?.trainer?.role || 'Trainer';
        popAlert('Success!', res?.data?.message, 'success', 'Ok').then(() => {
          dispatch(authActions.login(res?.data?.data));
          dispatch(resetWorkout());
          dispatch(finessRecordsActions.resetMeals());
          if (user === 'admin') navigate('/admin/dashboard');
          else navigate('/home');
        });
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          popAlert('Error!', err.response.data.message, 'error', 'Ok');
        } else {
          popAlert(
            'Error!',
            'Something went wrong. Please try again',
            'error',
            'Ok'
          );
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <Grid container spacing={0} style={{ minHeight: '100vh' }}>
        <Grid item xs={12} sm={5} md={7}>
          <Paper
            sx={{
              height: '100%',
              backgroundImage: `url(${loginImg})`,
              backgroundSize: 'cover',
            }}
          ></Paper>
        </Grid>
        <Grid item xs={12} sm={7} md={5}>
          <Paper
            sx={{
              padding: '0 24px ',
              bgcolor: '#1d1d1d',
              height: '100%',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                marginBottom: 4,
                paddingTop: 2,
                color: '#a0e220',
                fontWeight: 'bold',
              }}
            >
              LOGIN FORM
            </Typography>
            <form
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <InputLabel
                htmlFor="email"
                sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
              >
                Email
              </InputLabel>
              <OutlinedInput
                placeholder="Enter your email"
                id="email"
                name="email"
                required
                sx={{
                  borderRadius: 4,
                  width: '100%',
                  marginBottom: 2,
                  backgroundColor: '#373736', // set the background color
                  '& label.Mui-focused': { color: '#008000' },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#008000',
                  },
                  '& input::placeholder': { color: '#7b7b7b' },
                  '& input': { color: '#7b7b7b' },
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
              <InputLabel
                htmlFor="password"
                sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
              >
                Password
              </InputLabel>
              <OutlinedInput
                placeholder="Enter your password"
                type="password"
                id="password"
                name="password"
                required
                sx={{
                  borderRadius: 4,
                  border: 'none',
                  width: '100%',
                  marginBottom: 2,
                  backgroundColor: '#373736', // set the background color
                  '& label.Mui-focused': { color: '#008000' },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#008600',
                  },
                  '& input::placeholder': { color: '#7b7b7b' },
                  '& input': { color: '#7b7b7b' },
                }}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                variant="contained"
                sx={{
                  padding: '12px 24px',
                  backgroundImage: '#a0e220',
                  color: 'black',
                  '&:hover': {
                    backgroundImage: '#a0e220',
                  },
                  borderRadius: 4,
                  fontWeight: 'bold',
                  width: '100%',
                  mt: 2,
                }}
                onClick={submitHandler}
              >
                Login
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: 'black',
                      position: 'absolute',
                      left: '60%',
                      top: '50%',
                      marginLeft: '-12px', // Half of the size
                      marginTop: '-12px', // Half of the size
                    }}
                  />
                )}
              </Button>
              <Grid container spacing={1} style={{ justifyContent: 'center' }}>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      marginTop: 1,
                      color: '#8e8e8f',
                    }}
                  >
                    Not a member yet?
                  </Typography>
                </Grid>
                <Grid item>
                  <a href="/register" style={{ textDecoration: 'none' }}>
                    <Typography
                      noWrap
                      variant="subtitle1"
                      sx={{
                        marginTop: 1,
                        color: '#a0e220',
                      }}
                    >
                      Register{' '}
                    </Typography>
                  </a>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginPage;
