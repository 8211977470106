import { Box, Card, CardMedia, Grid, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from '../../components/commonStyle';
import TrainerInfoProfileCard from '../../components/profile-card/TrainerInfoProfileCard';
import PackageInfoCard from '../../components/trainer-info/PackageInfoCard';

const TrainerInfo = () => {
  const userState = useSelector((state) => state?.auth?.user);

  return (
    <div style={{ minHeight: '1vh' }}>
      <Container>
        <Typography
          variant="h4"
          sx={{
            marginBottom: 4,
            color: '#a0e220',
            fontWeight: 'bold',
          }}
        >
          Trainer Profile Info
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={12} md={5} sm={12}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Box
                  sx={{
                    backgroundColor: '#373736',
                    padding: '2rem .5rem',
                    height: '80%',
                  }}
                >
                  <TrainerInfoProfileCard
                    user={{
                      name: `${userState?.firstName} ${userState?.lastName} `,
                      experience: userState?.yearsOfExperience,
                      profileImg: userState?.profileImageFileUrl,
                      trainingFor: userState?.trainingFor,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7} sm={12}>
            <Card
              sx={{
                backgroundColor: '#373736',
                padding: '1.5rem 2rem',
                height: '83%',
              }}
            >
              <PackageInfoCard trainerId={userState?._id} />
            </Card>
          </Grid>
        </Grid>

        <Typography
          variant="h6"
          sx={{
            marginBottom: 4,
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          Portfolio Images
        </Typography>

        <Grid container spacing={2}>
          {userState?.portfolioFileData?.map((image) => {
            const imageUrl = image?.portfolioFileUrl;

            return (
              <Grid item key={image?.portfolioFileKey} xs={12} sm={6} md={4}>
                {imageUrl ? (
                  <CardMedia
                    component="img"
                    sx={{
                      height: '100%',
                      width: '100%',
                      borderRadius: '8px',
                      objectFit: 'cover',
                    }}
                    image={imageUrl}
                    title="Portfolio"
                    alt="Portfolio Image"
                  />
                ) : (
                  'No image available'
                )}
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default TrainerInfo;
