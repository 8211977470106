import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  Button,
  Divider,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getApi } from '../../utils/axios';
import { getDownloadURLFromFirebaseRef } from '../../utils/firebase';
//
const Container = styled('div')({
  margin: '1rem 9rem',
  '@media (max-width:600px)': {
    margin: '1rem 2rem',
  },
  '@media (max-width:480px)': {
    margin: '1rem',
  },
});
//
const Checkout = () => {
  const user = useSelector((state) => state.auth?.user);
  const cartState = useSelector((state) => state.cart);
  const { state } = useLocation();
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [address, setAddress] = useState(user?.address);
  const [mobile, setMobile] = useState(user?.mobileNumber);
  const [email, setEmail] = useState(user?.auth?._id);
  const [isLoading, setIsLoading] = useState(false);
  //
  const createOrder = async () => {
    setIsLoading(true);

    const order = await getApi()
      .post('/orders', {
        cartItems: cartState.cart,
        shipping: {
          name: firstName + ' ' + lastName,
          address,
          mobileNumber: mobile,
          email,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err?.response?.data?.message);
      });

    const checkoutSessions = await getApi()
      .post(`/payments/orders/${order?._id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err?.response?.data?.message);
      });

    window.location.replace(checkoutSessions?.url);
  };
  //
  useEffect(() => {
    let unmounted = false;
    const fetchImage = async () => {
      const items = state?.pCartResult?.items;
      if (!items) return;
      for (const item of items) {
        if (item.product?.image?.firebaseStorageRef) {
          const url = await getDownloadURLFromFirebaseRef(
            item.product.image.firebaseStorageRef
          );
          item.image = url;
        } else {
          // TODO: set default image
          item.image = undefined;
        }
      }
      if (!unmounted) {
        setIsLoading(false);
      }
    };

    fetchImage();

    return () => {
      unmounted = true;
    };
  }, []);
  //
  return (
    <div>
      <Container>
        <h2 style={{ color: '#a0e220' }}>Checkout Page</h2>
        <Grid container spacing={3}>
          {/* Left column for shipping details */}
          <Grid item xs={12} sm={6}>
            <h2 style={{ color: '#9A9A9A' }}>Shipping Details</h2>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  htmlFor="email"
                  sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
                >
                  First Name
                </InputLabel>
                <OutlinedInput
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Enter your first name"
                  id="fname"
                  name="fname"
                  //   required
                  sx={{
                    borderRadius: 4,
                    border: 'none',
                    width: '90%',
                    marginBottom: 2,
                    backgroundColor: '#373736', // set the background color
                    '& label.Mui-focused': { color: '#008000' },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008000',
                    },
                    '& input::placeholder': { color: '#7b7b7b' },
                    '& input': { color: '#7b7b7b' },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  htmlFor="email"
                  sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
                >
                  Last Name
                </InputLabel>
                <OutlinedInput
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Enter your last name"
                  id="lname"
                  name="lname"
                  //   required
                  sx={{
                    borderRadius: 4,
                    border: 'none',
                    width: '80%',
                    marginBottom: 2,
                    backgroundColor: '#373736', // set the background color
                    '& label.Mui-focused': { color: '#008000' },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008000',
                    },
                    '& input::placeholder': { color: '#7b7b7b' },
                    '& input': { color: '#7b7b7b' },
                  }}
                />
              </Grid>
            </Grid>
            <InputLabel
              htmlFor="text"
              sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
            >
              Address
            </InputLabel>
            <OutlinedInput
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Enter your address"
              id="address"
              name="address"
              //   required
              sx={{
                borderRadius: 4,
                border: 'none',
                width: '90%',
                marginBottom: 2,
                backgroundColor: '#373736', // set the background color
                '& label.Mui-focused': { color: '#008000' },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008000',
                },
                '& input::placeholder': { color: '#7b7b7b' },
                '& input': { color: '#7b7b7b' },
              }}
            />
            <InputLabel
              htmlFor="mobile"
              sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
            >
              Mobile Number
            </InputLabel>
            <OutlinedInput
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Enter your mobile number"
              type="mobile"
              id="mobile"
              name="mobile"
              //   required
              sx={{
                borderRadius: 4,
                border: 'none',
                width: '90%',
                marginBottom: 2,
                backgroundColor: '#373736', // set the background color
                '& label.Mui-focused': { color: '#008000' },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008600',
                },
                '& input::placeholder': { color: '#7b7b7b' },
                '& input': { color: '#7b7b7b' },
              }}
            />
            <InputLabel
              htmlFor="mobile"
              sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
            >
              Email
            </InputLabel>
            <OutlinedInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              type="email"
              id="email"
              name="email"
              //   required
              sx={{
                borderRadius: 4,
                border: 'none',
                width: '90%',
                marginBottom: 2,
                backgroundColor: '#373736', // set the background color
                '& label.Mui-focused': { color: '#008000' },
                '& .MuiInput-underline:after': {
                  borderBottomColor: '#008600',
                },
                '& input::placeholder': { color: '#7b7b7b' },
                '& input': { color: '#7b7b7b' },
              }}
            />
          </Grid>

          {/* Right column for order details form */}
          <Grid item xs={12} sm={6}>
            <h2 style={{ color: '#9A9A9A' }}>Order details</h2>
            <div
              style={{
                marginTop: '2rem',
                backgroundColor: '#363636',
                borderRadius: '10px',
                padding: '20px',
                color: 'white',
              }}
            >
              <Grid container spacing={1}>
                {state?.pCartResult?.items?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Grid item xs={12} sm={3}>
                        <img
                          style={{
                            height: '141px',
                            width: '129px',
                            borderRadius: '10px',
                            objectFit: 'cover',
                          }}
                          src={item.image}
                          alt="order"
                        />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <h3>{item?.product?.name}</h3>
                          <h3 style={{ marginRight: '15px' }}>
                            RS.{item?.total}
                          </h3>
                        </div>
                        {item?.gymWear && (
                          <>
                            <p style={{ color: '#9A9A9A' }}>
                              color :
                              <span style={{ color: 'white' }}>
                                {' ' + item?.gymWear?.color}
                              </span>
                            </p>
                            <p style={{ color: '#9A9A9A' }}>
                              size :
                              <span style={{ color: 'white' }}>
                                {' ' + item?.gymWear?.size}
                              </span>
                            </p>
                          </>
                        )}
                      </Grid>
                      <Divider
                        variant="fillWidth"
                        sx={{ mt: 3, backgroundColor: '#9A9A9A' }}
                      />
                    </React.Fragment>
                  );
                })}
              </Grid>
              <Divider
                variant="fillWidth"
                sx={{ mt: 3, backgroundColor: '#9A9A9A' }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <h3>Subtotal</h3>
                <h3 style={{ color: '#a0e220' }}>
                  RS.{state?.pCartResult?.subTotal}
                </h3>
              </div>
              <Divider
                variant="fillWidth"
                sx={{ backgroundColor: '#9A9A9A' }}
              />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>shipping</h3>
                <h3>RS.{state?.pCartResult?.shippingCost}</h3>
              </div>
              <Divider
                variant="fillWidth"
                sx={{ backgroundColor: '#9A9A9A' }}
              />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3>TOTAL</h3>
                <h3 style={{ color: '#a0e220' }}>
                  RS.{state?.pCartResult?.total}
                </h3>
              </div>
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            sx={{
              mt: 1,
              backgroundImage: '#a0e220', // add background image
              color: 'white',
              '&:hover': {
                backgroundImage: '#a0e220', // change background image on hover
              },
              fontWeight: 'bold',
              padding: '0.7rem 3rem',
              height: '50px',
              width: '42%',
              backgroundColor: '#EAC600',
              padding: '0',
              display: 'flex',
            }}
            disabled={isLoading}
            onClick={createOrder}
          >
            <Typography>Continue</Typography>
            {isLoading && (
              <CircularProgress
                color="inherit"
                size={25}
                style={{ marginLeft: '20px' }}
              />
            )}
          </Button>
        </div>
      </Container>
      <div style={{ margin: '100px' }}></div>
    </div>
  );
};

export default Checkout;
