import React, { useEffect } from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import TrainerIcon from '../../../assets/trainers.png';
import ProductIcon from '../../../assets/product.png';
import UserIcon from '../../../assets/users.png';
import CartIcon from '../../../assets/cart.png';

import styled from '@emotion/styled';
import ProducManage from './ProductManage';
import ClientsManage from './ClientsManage';
import TrainersManage from './TrainerManage';
import OrdersManage from './OrdersManage';

const Container = styled('div')({
  margin: '3rem 1rem',
  '@media (max-width:600px)': {
    margin: '1rem 2rem',
  },
  '@media (max-width:480px)': {
    margin: '1rem',
  },
});

const AdminDashboard = () => {
  const [selectedIndex, setSelectedIndex] = React.useState('1');

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);

    // set current index as a search param
    let params = new URLSearchParams();
    params.set('index', index);
    window.history.replaceState(null, null, `?${params.toString()}`);
  };

  useEffect(() => {
    let unmounted = false;

    let params = new URLSearchParams(window.location.search);
    const index = parseInt(params.get('index')) || 1;
    if (!unmounted) setSelectedIndex(index);

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <div>
      <Grid container spacing={5}>
        <Grid
          item
          xs={4}
          sm={4}
          md={3.5}
          style={{
            width: '100%',
            minHeight: '100vh',
            backgroundColor: '#363636',
            marginTop: '40px',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              pb: '5rem',
              textAlign: 'center',
              fontWeight: 'bold',
              color: 'white',
            }}
          >
            ADMIN DASHBOARD
          </Typography>
          <List>
            <ListItem
              onClick={(event) => handleListItemClick(event, 1)}
              style={{
                padding: '12px 16px',
                cursor: 'pointer',
                backgroundColor: selectedIndex === 1 ? '#a0e220' : '#D9D9D9',
              }}
            >
              <ListItemIcon>
                <img src={UserIcon} style={{ width: '35px' }} alt="icon" />
              </ListItemIcon>
              <ListItemText primary="Manage Clients" />
            </ListItem>
            <ListItem
              onClick={(event) => handleListItemClick(event, 2)}
              style={{
                padding: '12px 16px',
                cursor: 'pointer',
                backgroundColor: selectedIndex === 2 ? '#a0e220' : '#D9D9D9',
              }}
            >
              <ListItemIcon>
                <img src={TrainerIcon} style={{ width: '35px' }} alt="icon" />
              </ListItemIcon>
              <ListItemText primary="Manage Trainers" />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 3}
              onClick={(event) => handleListItemClick(event, 3)}
              style={{
                padding: '12px 16px',
                cursor: 'pointer',
                backgroundColor: selectedIndex === 3 ? '#a0e220' : '#D9D9D9',
              }}
            >
              <ListItemIcon>
                <img src={ProductIcon} style={{ width: '35px' }} alt="icon" />
              </ListItemIcon>
              <ListItemText primary="Manage Products" />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 4}
              onClick={(event) => handleListItemClick(event, 4)}
              style={{
                padding: '12px 16px',
                cursor: 'pointer',
                backgroundColor: selectedIndex === 4 ? '#a0e220' : '#D9D9D9',
              }}
            >
              <ListItemIcon>
                <img src={CartIcon} style={{ width: '35px' }} alt="icon" />
              </ListItemIcon>
              <ListItemText primary="Manage Orders" />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={8} sm={8} md={8}>
          <Container>
            {selectedIndex === 1 && <ClientsManage />}
            {selectedIndex === 2 && <TrainersManage />}
            {selectedIndex === 3 && <ProducManage />}
            {selectedIndex === 4 && <OrdersManage />}
          </Container>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminDashboard;
