import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Modal,
  useMediaQuery,
} from '@mui/material';
import { Container } from '../../components/commonStyle';
import mealPlanIcon from '../../assets/Meal-icon.png'; // Meal icon for meals
import { getApi } from '../../utils/axios';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { formatDate } from '../../utils/helpers';
import Loader from '../../components/Loader/Loader';
import LinkIcon from '@mui/icons-material/Link';
import { useSelector } from 'react-redux';

const ExternalMealPlan = () => {
  const trainer = useSelector((state) => state.auth?.user);
  const { clientID, reqID } = useParams();
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [mealPlan, setMealPlan] = useState(null);
  const [client, setClient] = useState(null);
  const isMobile = useMediaQuery('(max-width:660px)');

  const handleOpenModal = (meal) => setSelectedMeal(meal);
  const handleCloseModal = () => setSelectedMeal(null);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (reqID) {
      getApi()
        .get(`/meal-plan/by-trainer/${clientID}`)
        .then((res) => setMealPlan(res?.data?.data))
        .catch((err) => console.error(err?.response?.data?.message));
    } else {
      getApi()
        .get(`/meal-plan/by-trainer-for-external-clients/${clientID}`)
        .then((res) => setMealPlan(res?.data?.data))
        .catch((err) => console.error(err?.response?.data?.message));
    }
    return () => {
      source.cancel();
    };
  }, [clientID]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    //
    if (reqID) {
      getApi()
        .get(`/trainer-request/${reqID}`)
        .then((res) => setClient(res?.data?.data))
        .catch((err) => console.error(err?.response?.data?.message));
    }
    return () => {
      source.cancel();
    };
  }, [reqID]);

  if (mealPlan === null)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20vh',
        }}
      >
        <Loader />
      </Box>
    );
  else
    return (
      <Container>
        {/* User Information */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row', // Adjust layout for mobile
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: 2,
            backgroundImage: 'linear-gradient(to top, #a0e220, #70b380)',
            borderRadius: 2,
            padding: isMobile ? 2 : 0, // Add padding for mobile
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row', // Stack items for mobile
              alignItems: 'center',
              justifyContent: 'space-between',
              borderRadius: 2,
              marginBottom: isMobile ? 2 : 0, // Add margin for mobile
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              {/* First Image or Placeholder */}
              {reqID ? (
                client?.profilePhotoUrl ? (
                  <img
                    src={client?.profilePhotoUrl}
                    alt="user"
                    style={{
                      width: isMobile ? '80px' : '100px', // Adjust size for mobile
                      height: isMobile ? '80px' : '100px',
                      objectFit: 'cover',
                      borderRadius: '8px 0 0 8px',
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: isMobile ? '80px' : '100px', // Adjust size for mobile
                      height: isMobile ? '80px' : '100px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'gray',
                      borderRadius: '8px 0 0 8px',
                    }}
                  >
                    <Typography
                      variant="h5"
                      color={'white'}
                      fontWeight={700}
                      textTransform={'uppercase'}
                    >
                      {client?.fullName?.slice(0, 2) || 'NA'}
                    </Typography>
                  </Box>
                )
              ) : mealPlan?.externalClientId?.profilePhotoUrl ? (
                <img
                  src={mealPlan.externalClientId.profilePhotoUrl}
                  alt="user"
                  style={{
                    width: isMobile ? '80px' : '100px',
                    height: isMobile ? '80px' : '100px',
                    objectFit: 'cover',
                    borderRadius: '8px 0 0 8px',
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: isMobile ? '80px' : '100px',
                    height: isMobile ? '80px' : '100px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'gray',
                    borderRadius: '8px 0 0 8px',
                  }}
                >
                  <Typography
                    variant="h5"
                    color={'white'}
                    fontWeight={700}
                    textTransform={'uppercase'}
                  >
                    {mealPlan?.externalClientId?.name?.slice(0, 2) || 'NA'}
                  </Typography>
                </Box>
              )}

              {/* Icon */}
              <Box
                sx={{
                  position: 'absolute',
                  left: 'calc(50% - 17px)',
                  zIndex: 1,
                  color: 'white',
                }}
              >
                <LinkIcon fontSize="large" />
              </Box>

              {/* Second Image or Placeholder */}
              {!reqID ? (
                mealPlan?.createdBy?.profileImageFileUrl ? (
                  <img
                    src={mealPlan.createdBy.profileImageFileUrl}
                    alt="user"
                    style={{
                      width: isMobile ? '80px' : '100px',
                      height: isMobile ? '80px' : '100px',
                      objectFit: 'cover',
                      borderRadius: '0 8px 8px 0',
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: isMobile ? '80px' : '100px',
                      height: isMobile ? '80px' : '100px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'gray',
                      borderRadius: '0 8px 8px 0',
                    }}
                  >
                    <Typography
                      variant="h5"
                      color={'white'}
                      fontWeight={700}
                      textTransform={'uppercase'}
                    >
                      {mealPlan?.createdBy?.firstName?.slice(0, 2) || 'NA'}
                    </Typography>
                  </Box>
                )
              ) : trainer?.profileImageFileUrl ? (
                <img
                  src={trainer?.profileImageFileUrl}
                  alt="user"
                  style={{
                    width: isMobile ? '80px' : '100px',
                    height: isMobile ? '80px' : '100px',
                    objectFit: 'cover',
                    borderRadius: '0 8px 8px 0',
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: isMobile ? '80px' : '100px',
                    height: isMobile ? '80px' : '100px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'gray',
                    borderRadius: '0 8px 8px 0',
                  }}
                >
                  <Typography
                    variant="h5"
                    color={'white'}
                    fontWeight={700}
                    textTransform={'uppercase'}
                  >
                    {trainer?.firstName?.slice(0, 2) || 'NA'}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                marginLeft: isMobile ? 0 : 2,
                marginTop: isMobile ? 2 : 0,
                textAlign: isMobile ? 'center' : 'start',
              }}
            >
              <Typography
                variant="h5"
                color={'black'}
                fontWeight={700}
                textTransform={'uppercase'}
              >
                {reqID ? client?.fullName : mealPlan?.externalClientId?.name}
              </Typography>
              <Typography variant="body2" color={'black'} fontWeight={300}>
                Age:{' '}
                {reqID
                  ? client?.age
                  : mealPlan?.externalClientId?.personalInfo?.age}{' '}
                | DCI:{' '}
                {reqID
                  ? client?.calculatedMetrics?.dci?.toFixed(2)
                  : mealPlan?.externalClientId?.calculatedMetrics?.dci?.toFixed(
                      2
                    )}{' '}
                Cal | Goal:{' '}
                {reqID ? client?.goal : mealPlan?.externalClientId?.goal}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              textAlign: isMobile ? 'center' : 'right',
              paddingRight: isMobile ? 0 : '1rem',
            }}
          >
            <Typography
              variant={isMobile ? 'body1' : 'h7'}
              color={'black'}
              fontWeight={700}
            >
              MEAL PLAN
            </Typography>
            <Typography variant="body2" color={'black'} fontWeight={200}>
              {new Date(mealPlan?.updatedAt)
                ?.toLocaleDateString('en-US', {
                  month: 'short',
                  year: 'numeric',
                })
                ?.toUpperCase()}
            </Typography>
          </Box>
        </Box>
        {/* Meal Days and Meals */}

        {/* NOTE:Breakfast */}
        <Typography
          variant="h5"
          fontWeight={700}
          sx={{ color: '#a0e220', marginBottom: 2, marginTop: 4 }}
        >
          Breakfast
        </Typography>
        {mealPlan?.breakfast.map((breakfast, index) => (
          <Box key={index} sx={{ marginTop: 3 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleOpenModal(breakfast)}
              >
                <Card
                  sx={{
                    backgroundColor: '#424242',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: isMobile ? 'center' : 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <CardContent
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#a0e220',
                      }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                        }}
                      >
                        <img
                          src={breakfast?.mealItemId?.url || mealPlanIcon}
                          alt="Meal"
                          style={{ width: 50 }}
                        />
                      </Box>
                    </CardContent>
                    <Box>
                      <Typography
                        variant={isMobile ? 'body1' : 'h6'}
                        sx={{ fontWeight: 'bold', marginLeft: 2 }}
                        color={'white'}
                        fontWeight={200}
                      >
                        {breakfast?.mealItemId?.name}
                      </Typography>
                      {isMobile && (
                        <Typography
                          variant={isMobile ? 'caption' : 'body1'}
                          color={'white'}
                          fontWeight={200}
                          sx={{ paddingLeft: 2 }}
                        >
                          Calories:{' '}
                          {(
                            breakfast?.mealItemId?.calPerUnit * breakfast?.count
                          )?.toFixed(2)}
                          | Portion Size: {breakfast?.count}{' '}
                          {breakfast?.mealItemId?.unit.toLowerCase()}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  {!isMobile && (
                    <Typography
                      variant="body1"
                      color={'white'}
                      fontWeight={200}
                      sx={{ paddingRight: 2 }}
                    >
                      Calories:{' '}
                      {(
                        breakfast?.mealItemId?.calPerUnit * breakfast?.count
                      )?.toFixed(2)}
                      | Portion Size: {breakfast?.count}{' '}
                      {breakfast?.mealItemId?.unit.toLowerCase()}
                    </Typography>
                  )}
                </Card>
              </Grid>
            </Grid>
          </Box>
        ))}

        {/* NOTE:Lunch  */}
        <Typography
          variant="h5"
          fontWeight={700}
          sx={{ color: '#a0e220', marginBottom: 2, marginTop: 4 }}
        >
          Lunch
        </Typography>
        {mealPlan?.lunch.map((lunch, index) => (
          <Box key={index} sx={{ marginTop: 3 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleOpenModal(lunch)}
              >
                <Card
                  sx={{
                    backgroundColor: '#424242',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <CardContent
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#a0e220',
                      }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                        }}
                      >
                        <img
                          src={lunch?.mealItemId?.url || mealPlanIcon}
                          alt="Meal"
                          style={{ width: 50 }}
                        />
                      </Box>
                    </CardContent>
                    <Box>
                      <Typography
                        variant={isMobile ? 'body1' : 'h6'}
                        sx={{ fontWeight: 'bold', marginLeft: 2 }}
                        color={'white'}
                        fontWeight={200}
                      >
                        {lunch?.mealItemId?.name}
                      </Typography>
                      {isMobile && (
                        <Typography
                          variant={isMobile ? 'caption' : 'body1'}
                          color={'white'}
                          fontWeight={200}
                          sx={{ paddingLeft: 2 }}
                        >
                          Calories:{' '}
                          {(
                            lunch?.mealItemId?.calPerUnit * lunch?.count
                          )?.toFixed(2)}
                          | Portion Size: {lunch?.count}{' '}
                          {lunch?.mealItemId?.unit.toLowerCase()}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  {!isMobile && (
                    <Typography
                      variant="body1"
                      color={'white'}
                      fontWeight={200}
                      sx={{ paddingRight: 2 }}
                    >
                      Calories:{' '}
                      {(lunch?.mealItemId?.calPerUnit * lunch?.count)?.toFixed(
                        2
                      )}
                      | Portion Size: {lunch?.count}{' '}
                      {lunch?.mealItemId?.unit.toLowerCase()}
                    </Typography>
                  )}
                </Card>
              </Grid>
            </Grid>
          </Box>
        ))}

        {/* NOTE:Dinner  */}
        <Typography
          variant="h5"
          fontWeight={700}
          sx={{ color: '#a0e220', marginBottom: 2, marginTop: 4 }}
        >
          Dinner
        </Typography>
        {mealPlan?.dinner.map((dinner, index) => (
          <Box key={index} sx={{ marginTop: 3 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleOpenModal(dinner)}
              >
                <Card
                  sx={{
                    backgroundColor: '#424242',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <CardContent
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#a0e220',
                      }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                        }}
                      >
                        <img
                          src={dinner?.mealItemId?.url || mealPlanIcon}
                          alt="Meal"
                          style={{ width: 50 }}
                        />
                      </Box>
                    </CardContent>
                    <Box>
                      <Typography
                        variant={isMobile ? 'body1' : 'h6'}
                        sx={{ fontWeight: 'bold', marginLeft: 2 }}
                        color={'white'}
                        fontWeight={200}
                      >
                        {dinner?.mealItemId?.name}
                      </Typography>
                      {isMobile && (
                        <Typography
                          variant={isMobile ? 'caption' : 'body1'}
                          color={'white'}
                          fontWeight={200}
                          sx={{ paddingLeft: 2 }}
                        >
                          Calories:{' '}
                          {(
                            dinner?.mealItemId?.calPerUnit * dinner?.count
                          )?.toFixed(2)}
                          | Portion Size: {dinner?.count}{' '}
                          {dinner?.mealItemId?.unit.toLowerCase()}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  {!isMobile && (
                    <Typography
                      variant="body1"
                      color={'white'}
                      fontWeight={200}
                      sx={{ paddingRight: 2 }}
                    >
                      Calories:{' '}
                      {(
                        dinner?.mealItemId?.calPerUnit * dinner?.count
                      )?.toFixed(2)}
                      | Portion Size: {dinner?.count}{' '}
                      {dinner?.mealItemId?.unit.toLowerCase()}
                    </Typography>
                  )}
                </Card>
              </Grid>
            </Grid>
          </Box>
        ))}
        {/* Modal for Meal Details */}
        <Modal open={!!selectedMeal} onClose={handleCloseModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: isMobile ? 330 : 400,
              bgcolor: '#333',
              color: 'white',
              p: 4,
              borderRadius: 2,
              textAlign: 'center',
            }}
          >
            {selectedMeal && (
              <>
                <Box
                  sx={{
                    mb: 2,
                  }}
                >
                  <img
                    src={selectedMeal?.mealItemId?.url}
                    alt="Meal Details"
                    width={200}
                    height={200}
                    style={{
                      objectFit: 'cover',
                      borderRadius: 5,
                    }}
                  />
                </Box>
                <Typography variant="h5" sx={{ mb: 1 }} fontWeight={600}>
                  {selectedMeal?.mealItemId?.name}
                </Typography>

                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          backgroundColor: 'white',
                          padding: '10px 35px',
                          borderRadius: '5px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          color={'black'}
                          fontWeight={600}
                        >
                          {(
                            selectedMeal?.mealItemId?.calPerUnit *
                            selectedMeal?.count
                          )?.toFixed(2)}
                        </Typography>
                        <Typography variant="h7" color={'black'}>
                          Calories
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        sx={{
                          backgroundColor: 'white',
                          padding: '10px 35px',
                          borderRadius: '5px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          color={'black'}
                          fontWeight={600}
                        >
                          {selectedMeal?.count}{' '}
                          {selectedMeal?.mealItemId?.unit?.toLowerCase()}
                        </Typography>
                        <Typography variant="h7" color={'black'}>
                          Portion Size
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        sx={{
                          backgroundColor: 'white',
                          padding: '10px 35px',
                          borderRadius: '5px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          color={'black'}
                          fontWeight={600}
                        >
                          {selectedMeal?.mealItemId?.protein?.toFixed(2)}
                        </Typography>
                        <Typography variant="h7" color={'black'}>
                          Protein
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        sx={{
                          backgroundColor: 'white',
                          padding: '10px 35px',
                          borderRadius: '5px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          color={'black'}
                          fontWeight={600}
                        >
                          {selectedMeal?.mealItemId?.carbs?.toFixed(2)}
                        </Typography>
                        <Typography variant="h7" color={'black'}>
                          Carbs
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        sx={{
                          backgroundColor: 'white',
                          padding: '10px 35px',
                          borderRadius: '5px',
                        }}
                      >
                        <Typography
                          variant="h5"
                          color={'black'}
                          fontWeight={600}
                        >
                          {selectedMeal?.mealItemId?.fats?.toFixed(2)}
                        </Typography>
                        <Typography variant="h7" color={'black'}>
                          Fats
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Typography variant="body2">{selectedMeal.details}</Typography>
              </>
            )}
          </Box>
        </Modal>
      </Container>
    );
};

export default ExternalMealPlan;
