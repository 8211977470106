import styled from "@emotion/styled";
import React from "react";
import injeries from "../../assets/have-injuries.png";
import noInjeries from "../../assets/no-injuries.png";
import { useNavigate } from "react-router-dom";

const Container = styled("div")({
  margin: "3rem 9rem",
  "@media (max-width:600px)": {
    margin: "1rem 2rem",
  },
  "@media (max-width:480px)": {
    margin: "1rem",
  },
  // height: "100vh",
});
const CheckInjeries = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#D9D9D9",
          flexDirection: "column",
          margin: "100px",
        }}
      >
        <h1>Do you have any health issues or injuries?</h1>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
            marginTop: "2rem",
          }}
        >
          <div>
            {" "}
            <img
              onClick={() => navigate("/sorry")}
              src={injeries}
              alt="img"
              style={{
                width: "250px",
                height: "250px",
                margin: "20px",
                cursor: "pointer",
              }}
            />
          </div>
          <div>
            {" "}
            <img
              onClick={() => navigate("/personal-info")}
              src={noInjeries}
              alt="img"
              style={{
                width: "250px",
                height: "250px",
                margin: "20px",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CheckInjeries;
