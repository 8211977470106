import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const styles = {
  text: {
    color: '#D0D3D4',
  },
};

const TermsConditions = () => {
  return (
    <Container>
      <Box sx={{ marginTop: '20px' }}>
        <Typography style={{ color: 'white' }} variant="h4" gutterBottom>
          Terms & Conditions for Fitness Guru
        </Typography>

        <Typography
          style={{ color: 'white', fontWeight: 'bold', marginTop: '1rem' }}
          variant="h6"
          gutterBottom
        >
          1. Introduction
        </Typography>
        <Typography style={styles.text} paragraph>
          Welcome to Fitness Guru. By using our website and services, you agree
          to comply with and be bound by the following terms and conditions.
          Please review them carefully. If you do not agree to these terms, you
          should not use our website or services.
        </Typography>

        <Typography
          style={{ color: 'white', fontWeight: 'bold', marginTop: '1rem' }}
          variant="h6"
          gutterBottom
        >
          2. Scope and Role of Trainers
        </Typography>
        <Typography style={styles.text} component="ul" gutterBottom>
          <li style={{ marginTop: '0.5rem' }}>
            <strong>Independent Contractor Status:</strong> Trainers using the
            Fitness Guru platform are independent contractors, not employees,
            agents, or representatives of Fitness Guru. As independent
            contractors, trainers are responsible for their own actions,
            business operations, and tax obligations. Trainers retain full
            control over their services, schedules, and interactions with
            clients.
          </li>
          <li style={{ marginTop: '0.5rem' }}>
            <strong>Service Provision:</strong> Trainers are responsible for the
            fitness programs, workouts, and dietary advice they provide to
            clients. Fitness Guru provides a platform for trainers to connect
            with clients but does not supervise or control the services offered.
          </li>
          <li style={{ marginTop: '0.5rem' }}>
            <strong>Liability:</strong> Trainers are solely responsible for any
            liability arising from the services they provide. Fitness Guru is
            not liable for injuries, damages, or disputes resulting from
            trainer-client relationships or services rendered.
          </li>
        </Typography>

        <Typography
          style={{ color: 'white', fontWeight: 'bold', marginTop: '1rem' }}
          variant="h6"
          gutterBottom
        >
          3. Use of Our Services
        </Typography>
        <Typography style={styles.text} component="ul" gutterBottom>
          <li style={{ marginTop: '0.5rem' }}>
            <strong>Eligibility:</strong> You must be at least 18 years old to
            use our services. By using our services, you represent that you meet
            this age requirement.
          </li>
          <li style={{ marginTop: '0.5rem' }}>
            <strong>Account Registration:</strong> You may be required to create
            an account to access certain features of our website. You are
            responsible for maintaining the confidentiality of your account
            information and for all activities that occur under your account.
          </li>
          <li style={{ marginTop: '0.5rem' }}>
            <strong>Prohibited Activities:</strong> You agree not to use our
            services for any unlawful or prohibited purposes. Fitness Guru may
            terminate accounts for conduct that violates these terms.
          </li>
        </Typography>

        <Typography
          style={{ color: 'white', fontWeight: 'bold', marginTop: '1rem' }}
          variant="h6"
          gutterBottom
        >
          4. Health and Safety
        </Typography>
        <Typography style={styles.text} component="ul" gutterBottom>
          <li style={{ marginTop: '0.5rem' }}>
            <strong>Trainer Responsibility for Client Safety:</strong> Trainers
            must ensure that any fitness programs, exercises, or dietary advice
            provided are appropriate for each client's individual needs, fitness
            levels, and health conditions.
          </li>
          <li style={{ marginTop: '0.5rem' }}>
            <strong>Consultation with Healthcare Professionals:</strong>{' '}
            Trainers are required to advise clients to seek medical clearance
            from a healthcare provider before starting any fitness regimen,
            especially for clients with pre-existing conditions or injuries.
          </li>
          <li style={{ marginTop: '0.5rem' }}>
            <strong>Professional Conduct:</strong> Trainers must maintain a
            professional, safe, and inclusive environment for clients.
          </li>
        </Typography>

        <Typography
          style={{ color: 'white', fontWeight: 'bold', marginTop: '1rem' }}
          variant="h6"
          gutterBottom
        >
          5. Fees and Payments
        </Typography>
        <Typography style={styles.text} component="ul" gutterBottom>
          <li style={{ marginTop: '0.5rem' }}>
            <strong>Subscription Fees:</strong> Some services on the platform
            may require payment of subscription fees. By subscribing, you agree
            to pay the applicable fees as described on our website.
          </li>
          <li style={{ marginTop: '0.5rem' }}>
            <strong>Trainer Commission:</strong> When a trainer acquires a
            Fitness Guru member as a client, 10% of the trainer's package rate
            will be deducted as a commission payable to Fitness Guru.
          </li>
          <li style={{ marginTop: '0.5rem' }}>
            <strong>Payment Release:</strong> Payments to trainers will be
            processed and released every 30 days after deducting the 10%
            commission.
          </li>
        </Typography>

        <Typography
          style={{ color: 'white', fontWeight: 'bold', marginTop: '1rem' }}
          variant="h6"
          gutterBottom
        >
          6. Intellectual Property and User Content
        </Typography>
        <Typography style={styles.text} component="ul" gutterBottom>
          <li style={{ marginTop: '0.5rem' }}>
            <strong>Ownership:</strong> All content, trademarks, and
            intellectual property on our website are owned by Fitness Guru or
            our licensors.
          </li>
          <li style={{ marginTop: '0.5rem' }}>
            <strong>User Content:</strong> By submitting content to our website,
            trainers grant Fitness Guru a non-exclusive, royalty-free, worldwide
            license to use, reproduce, and distribute the content.
          </li>
        </Typography>

        <Typography
          style={{ color: 'white', fontWeight: 'bold', marginTop: '1rem' }}
          variant="h6"
          gutterBottom
        >
          7. Limitation of Liability
        </Typography>
        <Typography style={styles.text} paragraph>
          To the fullest extent permitted by law, Fitness Guru shall not be
          liable for any indirect, incidental, special, or consequential damages
          arising out of or in connection with the use of our services.
        </Typography>

        <Typography
          style={{ color: 'white', fontWeight: 'bold', marginTop: '1rem' }}
          variant="h6"
          gutterBottom
        >
          8. Indemnification
        </Typography>
        <Typography style={styles.text} paragraph>
          Trainers agree to indemnify, defend, and hold harmless Fitness Guru,
          its affiliates, officers, directors, employees, and agents from any
          claims, liabilities, damages, losses, and expenses arising from the
          use of our services or violations of these terms.
        </Typography>

        <Typography
          style={{ color: 'white', fontWeight: 'bold', marginTop: '1rem' }}
          variant="h6"
          gutterBottom
        >
          9. Termination
        </Typography>
        <Typography style={styles.text} paragraph>
          Fitness Guru reserves the right to terminate or suspend a trainer's
          account and access to services at any time, without prior notice, for
          conduct that violates these terms or is harmful to users or Fitness
          Guru.
        </Typography>

        <Typography
          style={{ color: 'white', fontWeight: 'bold', marginTop: '1rem' }}
          variant="h6"
          gutterBottom
        >
          10. Governing Law
        </Typography>
        <Typography style={styles.text} paragraph>
          These terms and conditions are governed by and construed in accordance
          with the laws of Sri Lanka, without regard to conflict of law
          principles.
        </Typography>

        <Typography
          style={{ color: 'white', fontWeight: 'bold', marginTop: '1rem' }}
          variant="h6"
          gutterBottom
        >
          11. Changes to These Terms
        </Typography>
        <Typography style={styles.text} paragraph>
          Fitness Guru reserves the right to update these terms and conditions
          periodically. Trainers will be notified of any changes through updates
          posted on the website and are advised to review the terms regularly.
        </Typography>

        <Typography
          style={{ color: 'white', fontWeight: 'bold', marginTop: '1rem' }}
          variant="h6"
          gutterBottom
        >
          12. Contact Us
        </Typography>
        <Typography style={styles.text} paragraph>
          Fitness Guru
          <br />
          NO: 186/F/12, Yabaralauwa North Malwana
          <br />
          <a
            href="mailto:contact@fitnessgurulk.com"
            style={{ color: '#a0e220' }}
          >
            contact@fitnessgurulk.com
          </a>
          <br />
          0112465644
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsConditions;
