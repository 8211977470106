import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const styles = {
  text: {
    color: '#D0D3D4',
  },
};

const ReturnPolicy = () => {
  return (
    <Container>
      <Box sx={{ marginTop: '20px' }}>
        <Typography style={styles.text} variant="h4" gutterBottom>
          Return Policy for Fitness Guru
        </Typography>

        <Typography style={styles.text} variant="h6" gutterBottom>
          1. Overview
        </Typography>
        <Typography style={styles.text} paragraph>
          At Fitness Guru, we are committed to providing high-quality services
          to help you achieve your fitness goals. If you are not completely
          satisfied with your subscription, we are here to help. Please read our
          return policy carefully to understand your rights and obligations.
        </Typography>

        <Typography style={styles.text} variant="h6" gutterBottom>
          2. Subscription Refunds
        </Typography>
        <Typography style={styles.text} paragraph>
          <strong>Initial Subscription:</strong> We offer a 7 day money-back
          guarantee for new subscriptions. If you are not satisfied with our
          services within the first 7 days of your initial subscription, you can
          request a full refund.
        </Typography>
        <Typography style={styles.text} paragraph>
          <strong>Renewal Subscriptions:</strong> Renewal subscriptions are
          non-refundable. If you do not wish to continue your subscription,
          please ensure you cancel before the renewal date to avoid being
          charged for the next subscription period.
        </Typography>

        <Typography style={styles.text} variant="h6" gutterBottom>
          3. Refund Process
        </Typography>
        <Typography style={styles.text} paragraph>
          To request a refund:
        </Typography>
        <Typography style={styles.text} component="ul" gutterBottom>
          <li>
            Contact Us: Email us at contact@fitnessgurulk.com with your account
            details, subscription information, and the reason for your refund
            request.
          </li>
          <li>
            Review: Once we receive your request, we will review it and notify
            you of the approval or rejection of your refund.
          </li>
          <li>
            Processing: If your refund is approved, it will be processed, and a
            credit will automatically be applied to your original method of
            payment within 7 days.
          </li>
        </Typography>

        <Typography style={styles.text} variant="h6" gutterBottom>
          4. Cancellation Policy
        </Typography>
        <Typography style={styles.text} paragraph>
          <strong>Cancellation:</strong> You may cancel your subscription at any
          time by logging into your account and following the cancellation
          instructions. If you cancel, you will continue to have access to the
          subscription services until the end of your current billing period.
        </Typography>
        <Typography style={styles.text} paragraph>
          <strong>No Partial Refunds:</strong> We do not provide partial refunds
          for unused portions of your subscription period.
        </Typography>

        <Typography style={styles.text} variant="h6" gutterBottom>
          5. Contact Information
        </Typography>
        <Typography style={styles.text} paragraph>
          If you have any questions about our return policy or need assistance
          with your subscription, please contact us at:
        </Typography>
        <Typography style={styles.text} paragraph>
          Fitness Guru
          <br />
          NO: 186/F/12, Yabaralauwa North Malwana
          <br />
          <a href="mailto:contact@fitnessgurulk.com" style={{ color: 'green' }}>
            contact@fitnessgurulk.com
          </a>
          <br />
          0112465644
        </Typography>
      </Box>
    </Container>
  );
};

export default ReturnPolicy;
