// ProfileCard.jsx
import { Box, Paper, Table, TableBody, TableContainer } from '@mui/material';
import React from 'react';
import ProfileImageUpload from './ProfileImageUpload';
import ProfileRow from './ProfileRow';
import Loader from '../../../components/Loader/Loader';

const ProfileCard = ({ client }) => {
  if (!client)
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Loader />
      </Box>
    );
  else
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <ProfileRow label="Name" value={client?.fullName} />
            <ProfileRow label="Occupation" value={client?.occupation} />
            <ProfileRow label="Age" value={client?.age} />
            <ProfileRow label="Weight" value={`${client?.weight} Kg`} />
            <ProfileRow label="Height" value={`${client?.height} cm`} />
            <ProfileRow
              label="Where do you workout?"
              value={client?.workoutPlace}
            />
            <ProfileRow
              label="If home please mention your equipments"
              value={client?.homeEquipments}
            />
            <ProfileImageUpload label="Please upload images of your equipments" />
            <ProfileRow
              label="Any food allergies?"
              value={client?.isAnyFoodAllergies ? 'YES' : 'NO'}
            />
            <ProfileRow
              label="Do you have any health issues or injuries?"
              value={client?.isAnyInjuries ? 'YES' : 'NO'}
            />
            <ProfileRow
              label="How do you rank your activity level?"
              value={client?.activityLevel}
            />
            <ProfileRow label="What is your goal?" value={client?.goal} />
            <ProfileRow
              label="What is your budget per week for meal plan?"
              value={`${client?.weeklyMealBudget} LKR`}
            />
            <ProfileRow
              label="Do you use any supplements?"
              value={client?.isUseAnySupplements ? 'YES' : 'NO'}
            />
            <ProfileImageUpload label="Please upload 4 images of your body in a clear background" />
          </TableBody>
        </Table>
      </TableContainer>
    );
};

export default ProfileCard;
