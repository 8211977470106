import React from "react";

const ComponentToPrint = React.forwardRef((props, ref) => {
  const getPageMargins = () => {
    return `@page { margin: 15mm; }`;
  };

  const getPageSize = () => {
    return `@page { size: 210mm 297mm;}`;
  };

  return (
    <div ref={ref}>
      <style>
        {getPageSize()}
        {getPageMargins()}
      </style>

      {/* breakfast section */}
      <div style={{ pageBreakAfter: "always" }}>{props.breakfastSection}</div>

      {/* lunch section */}
      <div style={{ pageBreakAfter: "always" }}>{props.lunchSection}</div>

      {/* dinner section */}
      <div style={{ pageBreakAfter: "always" }}>{props.dinnerSection}</div>
    </div>
  );
});

export default ComponentToPrint;
