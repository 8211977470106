import { Box, Button } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import ProfileImg from './ProfileImg';
import { useNavigate } from 'react-router-dom';

export default function TrainerInfoProfileCard({ user }) {
  const navigate = useNavigate();
  return (
    <Box sx={{ backgroundColor: '#373736' }}>
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box>
          <ProfileImg
            data={{
              size: '180px',
              image: user.profileImg,
              fullName: `${user.name}`,
            }}
          />
        </Box>
      </Box>

      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          gutterBottom
          variant="h5"
          sx={{ textAlign: 'center', fontWeight: 'bold', color: 'white' }}
        >
          {user.name}
        </Typography>
        <Typography
          gutterBottom
          variant="h6"
          sx={{ textAlign: 'center', fontWeight: 'normal', color: 'white' }}
        >
          {user.experience} Years Experience
        </Typography>
        <Button variant="outlined">
          {user.trainingFor === 'MenAndWomen'
            ? 'Men and Women'
            : user.trainingFor}
        </Button>
      </CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="text"
          sx={{
            color: '#a0e220',
          }}
          onClick={() => navigate(`/career-details`)}
        >
          Edit
        </Button>
      </Box>
    </Box>
  );
}
