import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {
    currentPage: 1,
  },
};
const userRegisterSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    resetUser(state) {
      return initialState;
    },
  },
});
export const userRegisterActions = userRegisterSlice.actions;
export default userRegisterSlice;
