import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../../app.css';
import MealPreview from '../../components/meals-tables/MealPreview';
import { popAlert } from '../../utils/alert';
import { getApi } from '../../utils/axios';
import ComponentToPrint from './ComponentToPrint';
import ExternalMealPlan from './ExternalMealPlan';

const MealPlan = () => {
  const { reqID, clientID } = useParams();
  const navigate = useNavigate();
  const componentRef = useRef();
  const [type, setType] = useState('breakfast');
  const [meals, setMeals] = useState({});
  const [client, setClient] = useState();

  const handleClose = () => {
    if (reqID) {
      navigate(`/client/${reqID}`);
    } else {
      navigate('/external-clients');
    }
  };
  //
  const handleCopyLink = () => {
    const workoutPlanUrl = `http://localhost:3000/external/meal/${clientID}`;
    navigator.clipboard
      .writeText(workoutPlanUrl)
      .then(() => {
        popAlert(
          'Copied!',
          'meal plan link copied to clipboard!',
          'info',
          'ok'
        ).then(() => {
          window.open(`/external/meal/${clientID}`, '_blank');
        });
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };
  //
  useEffect(() => {
    const source = axios.CancelToken.source();
    //
    if (reqID) {
      getApi()
        .get(`/meal-plan/by-trainer/${clientID}`)
        .then((res) => setMeals(res?.data?.data))
        .catch((err) => console.error(err?.response?.data?.message));
    } else {
      getApi()
        .get(`/meal-plan/by-trainer-for-external-clients/${clientID}`)
        .then((res) => setMeals(res?.data?.data))
        .catch((err) => console.error(err?.response?.data?.message));
    }
    return () => {
      source.cancel();
    };
  }, [clientID]);
  //
  useEffect(() => {
    const source = axios.CancelToken.source();
    //
    if (reqID) {
      getApi()
        .get(`/trainer-request/${reqID}`)
        .then((res) => setClient(res?.data?.data))
        .catch((err) => console.error(err?.response?.data?.message));
    } else {
      getApi()
        .get('/external-client')
        .then((res) => {
          const filterdExternalClient = res?.data?.data?.find(
            (client) => client._id === clientID
          );
          setClient(filterdExternalClient);
        })
        .catch((err) => console.error(err?.response?.data?.message));
    }
    return () => {
      source.cancel();
    };
  }, [reqID]);

  return (
    <div style={{ minHeight: '100vh' }}>
      {/* <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#a0e220',
          paddingLeft: '20px',
        }}
      >
        <ArrowBackIcon
          fontSize="large"
          sx={{ cursor: 'pointer', color: 'white' }}
          onClick={handleClose}
        />
        <ReactToPrint
          trigger={() => (
            <div
              style={{
                height: '3.5rem',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0.5rem',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div style={{ marginLeft: '20px' }}>
                <Typography
                  style={{
                    color: 'white',
                    fontSize: '18px',
                    fontWeight: 'bold',
                  }}
                >
                  Meal Plan
                </Typography>
                <Typography style={{ color: 'white', fontSize: '14px' }}>
                  {new Date(meals?.updatedAt).toLocaleDateString('en-US', {
                    month: 'long',
                    year: 'numeric',
                  })}
                </Typography>
              </div>
              <div>
                <button
                  style={{
                    width: '120px',
                    borderRadius: '15px',
                    border: '1px solid white',
                    backgroundColor: '#a0e220',
                    color: 'white',
                    height: '40px',
                    fontSize: '18px',
                    marginRight: '20px',
                    cursor: 'pointer',
                  }}
                >
                  Print
                </button>
              </div>
            </div>
          )}
          content={() => componentRef.current}
        />
      </div> */}
      {!reqID && (
        <div className="responsive-padding">
          <Button
            style={{
              color: 'white',
              textTransform: 'capitalize',
              fontSize: '16px',
            }}
            variant="outlined"
            startIcon={<ContentCopyIcon />}
            onClick={handleCopyLink}
          >
            Get meal plan link
          </Button>
        </div>
      )}

      <div style={{ display: 'none' }}>
        <ComponentToPrint
          ref={componentRef}
          breakfastSection={
            <MealPreview mealPlan={meals} type={'breakfast'} user={client} />
          }
          lunchSection={
            <MealPreview mealPlan={meals} type={'lunch'} user={client} />
          }
          dinnerSection={
            <MealPreview mealPlan={meals} type={'dinner'} user={client} />
          }
        />
      </div>

      {/* design displayable component here */}
      <div>
        <ExternalMealPlan />
      </div>
    </div>
  );
};

export default MealPlan;
