import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileImg from '../profile-card/ProfileImg';

const YourClientCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        maxWidth: 200,
        borderRadius: 5,
        backgroundColor: '#1D1D1D',
        padding: '1rem 1rem',
        marginTop: '1rem',
      }}
    >
      <CardActionArea>
        <CardContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ProfileImg
            data={{
              size: '100px',
              image: data?.image,
              fullName: `${data.name}`,
            }}
          />
        </CardContent>
        <CardContent sx={{ backgroundColor: '#1D1D1D', textAlign: 'center' }}>
          <Typography
            gutterBottom
            variant="h5"
            color="white"
            style={{ fontWeight: 'bold', margin: 0 }}
          >
            {data.name}
          </Typography>
          <Button
            style={{
              color: '#a0e220',
              border: '1px solid #a0e220',
              width: '100%',
              borderRadius: '10px',
              margin: '10px 0px 0px 0px',
            }}
            onClick={() =>
              navigate(`/client/${data?.client?._id}`, {
                state: data,
              })
            }
          >
            View profile
          </Button>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default YourClientCard;
