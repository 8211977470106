// hooks/useExercises.js
import { useState, useEffect } from 'react';
import { getApi } from '../utils/axios';

const useExercises = (input) => {
  const [exercises, setExercises] = useState([]);
  const [filteredExercises, setFilteredExercises] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchExercises = async () => {
      setLoading(true);
      try {
        const response = await getApi().get('/workout/exercises');
        setExercises(response?.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching exercises:', error);
        setLoading(false);
      }
    };

    fetchExercises();
  }, []);

  useEffect(() => {
    if (input) {
      const uniqueExercises = Array.from(
        new Set(exercises.map((ex) => ex.name))
      ).map((name) => {
        return exercises.find((ex) => ex.name === name);
      });

      setFilteredExercises(
        uniqueExercises.filter((exercise) =>
          exercise.name.toLowerCase().includes(input.toLowerCase())
        )
      );
    } else {
      const uniqueExercises = Array.from(
        new Set(exercises.map((ex) => ex.name))
      ).map((name) => {
        return exercises.find((ex) => ex.name === name);
      });

      setFilteredExercises(uniqueExercises);
    }
  }, [input, exercises]);

  return { filteredExercises, loading };
};

export default useExercises;
