import { Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
import profile from '../../assets/profile.png';
import { capitalizeWords } from '../../utils/helpers';
import './style.css';

const MealPreview = ({ mealPlan, type, user }) => {
  const [profileImage, setProfileImage] = useState('');
  //
  function getMealsByType(type) {
    switch (type) {
      case 'breakfast':
        return mealPlan?.breakfast;
      case 'lunch':
        return mealPlan?.lunch;
      case 'dinner':
        return mealPlan?.dinner;
      default:
        return null;
    }
  }
  //
  return (
    <div
      style={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '816px',
        '@media (minWidth: 768px)': {
          minWidth: '640px',
        },
        '@media (minWidth: 480px)': {
          minWidth: '320px',
        },
      }}
    >
      {/* header  */}
      <div
        style={{
          backgroundImage: 'linear-gradient(to top, #a0e220, #a0e220)',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={user?.profilePhotoUrl ? user?.profilePhotoUrl : profile}
              alt="user"
              style={{
                width: '100px',
                height: '110px',
                objectFit: 'cover',
              }}
            />
            <div style={{ marginLeft: '1rem', color: 'white' }}>
              <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                {capitalizeWords(user?.fullName || user?.name)}
              </Typography>
              <div style={{ display: 'flex' }}>
                <Typography sx={{ fontSize: '13px', marginRight: '5px' }}>
                  Age: {user?.personalInfo?.age || user?.age}
                </Typography>
                {user?.calculatedMetrics && (
                  <>
                    <Divider
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                      sx={{ backgroundColor: 'white', margin: '3px' }}
                    />
                    <Typography sx={{ fontSize: '13px', marginRight: '5px' }}>
                      BMR: {user?.calculatedMetrics?.bmr?.toFixed(2)}Cal
                    </Typography>
                    <Divider
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                      sx={{ backgroundColor: 'white', margin: '3px' }}
                    />
                    <Typography sx={{ fontSize: '13px', marginRight: '5px' }}>
                      DCL: {user?.calculatedMetrics?.dci?.toFixed(2)}Cal
                    </Typography>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            style={{ marginRight: '1rem', color: 'white', textAlign: 'right' }}
          >
            <Typography
              sx={{ fontSize: '14px', marginRight: '5px', fontWeight: 'bold' }}
            >
              FITNESS GURU
            </Typography>
            <Typography sx={{ fontSize: '15px', marginRight: '5px' }}>
              {new Date(mealPlan?.updatedAt).toLocaleDateString('en-US', {
                month: 'long',
                year: 'numeric',
              })}
            </Typography>
          </div>
        </div>
      </div>
      {/* meal name  */}
      <div style={{ marginTop: '25px', marginBottom: '10px' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bolder' }}>
          {' '}
          {type?.charAt(0).toUpperCase() + type?.slice(1)}
        </Typography>
      </div>
      {/* table  */}
      <div>
        <table id="customers">
          <thead>
            <tr>
              <th>Meal</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {getMealsByType(type)?.map((meal, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>{meal?.mealItemId?.name}</td>
                    <td>{meal?.count + ' units '}</td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MealPreview;
