import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getDownloadURLFromFirebaseRef } from '../../utils/firebase';
import { getApi } from '../../utils/axios';
import { popAlert } from '../../utils/alert';
import loadingImage from '../../assets/loading.gif';

const ShippingDetails = ({ order }) => {
  const userType = useSelector((state) => state.auth?.user?.type);
  const [pItems, setPItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState('paid');

  const handleMarkAsShipped = () => {
    getApi()
      .patch(`orders/${order?._id}/shipping`)
      .then((res) => {
        popAlert('success!', 'Order marked as shipped !', 'success', 'Ok');
        setStatus('shipped');
      })
      .catch((err) => {
        console.error(err.response?.data?.message);
      });
  };
  //
  useEffect(() => {
    let unmounted = false;
    const fetchImage = async () => {
      const items = order?.items;
      if (!items) return;
      for (const item of items) {
        if (item.product?.image?.firebaseStorageRef) {
          const url = await getDownloadURLFromFirebaseRef(
            item.product.image.firebaseStorageRef
          );
          item.image = url;
        } else {
          // TODO: set default image
          item.image = undefined;
        }
      }
      if (!unmounted) {
        setPItems(items);
        setIsLoading(false);
      }
    };

    fetchImage();

    return () => {
      unmounted = true;
    };
  }, [order]);

  return (
    <div
      style={{
        backgroundColor: '#363636',
        borderRadius: '10px',
        padding: '20px',
        color: 'white',
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7}>
          <Grid container spacing={1}>
            {isLoading && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <CircularProgress color="primary" size={25} />
              </Box>
            )}

            {pItems?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={4}>
                    <img
                      style={{
                        height: '141px',
                        width: '129px',
                        objectFit: 'cover',
                        borderRadius: '10px',
                      }}
                      src={item.image}
                      alt="order"
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h3>{item?.product?.name}</h3>
                      <h3>RS.{item?.total}</h3>
                    </div>
                    {item?.gymWear && (
                      <div>
                        <p style={{ color: '#9A9A9A' }}>
                          color :{' '}
                          <span style={{ color: 'white' }}>
                            {item?.gymWear?.color}
                          </span>
                        </p>
                        <p style={{ color: '#9A9A9A' }}>
                          size :{' '}
                          <span style={{ color: 'white' }}>
                            {item?.gymWear?.size}
                          </span>
                        </p>
                      </div>
                    )}
                  </Grid>
                  <Divider
                    variant="fillWidth"
                    sx={{ mt: 3, backgroundColor: '#9A9A9A' }}
                  />
                </React.Fragment>
              );
            })}
          </Grid>
          <Divider
            variant="fillWidth"
            sx={{ mt: 3, backgroundColor: '#9A9A9A' }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <h3>Subtotal</h3>
            <h3 style={{ color: '#a0e220' }}>RS.{order?.subTotal}</h3>
          </div>
          <Divider variant="fillWidth" sx={{ backgroundColor: '#9A9A9A' }} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3>shipping</h3>
            <h3>RS.{order?.shipping?.charges}</h3>
          </div>
          <Divider variant="fillWidth" sx={{ backgroundColor: '#9A9A9A' }} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3>TOTAL</h3>
            <h3 style={{ color: '#a0e220' }}>RS.{order?.total}</h3>
          </div>
        </Grid>

        <Grid item xs={12} sm={5} style={{ paddingLeft: '2rem' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '350px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                style={{
                  fontSize: '20px',
                  color: '#9A9A9A',
                  marginBottom: '2rem',
                }}
              >
                SHIPPING DETAILS
              </Typography>

              <Typography
                style={{
                  fontSize: '18px',
                  color: '#9A9A9A',
                  fontStyle: 'italic',
                }}
              >
                {order?.shipping?.name}
              </Typography>
              <Typography
                style={{
                  fontSize: '18px',
                  color: '#9A9A9A',
                  fontStyle: 'italic',
                }}
              >
                {order?.shipping?.address}
              </Typography>
              <Typography
                style={{
                  fontSize: '18px',
                  color: '#9A9A9A',
                  fontStyle: 'italic',
                }}
              >
                {order?.shipping?.mobileNumber}
              </Typography>
              <Typography
                style={{
                  fontSize: '18px',
                  color: '#9A9A9A',
                  fontStyle: 'italic',
                }}
              >
                {order?.shipping?.email}
              </Typography>
            </div>
            <div>
              {userType === 'admin' && order?.status === 'paid' ? (
                <Button
                  style={{
                    color: 'white',
                    backgroundColor: '#a0e220',
                    padding: '5px 20px',
                    fontSize: '12px',
                    marginTop: '3px',
                  }}
                  onClick={handleMarkAsShipped}
                >
                  Mark as Shipped
                </Button>
              ) : (
                <Button
                  style={{
                    color: 'white',
                    backgroundColor: '#A5A5A5',
                    padding: '5px 20px',
                    fontSize: '12px',
                    marginTop: '3px',
                  }}
                >
                  Shipped
                </Button>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ShippingDetails;
