// ProfileRow.jsx
import React from 'react';
import { TableRow, TableCell } from '@mui/material';

const ProfileRow = ({ label, value }) => {
  return (
    <TableRow>
      <TableCell
        component="th"
        scope="row"
        sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
      >
        {label}
      </TableCell>
      <TableCell sx={{ fontWeight: 'b old' }}>{value}</TableCell>
    </TableRow>
  );
};

export default ProfileRow;
