import { Divider, Typography } from '@mui/material';
import React from 'react';
import profile from '../../assets/profile.png';
import './style.css';

const WorkoutPreview = ({ workoutPlan, type, user }) => {
  //
  function getWorkoutsByType(type) {
    switch (type) {
      case 'gym':
        return workoutPlan;
      case 'home':
        return workoutPlan;
      default:
        return null;
    }
  }
  //
  return (
    <div
      style={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '816px',
        '@media (minWidth: 768px)': {
          minWidth: '640px',
        },
        '@media (minWidth: 480px)': {
          minWidth: '320px',
        },
      }}
    >
      {/* header  */}
      <div
        style={{
          backgroundImage: 'linear-gradient(to top, #a0e220, #a0e220)',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={user?.profilePhotoUrl ? user?.profilePhotoUrl : profile} //TODO: add loading image
              alt="user"
              style={{
                width: '100px',
                height: '110px',
                objectFit: 'cover',
              }}
            />
            <div style={{ marginLeft: '1rem', color: 'white' }}>
              <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                {user?.fullName || user?.name}
              </Typography>

              <div style={{ display: 'flex' }}>
                <Typography sx={{ fontSize: '13px', marginRight: '5px' }}>
                  Age: {user?.personalInfo?.age || user?.age}
                </Typography>
                {user?.calculatedMetrics && (
                  <>
                    <Divider
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                      sx={{ backgroundColor: 'white', margin: '3px' }}
                    />
                    <Typography sx={{ fontSize: '13px', marginRight: '5px' }}>
                      BMR: {user?.calculatedMetrics?.bmr?.toFixed(2)}Cal
                    </Typography>
                    <Divider
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                      sx={{ backgroundColor: 'white', margin: '3px' }}
                    />
                    <Typography sx={{ fontSize: '13px', marginRight: '5px' }}>
                      DCL: {user?.calculatedMetrics?.dci?.toFixed(2)}Cal
                    </Typography>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            style={{ marginRight: '1rem', color: 'white', textAlign: 'right' }}
          >
            <Typography
              sx={{ fontSize: '14px', marginRight: '5px', fontWeight: 'bold' }}
            >
              FITNESS GURU
            </Typography>
            <Typography sx={{ fontSize: '15px', marginRight: '5px' }}>
              {new Date(workoutPlan?.updatedAt).toLocaleDateString('en-US', {
                month: 'long',
                year: 'numeric',
              })}
            </Typography>
          </div>
        </div>
      </div>
      {/* meal name  */}
      <div
        style={{
          marginTop: '20px',
          marginBottom: '5px',
          padding: '5px',
          backgroundColor: '#a0e220',
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {type?.toUpperCase()} WORKOUTS
        </Typography>
      </div>
      {/* table  */}
      <div>
        <React.Fragment>
          <h4>{getWorkoutsByType(type)?.day?.toUpperCase()}</h4>
          <table id="customers">
            <thead>
              <tr>
                <th>Day</th>
                <th style={{ width: '300px' }}>Exercise</th>
                <th>Reps</th>
                <th>Sets</th>
                <th>Rest</th>
              </tr>
            </thead>
            <tbody>
              {getWorkoutsByType(type)?.exerciseDays?.map((exerciseDay) =>
                exerciseDay.exercises.map((exercise, index) => (
                  <tr key={exercise._id}>
                    {/* Display day only on the first exercise of each day */}
                    {index === 0 ? (
                      <td rowSpan={exerciseDay.exercises.length}>
                        {exerciseDay.day}
                      </td>
                    ) : null}
                    <td>{exercise.exercise.name}</td>
                    <td>{exercise.reps}</td>
                    <td>{exercise.sets}</td>
                    <td>{exercise.rest}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </React.Fragment>
      </div>
    </div>
  );
};

export default WorkoutPreview;
