import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getApi } from '../../utils/axios';
import { popAlert } from '../../utils/alert';

const PackageInfoCard = ({ trainerId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [selectedPackageIndex, setSelectedPackageIndex] = useState(0);

  useEffect(() => {
    const fetchTrainerData = async () => {
      setLoading(true);
      try {
        // Fetch existing packages
        const packageResponse = await getApi().get(
          `/trainer-package/${trainerId}`
        );
        const existingPackages = packageResponse?.data?.data || [];
        setPackages(existingPackages);
      } catch (error) {
        popAlert('Error!', 'Failed to load trainer data.', 'error', 'OK');
      } finally {
        setLoading(false);
      }
    };

    fetchTrainerData();
  }, [trainerId]);

  // Handle Tab Change
  const handleTabChange = (event, newValue) => {
    setSelectedPackageIndex(newValue);
  };

  // Render loading message if no packages are available
  if (loading) {
    return (
      <Typography variant="h6" sx={{ mb: 4, color: 'white' }}>
        Loading...
      </Typography>
    );
  }

  if (!packages || packages.length === 0) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" sx={{ mb: 4, color: 'white' }}>
          No Package informations available
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: 2 }}>
          <Button
            variant="text"
            sx={{
              color: '#a0e220',
            }}
            onClick={() => navigate(`/package-details`)}
          >
            Add packages
          </Button>
        </Box>
      </Box>
    );
  }

  const selectedPackage = packages[selectedPackageIndex];

  return (
    <div style={{}}>
      <Typography variant="h5" sx={{ mb: 4, color: 'white' }}>
        Your Packages
      </Typography>
      <Tabs
        value={selectedPackageIndex}
        onChange={handleTabChange}
        aria-label="Package tabs"
        variant="fullWidth"
        sx={{
          marginBottom: 2,
          width: '100%',
          overflowX: 'auto',
          '& .MuiTabs-flexContainer': {
            display: 'flex',
            overflowX: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        }}
        textColor="inherit"
      >
        {packages.map((pkg, index) => (
          <Tab
            key={pkg._id}
            label={pkg.name}
            value={index}
            sx={{
              color: selectedPackageIndex === index ? 'black' : 'black',
              backgroundColor:
                selectedPackageIndex === index ? '#a0e220' : 'white',
              borderRadius: '4px',
              marginRight: '8px',
            }}
          />
        ))}
      </Tabs>

      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ backgroundColor: '#373736', color: 'white' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6" component="div">
                {selectedPackage.trainingPeriod} Months
              </Typography>
              <Typography
                variant="h6"
                component="div"
                sx={{ color: '#a0e220', fontWeight: 'bold' }}
              >
                Rs. {selectedPackage.price}
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ marginTop: 2, color: '#ccc' }}>
              {selectedPackage.packageBrief}
            </Typography>
            <Box
              sx={{ display: 'flex', justifyContent: 'flex-end', margin: 2 }}
            >
              <Button
                variant="text"
                sx={{
                  color: '#a0e220',
                }}
                onClick={() => navigate(`/package-details`)}
              >
                Edit
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default PackageInfoCard;
