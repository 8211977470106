import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer/Footer';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Button,
  Grid,
  Typography,
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Divider,
  Radio,
  FormControlLabel,
  RadioGroup,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch } from 'react-redux';
import { popAlert } from '../../utils/alert';
import { cartActions } from '../../store/cartSlice';
import { getDownloadURLFromFirebaseRef } from '../../utils/firebase';

const Container = styled('div')({
  margin: '3rem 9rem',
  '@media (max-width:600px)': {
    margin: '1rem 2rem',
  },
  '@media (max-width:480px)': {
    margin: '1rem',
  },
  '@media (max-width:1391px)': {
    margin: '1rem',
  },
});

const SignleProduct = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const productDetails = location.state;
  const [size, setSize] = useState('');
  const [qty, setQty] = useState(1);
  const [color, setColor] = useState('');
  const [image, setImage] = useState('');
  //
  const handleIncrement = () => {
    setQty(qty + 1);
  };
  //
  const handleDecrement = () => {
    setQty(qty - 1);
  };
  //
  const handleChange = (event) => {
    setSize(event.target.value);
  };
  //
  const addToCart = () => {
    if (qty <= 0)
      return popAlert(
        'Warning!',
        'Quantity must be greater than zero!',
        'warning',
        'Ok'
      );
    if (productDetails.type === 'gym-wear' && !size)
      return popAlert(
        'Warning!',
        'Preferred size must be selected!',
        'warning',
        'Ok'
      );
    if (productDetails.type === 'gym-wear' && !color)
      return popAlert(
        'Warning!',
        'Prefferd color must be selected!',
        'warning',
        'Ok'
      );
    dispatch(
      cartActions.addToCart({
        productId: productDetails._id,
        qty: parseInt(qty),
        gymWear: { size, color },
      })
    );
    popAlert('Success!', 'Item added to the cart!', 'success', 'Ok');
  };
  //
  useEffect(() => {
    let unmounted = false;
    const fetchImage = async () => {
      const url = await getDownloadURLFromFirebaseRef(
        productDetails?.image?.firebaseStorageRef
      );
      if (!unmounted && url) setImage(url);
    };

    fetchImage();

    return () => {
      unmounted = true;
    };
  }, [productDetails?.image?.firebaseStorageRef]);
  return (
    <div>
      <Container>
        <div style={{ marginTop: '50px' }}>
          <Grid container columns={12}>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <img
                style={{
                  minHeight: '494px',
                  maxWidth: '413px',
                  borderRadius: '10px',
                  objectFit: 'cover',
                }}
                alt="item"
                src={image}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="h4" color={'white'}>
                {productDetails?.name}
              </Typography>
              <Typography variant="h5" color={'#a0e220'} mt={2}>
                Rs. {productDetails?.price}
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '2rem',
                }}
              >
                <p style={{ margin: '1rem 0', color: 'white' }}>
                  {productDetails?.supplement?.description}
                </p>
              </div>
              {productDetails?.type === 'gym-wear' && (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '1.5rem',
                    }}
                  >
                    <Typography variant="h6" color={'white'}>
                      Color :
                    </Typography>
                    <RadioGroup
                      row
                      aria-label="shirt-color"
                      name="shirt-color"
                      value={color}
                      onChange={(event) => setColor(event.target.value)}
                      sx={{ ml: 4 }}
                    >
                      {productDetails?.gymWear?.colors?.map((color) => {
                        return (
                          <FormControlLabel
                            key={color}
                            value={color}
                            control={
                              <Radio
                                sx={{
                                  color: color,
                                  '&.Mui-checked': { color: color },
                                  width: 24,
                                  height: 24,
                                  borderRadius: '50%',
                                }}
                              />
                            }
                          />
                        );
                      })}
                    </RadioGroup>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" color={'white'} mt={2}>
                      Size :
                    </Typography>
                    <FormControl sx={{ m: 1, minWidth: 220, ml: 4 }}>
                      <Select
                        value={size}
                        onChange={handleChange}
                        // displayEmpty
                        inputprops={{ 'aria-label': 'Without label' }}
                        placeholder="Choose a size"
                        variant="outlined"
                        sx={{
                          color: '#9A9A9A',
                          '& fieldset': {
                            borderColor: '#D9D9D9 !important',
                          },
                          mt: 2,
                          fontSize: '14px',
                          height: '34px',
                        }}
                      >
                        {productDetails?.gymWear?.sizes?.map((size) => {
                          return (
                            <MenuItem key={size} value={size}>
                              {size}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '2rem',
                }}
              >
                <Typography variant="h6" color={'white'} mt={2}>
                  Qty :
                </Typography>
                <FormControl sx={{ ml: 5 }}>
                  <OutlinedInput
                    inputprops={{
                      startAdornment: (
                        <InputAdornment position="start" sx={{ ml: '-10px' }}>
                          <IconButton
                            size="small"
                            onClick={handleDecrement}
                            style={{
                              borderRight: '1px solid #9A9A9A',
                              borderRadius: 0,
                              padding: '0px',
                              marginRight: 1,
                            }}
                          >
                            <RemoveIcon style={{ color: '#7b7b7b' }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" sx={{ mr: '-10px' }}>
                          <IconButton
                            size="small"
                            onClick={handleIncrement}
                            style={{
                              borderLeft: '1px solid #9A9A9A',
                              borderRadius: 0,
                              padding: '0px',
                            }}
                          >
                            <AddIcon
                              style={{
                                color: '#9A9A9A',
                              }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                      inputprops: { min: 1, max: 10, step: 1 },
                      style: {
                        color: '#9A9A9A',
                        borderColor: '#9A9A9A',
                        textAlign: 'center',
                        fontSize: '14px',
                        height: '30px',
                        width: '90px',
                      },
                    }}
                    value={qty}
                    sx={{
                      fontSize: '14px',
                      '& fieldset': {
                        borderColor: '#D9D9D9 !important',
                      },
                    }}
                    style={{ width: 130, color: '#7b7b7b' }}
                    labelstyle={{ color: '#9A9A9A' }}
                    inputstyle={{ color: '#9A9A9A' }}
                    onChange={(e) => setQty(e.target.value)}
                  />
                </FormControl>
              </div>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  padding: '12px 24px',
                  backgroundImage: '#a0e220',
                  color: 'white',
                  '&:hover': {
                    backgroundImage: '#a0e220',
                  },
                  borderRadius: 4,
                  fontWeight: 'bold',
                  width: '90%',
                  mt: 10,
                }}
                onClick={addToCart}
              >
                Add to Cart
              </Button>
              <Divider
                variant="fillWidth"
                sx={{ mt: 3, backgroundColor: '#9A9A9A' }}
              />
              <h5 style={{ color: '#9A9A9A' }}>
                Add your items to the cart and then proceed to checkout.
              </h5>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default SignleProduct;
