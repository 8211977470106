import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch } from 'react-redux';
import { cartActions } from '../../store/cartSlice';
import { getDownloadURLFromFirebaseRef } from '../../utils/firebase';

const styles = {
  container: {
    display: 'flex',
    padding: '30px 0 20px 0',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  imageContainer: {
    marginRight: '-50px',
  },
  image: {
    height: '120px',
    width: '120px',
    borderRadius: '10px',
    objectFit: 'cover',
  },
  detailsContainer: {
    marginRight: '20px',
    color: 'white',
    width: '150px',
    textAlign: 'left',
  },
  name: {
    marginBottom: '10px',
  },
  color: {
    marginTop: '15px',
  },
  size: {
    marginTop: '-10px',
  },
  price: {
    color: '#9A9A9A',
  },
  subprice: {
    color: '#a0e220',
  },
  removeButton: {
    backgroundColor: '#f44336',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#d32f2f',
    },
  },
};

const CartItem = ({ cartItem }) => {
  const dispatch = useDispatch();
  const [qty, setQty] = useState(0);
  const [image, setImage] = useState('');
  //
  const handleIncrement = () => {
    const value = qty + 1;
    setQty(value);
    changeQty(value);
  };
  //
  const handleDecrement = () => {
    const value = qty - 1;
    setQty(value);
    changeQty(value);
  };
  //
  const changeQty = (quantity) => {
    if (quantity === undefined || quantity === null || quantity === '') return;
    dispatch(cartActions.changeQty({ cartId: cartItem.cartId, quantity }));
  };
  //
  const handleQtyChange = (e) => {
    setQty(e.target.value);
    changeQty(e.target.value);
  };
  //
  const handleRemove = () => {
    dispatch(cartActions.removeFromCart(cartItem.cartId));
  };
  //
  useEffect(() => {
    let unmounted = false;
    const fetchImage = async () => {
      const url = await getDownloadURLFromFirebaseRef(
        cartItem?.product?.image?.firebaseStorageRef
      );
      if (!unmounted && url) setImage(url);
    };

    fetchImage();

    return () => {
      unmounted = true;
    };
  }, [cartItem?.product?.image?.firebaseStorageRef]);
  return (
    <div>
      <div style={styles.container}>
        <div style={styles.imageContainer}>
          <img src={image} alt="item" style={styles.image} />
        </div>

        <div style={styles.detailsContainer}>
          <h3 style={styles.name}>{cartItem.product.name}</h3>
          {cartItem.product.type === 'gym-wear' && (
            <>
              <h4 style={styles.color}>
                <span style={{ color: '#9A9A9A' }}>Color:</span> Beige
              </h4>
              <h4 style={styles.size}>
                {' '}
                <span style={{ color: '#9A9A9A' }}>Size:</span> Medium
              </h4>
            </>
          )}
        </div>
        <div>
          <h3 style={styles.price}>Rs.{cartItem.total / cartItem.quantity}</h3>
        </div>
        <div>
          <FormControl sx={{ ml: 5 }}>
            <OutlinedInput
              inputprops={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ ml: '-10px' }}>
                    <IconButton
                      size="small"
                      onClick={handleDecrement}
                      style={{
                        borderRight: '1px solid #9A9A9A',
                        borderRadius: 0,
                        padding: '0px',
                        marginRight: 1,
                      }}
                    >
                      <RemoveIcon style={{ color: '#9A9A9A' }} />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" sx={{ mr: '-10px' }}>
                    <IconButton
                      size="small"
                      onClick={handleIncrement}
                      style={{
                        borderLeft: '1px solid #9A9A9A',
                        borderRadius: 0,
                        padding: '0px',
                      }}
                    >
                      <AddIcon
                        style={{
                          color: '#9A9A9A',
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
                inputProps: { min: 1, max: 10, step: 1 },
                style: {
                  color: '#9A9A9A',
                  borderColor: '#9A9A9A',
                  textAlign: 'center',
                  fontSize: '14px',
                  height: '30px',
                  width: '90px',
                },
              }}
              defaultValue={cartItem.quantity}
              sx={{
                fontSize: '14px',
                '& fieldset': {
                  borderColor: '#D9D9D9 !important',
                },
              }}
              style={{ width: 130, color: '#7b7b7b' }}
              labelstyle={{ color: '#9A9A9A' }}
              inputstyle={{ color: '#9A9A9A' }}
              onChange={handleQtyChange}
            />
          </FormControl>
        </div>
        <div>
          <h3 style={styles.subprice}>Rs.{cartItem.total}</h3>
        </div>
        <div>
          <Button style={styles.removeButton} onClick={handleRemove}>
            Remove
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
