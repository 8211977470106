import {
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import React, { useState } from 'react';
import PlusIcon from '../../../assets/plus.png';
import { getApiForFormData } from '../../../utils/axios';
import { popAlert } from '../../../utils/alert';
import { useNavigate } from 'react-router-dom';

const UpdateProductForm = ({ product }) => {
  const navigate = useNavigate;
  const [type, setType] = useState(product?.type);
  const [colors, setColors] = useState(product?.gymWear?.colors);
  const [newColor, setNewColor] = useState('');
  const [sizes, setSizes] = useState(product?.gymWear?.sizes);
  const [newSize, setNewSize] = useState('');
  const [price, setPrice] = useState();
  const [description, setDescription] = useState('');
  const [images, setImages] = useState(product?.image);
  const [name, setProductName] = useState(product?.name);

  const handleChange = (event) => {
    setType(event.target.value);
  };
  //
  const handleColors = () => {
    if (newColor.trim() !== '') {
      setColors([...colors, newColor.trim()]);
      setNewColor('');
    }
  };
  const handleSizes = (e) => {
    if (newSize.trim() !== '') {
      setSizes([...sizes, newSize.trim()]);
      setNewSize('');
    }
  };
  //
  const handleUpdateProduct = async () => {
    const body = {
      type,
      name,
      price,
      gymWear: { colors, sizes },
      supplement: { description },
    };
    const formData = new FormData();
    formData.append('strigifiedBody', JSON.stringify(body));
    formData.append('file', images);

    await getApiForFormData()
      .patch(`/products/${product?._id}`, formData)
      .then((res) => {
        popAlert('success!', 'Product updated successfully!', 'success', 'Ok');
      })
      .catch((err) => {
        err.response?.data?.message &&
          popAlert('Error!', err.response?.data?.message, 'error', 'OK');
      });
  };
  // Map over the array of color codes to render input fields with the corresponding background color
  const colorInputs = colors.map((color, index) => (
    <OutlinedInput
      key={index}
      type="text"
      placeholder="#"
      id="color"
      name="color"
      required
      value={color}
      sx={{
        mr: '1rem',
        color: '#7b7b7b',
        borderRadius: 4,
        border: '1px solid #707070',
        width: '13%',
        marginBottom: 2,
        backgroundColor: color, // set the background color dynamically based on the color value
        '& label.Mui-focused': { color: '#008000' },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#008000',
        },
        '& input::placeholder': { color: '#7b7b7b' },
        '& input': { color: '#7b7b7b' },
      }}
      onChange={(e) => setNewColor(e.target.value)}
    />
  ));
  //
  const sizeInput = sizes.map((size, index) => (
    <OutlinedInput
      value={size}
      key={index}
      type="text"
      placeholder=""
      id="size"
      name="size"
      required
      sx={{
        mr: '1rem',
        color: '#7b7b7b',
        borderRadius: 4,
        border: '1px solid #707070',
        width: '13%',
        marginBottom: 2,
        backgroundColor: '#373736',
        '& label.Mui-focused': { color: '#008000' },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#008000',
        },
        '& input::placeholder': { color: '#7b7b7b' },
        '& input': { color: '#7b7b7b' },
      }}
      onChange={(e) => setNewSize(e.target.value)}
    />
  ));
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            margin: '2rem 0',
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          Update Product
        </Typography>
        <button
          style={{
            color: 'black',
            backgroundColor: '#a0e220',
            border: '1px solid black',
            padding: '10px 15px ',
            cursor: 'pointer',
          }}
          onClick={handleUpdateProduct}
        >
          Update
        </button>
      </div>
      <form>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <InputLabel
            htmlFor="email"
            sx={{
              color: 'white',
              mt: 2,
              mb: 1,
              fontSize: '1.3rem',
              width: '250px',
            }}
          >
            Product Type
          </InputLabel>
          <Select
            defaultValue={product?.type}
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            onChange={handleChange}
            autoWidth
            sx={{
              color: '#7b7b7b',
              borderRadius: 4,
              border: '1px solid #707070',
              width: '100%',
              marginBottom: 2,
              backgroundColor: '#373736', // set the background color
              '& label.Mui-focused': { color: '#008000' },
              '& .MuiInput-underline:after': { borderBottomColor: '#008000' },
              '& input::placeholder': { color: '#7b7b7b' },
              '& input': { color: '#7b7b7b' },
            }}
            label="type"
          >
            <MenuItem value={'supplement'}>Supplement</MenuItem>
            <MenuItem value={'gym-wear'}>Gym Wear</MenuItem>
          </Select>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <InputLabel
            sx={{
              color: 'white',
              mt: 2,
              mb: 1,
              fontSize: '1.3rem',
              width: '250px',
            }}
          >
            Product Name
          </InputLabel>
          <OutlinedInput
            defaultValue={product?.name}
            placeholder="Product Name"
            type="text"
            id="name"
            sx={{
              borderRadius: 4,
              border: '1px solid #707070',
              width: '100%',
              marginBottom: 2,
              backgroundColor: '#373736', // set the background color
              '& label.Mui-focused': { color: '#008000' },
              '& .MuiInput-underline:after': { borderBottomColor: '#008000' },
              '& input::placeholder': { color: '#7b7b7b' },
              '& input': { color: '#7b7b7b' },
            }}
            onChange={(e) => setProductName(e.target.value)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <InputLabel
            htmlFor="number"
            sx={{
              color: 'white',
              mt: 2,
              mb: 1,
              fontSize: '1.3rem',
              width: '250px',
            }}
          >
            Product Price
          </InputLabel>
          <OutlinedInput
            defaultValue={product?.price}
            placeholder="Product Price"
            type="number"
            id="price"
            endAdornment={
              <InputAdornment position="end">
                <Typography sx={{ color: 'white' }}>LKR</Typography>
              </InputAdornment>
            }
            sx={{
              borderRadius: 4,
              border: '1px solid #707070',
              width: '100%',
              marginBottom: 2,
              backgroundColor: '#373736', // set the background color
              '& label.Mui-focused': { color: '#008000' },
              '& .MuiInput-underline:after': { borderBottomColor: '#008000' },
              '& input::placeholder': { color: '#7b7b7b' },
              '& input': { color: '#7b7b7b' },
            }}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <InputLabel
            htmlFor="email"
            sx={{
              color: 'white',
              mt: 2,
              mb: 1,
              fontSize: '1.3rem',
              width: '250px',
            }}
          >
            Description
          </InputLabel>
          <OutlinedInput
            defaultValue={product?.supplement?.description}
            multiline
            rows={4}
            placeholder="Description"
            id="des"
            name="des"
            required
            sx={{
              color: '#7b7b7b',
              borderRadius: 4,
              border: '1px solid #707070',
              width: '100%',
              marginBottom: 2,
              backgroundColor: '#373736', // set the background color
              '& label.Mui-focused': { color: '#008000' },
              '& .MuiInput-underline:after': { borderBottomColor: '#008000' },
              '& input::placeholder': { color: '#7b7b7b' },
              '& input': { color: '#7b7b7b' },
            }}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        {type === 'gym-wear' && (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <InputLabel
                htmlFor="text"
                sx={{
                  color: 'white',
                  mt: 2,
                  mb: 1,
                  fontSize: '1.3rem',
                  width: '250px',
                }}
              >
                Colors
              </InputLabel>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  width: '100%',
                }}
              >
                {colorInputs}
                <OutlinedInput
                  type="text"
                  placeholder="#"
                  id="color"
                  name="color"
                  required
                  sx={{
                    mr: '1rem',
                    color: '#7b7b7b',
                    borderRadius: 4,
                    border: '1px solid #707070',
                    width: '13%',
                    marginBottom: 2,
                    backgroundColor: '#363636',
                    '& label.Mui-focused': { color: '#008000' },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008000',
                    },
                    '& input::placeholder': { color: '#7b7b7b' },
                    '& input': { color: '#7b7b7b' },
                  }}
                  onChange={(e) => setNewColor(e.target.value)}
                />

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#363636',
                    padding: '0.5rem 1rem',
                    height: '53%',
                    borderRadius: '10px',
                  }}
                >
                  <img alt="plus" src={PlusIcon} onClick={handleColors} />
                </div>
              </div>
            </div>

            {/* sizes  */}

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <InputLabel
                htmlFor="email"
                sx={{
                  color: 'white',
                  mt: 2,
                  mb: 1,
                  fontSize: '1.3rem',
                  width: '250px',
                }}
              >
                Sizes
              </InputLabel>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  width: '100%',
                }}
              >
                {sizeInput}
                <OutlinedInput
                  type="text"
                  placeholder=""
                  id="size"
                  name="size"
                  required
                  sx={{
                    mr: '1rem',
                    color: '#7b7b7b',
                    borderRadius: 4,
                    border: '1px solid #707070',
                    width: '13%',
                    marginBottom: 2,
                    backgroundColor: '#373736',
                    '& label.Mui-focused': { color: '#008000' },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008000',
                    },
                    '& input::placeholder': { color: '#7b7b7b' },
                    '& input': { color: '#7b7b7b' },
                  }}
                  onChange={(e) => setNewSize(e.target.value)}
                />

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#363636',
                    padding: '0.5rem 1rem',
                    height: '53%',
                    borderRadius: '10px',
                  }}
                >
                  <img alt="plus" src={PlusIcon} onClick={handleSizes} />
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <InputLabel
            htmlFor="email"
            sx={{
              color: 'white',
              mt: 2,
              mb: 1,
              fontSize: '1.3rem',
              width: '250px',
            }}
          ></InputLabel>
          <div
            style={{
              border: '1px solid #818180',
              padding: '1rem',
              borderRadius: '10px',
              marginBottom: '2rem',
              width: '100%',
              height: '100px',
            }}
          >
            <div
              style={{
                border: 'dotted',
                borderColor: '#818180',
                height: '100px',
                borderRadius: '10px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button variant="outlined" component="label" color="success">
                Upload photos
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={(e) => setImages(Array.from(e.target.files))}
                />
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateProductForm;
