import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import { Button, Typography } from '@mui/material';
import axios from 'axios';
import { getApi } from '../../../utils/axios';
import { popAlert } from '../../../utils/alert';
import { getDownloadURLFromFirebaseRef } from '../../../utils/firebase';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Loader from '../../../components/Loader/Loader';

const styles = {
  tableCell: { color: '#9A9A9A', fontSize: '1rem' },
  bodyTableCell: { color: '#9A9A9A', fontSize: '0.9rem' },
  seeMoreBtn: {
    color: '#a0e220',
    backgroundColor: '#1D1D1D',
    border: '1px solid #a0e220',
    padding: 5,
  },
  expandedContent: {
    backgroundColor: '#363636',
    height: '100px',
  },
  expandedContentText: {
    color: '#9A9A9A',
    fontSize: '0.8rem',
  },
  expandedSubContentText: {
    color: 'white',
    fontSize: '0.8rem',
    fontWeight: 'bold',
  },
};

const TrainerManage = () => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);

  const handleChange = (event, value) => {
    setPage(value);
  };
  //
  const handleExpandRow = (orderId) => {
    if (expandedRows.includes(orderId)) {
      setExpandedRows(expandedRows.filter((id) => id !== orderId));
    } else {
      setExpandedRows([...expandedRows, orderId]);
    }
  };
  //
  const handleReject = async (id) => {
    if (id) {
      await getApi()
        .patch(`users/${id}/trainer-status`, { action: 'reject' })
        .then((res) => {
          popAlert(
            'Success!',
            'Trainer rejected successfully',
            'success',
            'Ok'
          );
        })
        .catch((err) => {
          popAlert('Error!', "Can't reject the request", 'error', 'Ok');
        });
    }
  };
  const handleAccept = async (id) => {
    if (id) {
      await getApi()
        .patch(`users/${id}/trainer-status`, { action: 'accept' })
        .then((res) => {
          popAlert(
            'Success!',
            'Trainer accepted successfully',
            'success',
            'Ok'
          );
        })
        .catch((err) => {
          popAlert('Error!', "Can't accept the request", 'error', 'Ok');
        });
    }
  };
  //
  const handleCertificateView = (url) => {
    window.open(url, '_blank');
  };
  //
  useEffect(() => {
    const source = axios.CancelToken.source();
    getApi()
      .get(`users?type=trainer&page=${page}&limit=13&orderBy=desc`)
      .then(async (res) => {
        const trainers = res?.data?.content || [];

        // resolve trainer certificates
        for (const trainer of trainers) {
          if (trainer.trainer?.certificate?.firebaseStorageRef) {
            const url = await getDownloadURLFromFirebaseRef(
              trainer.trainer.certificate.firebaseStorageRef
            );
            trainer.trainer.certificate = url;
          } else {
            // TODO: set default image
            trainer.trainer.certificate = undefined;
          }
        }
        setTrainers(trainers);
        setTotal(res?.data?.totalElements);
        setTotalPages(res.data.totalPages || 0);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err?.response?.data?.message);
        setIsLoading(false);
      });
    return () => {
      source.cancel();
    };
  }, [page]);

  //
  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '80vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader />
      </div>
    );
  }
  //
  return (
    <div>
      {/* header  */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            marginBottom: 1,
            color: '#a0e220',
            fontWeight: 'bold',
          }}
        >
          Manage Trainers
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              marginBottom: 1,
              color: '#9A9A9A',
              fontWeight: 'normal',
              marginRight: '30px',
            }}
          >
            TOTAL TRAINERS
          </Typography>
          <button
            style={{
              color: '#a0e220',
              backgroundColor: '#1D1D1D',
              border: '1px solid #a0e220',
              padding: '10px 25px ',
              cursor: 'pointer',
              fontSize: '20px',
              marginBottom: '5px',
            }}
          >
            {trainers?.length}
          </button>
        </div>
      </div>
      {/* expandable table view */}
      <TableContainer component={Paper} sx={{ border: '15px solid #1D1D1D' }}>
        <Table
          sx={{
            minWidth: 650,
            backgroundColor: '#1D1D1D',
            '& td, & th': { borderColor: '#9A9A9A' },
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableCell}>TRAINER ID</TableCell>
              <TableCell sx={styles.tableCell}>NAME</TableCell>
              <TableCell sx={styles.tableCell}>SINCE</TableCell>
              <TableCell sx={styles.tableCell}>EXPERIENCE</TableCell>
              <TableCell sx={styles.tableCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trainers.map((row) => (
              <React.Fragment key={row._id}>
                <TableRow
                  sx={{
                    '& td, & th': { borderColor: '#9A9A9A' },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={styles.bodyTableCell}
                  >
                    {row?._id}
                  </TableCell>
                  <TableCell sx={styles.bodyTableCell}>
                    {' '}
                    {row?.firstName}
                    {'  '}
                    {row?.lastName}{' '}
                  </TableCell>
                  <TableCell sx={styles.bodyTableCell}>
                    {new Date(row.createdAt).toLocaleString('en-US', {
                      month: 'short',
                      year: 'numeric',
                    })}
                  </TableCell>
                  <TableCell sx={styles.bodyTableCell}>
                    {' '}
                    {row?.trainer.lenth !== 0
                      ? row?.trainer?.careerLength
                      : 'waiting..'}
                  </TableCell>
                  <TableCell sx={styles.bodyTableCell}>
                    <button
                      style={styles.seeMoreBtn}
                      onClick={() => handleExpandRow(row._id)}
                    >
                      {expandedRows.includes(row._id) ? 'see less' : 'see more'}
                    </button>
                  </TableCell>
                </TableRow>
                {expandedRows.includes(row._id) && (
                  <TableRow style={{ backgroundColor: '#363636' }}>
                    <TableCell colSpan={5}>
                      <div style={{ display: 'flex' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: '20px',
                          }}
                        >
                          <Typography style={styles.expandedContentText}>
                            Name
                          </Typography>
                          <Typography style={styles.expandedContentText}>
                            Address
                          </Typography>
                          <Typography style={styles.expandedContentText}>
                            Mobile{' '}
                          </Typography>
                          <Typography style={styles.expandedContentText}>
                            Email{' '}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: '150px',
                            width: '200px',
                          }}
                        >
                          <Typography style={styles.expandedSubContentText}>
                            {row.firstName} {row.lastName}
                          </Typography>
                          <Typography style={styles.expandedSubContentText}>
                            {' '}
                            {row.address}
                          </Typography>
                          <Typography style={styles.expandedSubContentText}>
                            {' '}
                            {row.mobileNumber}
                          </Typography>
                          <Typography style={styles.expandedSubContentText}>
                            {' '}
                            {row.auth._id}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: '20px',
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            color="primary"
                            style={{ color: '#fff' }}
                            onClick={() =>
                              handleCertificateView(row.trainer?.certificate)
                            }
                          >
                            View Certificate &nbsp;
                            <PictureAsPdfIcon />
                          </Button>
                        </div>
                        {row?.trainer?.isVerificationSubmited &&
                          !row?.trainer?.isVerified &&
                          !row?.trainer?.isRejected && (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginRight: '70px',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                style={{
                                  color: 'white',
                                  backgroundColor: '#FF6767',
                                  padding: '5px 20px',
                                  fontSize: '12px',
                                  margin: '3px',
                                }}
                                onClick={() => handleReject(row._id)}
                              >
                                Reject
                              </Button>

                              <Button
                                style={{
                                  color: 'white',
                                  backgroundColor: '#a0e220',
                                  padding: '5px 20px',
                                  fontSize: '12px',
                                  margin: '3px',
                                }}
                                onClick={() => handleAccept(row?._id)}
                              >
                                Accept
                              </Button>
                            </div>
                          )}
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChange}
          fontWeight={'bold'}
          color="primary"
          shape="rounded"
        />
      </div>
    </div>
  );
};

export default TrainerManage;
