import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getApi } from '../../utils/axios';
import { popAlert } from '../../utils/alert';

const Portfolio = ({ image, trainerId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const fetchTrainerData = async () => {
      setLoading(true);
      try {
        // Fetch existing packages
        const packageResponse = await getApi().get(
          `/trainer-package/${trainerId}`
        );
        const existingPackages = packageResponse?.data?.data || [];
        setPackages(existingPackages);
      } catch (error) {
        popAlert('Error!', 'Failed to load trainer data.', 'error', 'OK');
      } finally {
        setLoading(false);
      }
    };

    fetchTrainerData();
  }, [trainerId]);
  // Render loading message if no images are available
  if (!image || image.length === 0) {
    return (
      <Typography variant="h6" sx={{ mb: 4, color: 'white' }}>
        No portfolio images available
      </Typography>
    );
  }
  //
  return (
    <div style={{ paddingBottom: '4.2rem' }}>
      <Typography variant="h5" sx={{ mb: 4, color: 'white' }}>
        Your Portfolio
      </Typography>
      <Card
        sx={{ maxWidth: 450, cursor: 'pointer', objectFit: 'cover' }}
        onClick={() => navigate('/trainer-info')}
      >
        <CardMedia
          component="img"
          alt="portfolio img"
          height="210"
          image={image[0]?.portfolioFileUrl}
          style={{ objectFit: 'cover' }}
        />
        <CardContent>
          {/* Container for title and package names */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
            }}
          >
            {/* Portfolio title */}
            <Typography variant="h5" component="div">
              My Portfolio
            </Typography>

            {/* Package names */}
            <Box sx={{ textAlign: 'right', flexGrow: 1, ml: 2 }}>
              {packages?.map((packageData, index) => (
                <React.Fragment key={packageData?._id}>
                  <Typography
                    variant="body2"
                    component="span"
                    color="text.secondary"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {packageData?.name}
                  </Typography>
                  {index < packages.length - 1 && (
                    <Typography
                      variant="body2"
                      component="span"
                      color="text.secondary"
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      {' | '}
                    </Typography>
                  )}
                </React.Fragment>
              ))}
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          <Stack direction="row" spacing={1} alignItems="center"></Stack>
        </CardActions>
      </Card>
    </div>
  );
};

export default Portfolio;
