import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  InputBase,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container } from '../../components/commonStyle';
import { authActions } from '../../store/authSlice';
import { popAlert } from '../../utils/alert';
import { getApiForFormData } from '../../utils/axios';

const CareerDetails = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileRef = useRef(null);
  const [trainingFor, setTrainingFor] = useState('Men');
  const [yearsOfExperience, setYearsOfExperience] = useState(0);
  const [file, setFile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(''); //get for preview the pdf
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [socialLink, setSocialLink] = useState('');
  const [isUpdatable, setIsUpdatable] = useState(false);

  useEffect(() => {
    const setCarrerDetails = () => {
      setTrainingFor(user?.trainingFor);
      setYearsOfExperience(user?.yearsOfExperience);
      setSocialLink(user?.socialLink);
      setFile({ name: user?.certificationFileUrl });
      setIsUpdatable(true);
    };
    if (user?.trainingFor && user?.yearsOfExperience && user?.socialLink) {
      setCarrerDetails();
    } else setIsUpdatable(false);
  }, [user]);

  const handleRadioChange = (event) => {
    setTrainingFor(event.target.value);
  };
  //
  const handlePdfFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setPdfUrl(event.target.result);
    };
  };
  //
  const handlRemoveFile = () => {
    setFile(null);
    fileRef.current.value = '';
  };
  //
  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();

    formData.append('trainingFor', trainingFor);
    formData.append('yearsOfExperience', yearsOfExperience);
    formData.append('socialLink', socialLink);

    if (file) {
      formData.append('file', file);
    } else {
      console.error('No file selected.');
      return;
    }

    try {
      const response = await getApiForFormData().post(
        '/trainer/add-career-details',
        formData
      );
      const careerDetails = response?.data?.data;

      if (careerDetails) {
        dispatch(authActions.setUser(careerDetails));
        popAlert('Success!', response.data.message, 'success', 'Ok').then(
          () => {
            navigate('/trainer-profile');
          }
        );
      }
    } catch (err) {
      if (err.response?.data?.message) {
        popAlert('Error!', err.response.data.message, 'error', 'OK');
      }
      if (err.response?.data?.data) {
        setErrors(err.response.data);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    const formData = new FormData();

    formData.append('trainingFor', trainingFor);
    formData.append('yearsOfExperience', yearsOfExperience);
    formData.append('socialLink', socialLink);

    try {
      const response = await getApiForFormData().put('/trainer', formData);
      const careerDetails = response?.data?.data;

      if (careerDetails) {
        dispatch(authActions.setUser(careerDetails));
        popAlert('Success!', response.data.message, 'success', 'Ok').then(
          () => {
            navigate('/trainer-info');
          }
        );
      }
    } catch (err) {
      if (err.response?.data?.message) {
        popAlert('Error!', err.response.data.message, 'error', 'OK');
      }
      if (err.response?.data?.data) {
        setErrors(err.response.data);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <h1
        style={{
          textAlign: 'center',
          color: '#a0e220',
        }}
      >
        Career Details
      </h1>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <InputLabel sx={{ color: 'white', mt: 0, fontSize: '1.4rem' }}>
            Who are you training?
          </InputLabel>
          <RadioGroup
            aria-label="options"
            name="preferredGender"
            value={trainingFor}
            onChange={handleRadioChange}
            style={{ marginLeft: '20px', color: '#7b7b7b' }}
          >
            <FormControlLabel
              value="Men"
              control={
                <Radio
                  sx={{
                    color: '#363636',
                    '&.Mui-checked': { color: '#a0e220' },
                  }}
                />
              }
              label="Men"
            />
            <FormControlLabel
              value="Women"
              control={
                <Radio
                  sx={{
                    color: '#363636',
                    '&.Mui-checked': { color: '#a0e220' },
                  }}
                />
              }
              label="Women"
            />
            <FormControlLabel
              value="MenAndWomen"
              control={
                <Radio
                  sx={{
                    color: '#363636',
                    '&.Mui-checked': { color: '#a0e220' },
                  }}
                />
              }
              label="Men & women"
            />
          </RadioGroup>
          <InputLabel sx={{ color: 'white', mt: 1, mb: 1, fontSize: '1.4rem' }}>
            How long are you working as a professional trainer?
          </InputLabel>
          <InputBase
            id="outlined-number"
            type="number"
            variant="outlined"
            name="careerLength"
            value={yearsOfExperience}
            sx={{
              border: '1px solid #818180',
              borderColor: '#818180',
              width: '20%',
              '& label.Mui-focused': { color: '#008000' },
              '& .MuiInput-underline:after': { borderBottomColor: '#008000' },
              '& input::placeholder': { color: '#7b7b7b' },
              '& input': { color: '#7b7b7b' },
              padding: 1,
            }}
            onChange={(e) => setYearsOfExperience(e.target.value)}
          />
          {errors['careerLength'] && (
            <Typography color="error">{errors['careerLength']}</Typography>
          )}

          <InputLabel
            sx={{ color: 'white', mt: 1, mb: 1.5, fontSize: '1.4rem' }}
          >
            Upload your trainer certification file.
          </InputLabel>
          {file && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                padding: '10px',
              }}
            >
              <DescriptionIcon sx={{ color: 'red' }} />
              <a
                href={file.name}
                style={{
                  color: 'white',
                  maxWidth: '400px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'block',
                  textDecoration: 'none',
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {file.name}
              </a>
              <CloseIcon
                sx={{ color: '#707070', cursor: 'pointer' }}
                onClick={handlRemoveFile}
              />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '4rem 3rem ',
              border: 'dotted',
              borderColor: '#818180',
              borderRadius: '10px',
            }}
          >
            <Button variant="outlined" component="label" color="success">
              Upload file
              <input
                ref={fileRef}
                hidden
                name="file"
                type="file"
                onChange={handlePdfFileChange}
              />
            </Button>
          </div>

          <InputLabel sx={{ color: 'white', mt: 1, mb: 1, fontSize: '1.4rem' }}>
            Social Media URL
          </InputLabel>
          <InputBase
            id="outlined-number"
            variant="outlined"
            name="socialMedia"
            placeholder="Facebook | Instagram | TikTok URL"
            sx={{
              border: '1px solid #818180',
              borderColor: '#818180',
              '& label.Mui-focused': { color: '#008000' },
              '& .MuiInput-underline:after': { borderBottomColor: '#008000' },
              '& input::placeholder': { color: '#7b7b7b' },
              '& input': { color: '#7b7b7b' },
              padding: 1,
            }}
            value={socialLink}
            onChange={(e) => setSocialLink(e.target.value)}
          />

          <Button
            variant="contained"
            sx={{
              backgroundImage: '#a0e220', // add background image
              color: 'white',
              '&:hover': {
                backgroundImage: '#a0e220', // change background image on hover
              },
              fontWeight: 'bold',
              padding: '0.7rem 2rem',
              width: '100%',
              mt: 3,
              mb: 5,
            }}
            onClick={isUpdatable ? handleUpdate : handleSubmit}
          >
            <Typography sx={{ fontWeight: 'bold', color: 'black' }}>
              {isUpdatable ? 'update' : 'continue'}
            </Typography>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: 'black',
                  position: 'absolute',
                  left: '65%',
                  top: '50%',
                  marginLeft: '-12px', // Half of the size
                  marginTop: '-12px', // Half of the size
                }}
              />
            )}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default CareerDetails;
