import { Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const VerificationSubmitted = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Button
        variant="contained"
        sx={{
          mt: 1,
          backgroundImage: '#a0e220', // add background image
          color: 'white',
          '&:hover': {
            backgroundImage: '#a0e220', // change background image on hover
          },
          fontWeight: 'bold',
          padding: '0.7rem 3rem',
          width: '50%',
        }}
        onClick={() => navigate('/career-details')}
      >
        <Typography color={'black'}>Start Training</Typography>
      </Button>
      <Typography
        variant="p"
        sx={{
          margin: 4,
          color: 'white',
          textAlign: 'center',
          lineHeight: '1.5rem',
        }}
      >
        By clicking Start Training button, you will be registered as a Trainer
        in Fitness Guru and, you will get to list your portfolio here.
      </Typography>
    </div>
  );
};

export default VerificationSubmitted;
