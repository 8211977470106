import axios from 'axios';
import { authActions } from '../store/authSlice';
import { popAlert } from './alert';
import { refreshAccessTokenFn } from './helpers';
import store from '../store/store';

// Create a new Axios instance
const Request = axios.create({
  baseURL: 'https://fitness-guru.onrender.com/',
  headers: {
    'Content-Type': 'application/json',
  },
});
// Add a request interceptor
Request.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const tokens = state.auth.tokens;
    if (tokens && tokens.accessToken) {
      config.headers['Authorization'] = `Bearer ${tokens.accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
Request.interceptors.response.use(
  (response) => response,
  async (error) => {
    const state = store.getState();
    const tokens = state.auth.tokens;
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      tokens
    ) {
      originalRequest._retry = true;

      try {
        const { data } = await refreshAccessTokenFn();
        if (data && data.accessToken && data.refreshToken) {
          store.dispatch(authActions.setAuthTokens(data));

          Request.defaults.headers[
            'Authorization'
          ] = `Bearer ${data.accessToken}`;
          originalRequest.headers[
            'Authorization'
          ] = `Bearer ${data.accessToken}`;

          return Request(originalRequest);
        }
      } catch (err) {
        store.dispatch(authActions.clearAuth());
        return Promise.reject(err);
      }
    }

    if (error?.response?.status === 401) {
      popAlert(
        'Error!',
        'Unauthorized. Please log in again.',
        'error',
        'Ok'
      ).then(() => {
        store.dispatch(authActions.logout());
        window.location.href = '/login';
      });
    }

    return Promise.reject(error);
  }
);

export const getApi = () => Request;

export const getApiForFormData = () => {
  const instance = axios.create({
    baseURL: 'https://fitness-guru.onrender.com',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  instance.interceptors.request.use(
    (config) => {
      const state = store.getState();
      const tokens = state.auth.tokens;
      if (tokens && tokens.accessToken) {
        config.headers['Authorization'] = `Bearer ${tokens.accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const state = store.getState();
      const tokens = state.auth.tokens;
      const originalRequest = error.config;

      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry &&
        tokens
      ) {
        originalRequest._retry = true;

        try {
          const { data } = await refreshAccessTokenFn();
          if (data && data.accessToken && data.refreshToken) {
            store.dispatch(authActions.setAuthTokens(data));

            instance.defaults.headers[
              'Authorization'
            ] = `Bearer ${data.accessToken}`;
            originalRequest.headers[
              'Authorization'
            ] = `Bearer ${data.accessToken}`;

            return instance(originalRequest);
          }
        } catch (err) {
          store.dispatch(authActions.clearAuth());
          return Promise.reject(err);
        }
      }

      if (error.response.status === 401) {
        popAlert(
          'Error!',
          'Unauthorized. Please log in again.',
          'error',
          'Ok'
        ).then(() => {
          store.dispatch(authActions.logout());
          window.location.href = '/login';
        });
      }

      return Promise.reject(error);
    }
  );

  return instance;
};
