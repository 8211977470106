import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const styles = {
    root: {
      backgroundColor: 'black',
      paddingTop: '30px',
    },
    section: {
      color: '#FFFFFF',
    },
    heading: {
      marginBottom: '30px',
      fontWeight: 'bold',
      color: '#FFFFFF',
    },
    socialIcon: {
      marginRight: '10px',
      fontSize: '24px',
      color: '#333',
    },
    address: {
      fontSize: '16px',
      color: '#FFFFFF',
      cursor: 'pointer',
    },
    copyright: {
      backgroundColor: '#1D1D1D',
      height: '3rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '20px',
    },
  };

  return (
    <footer style={styles.root}>
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6} md={4}>
            <div style={styles.section}>
              <Typography variant="h5" style={styles.heading}>
                FITNESS GURU
              </Typography>
              <Typography variant="subtitle1" sx={{ lineHeight: 1.2 }}>
                Fitness Guru is the ultimate one stop station for all your
                fitness needs. Imagine having a partner who will guide you
                through all your fitness needs including proper meal plans,
                workout routines, accuracy of exercises.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={2.5}>
            <div style={styles.section}>
              <Typography variant="h6" style={styles.heading}>
                Services
              </Typography>
              <Typography variant="subtitle1" style={styles.address}>
                Meal Plans
              </Typography>
              <Typography variant="subtitle1" style={styles.address}>
                Workout Routines
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={2.5}>
            <div style={styles.section}>
              <Typography variant="h6" style={styles.heading}>
                Company
              </Typography>

              <Typography
                variant="subtitle1"
                style={styles.address}
                onClick={() => navigate('/privacy-policy')}
              >
                Privacy Policy
              </Typography>
              <Typography
                variant="subtitle1"
                style={styles.address}
                onClick={() => navigate('/term-conditions')}
              >
                Terms & Conditions
              </Typography>
              <Typography
                variant="subtitle1"
                style={styles.address}
                onClick={() => navigate('/return-policy')}
              >
                Return Policy
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={2.5}>
            <div style={styles.section}>
              <Typography variant="h6" style={styles.heading}>
                About us
              </Typography>
              <Typography variant="subtitle1" style={styles.address}>
                No.186/F/12, Yabaralauwa
              </Typography>
              <Typography variant="subtitle1" style={styles.address}>
                North Malwana.
              </Typography>

              <Typography variant="subtitle1" style={styles.address}>
                011 246 5644
              </Typography>
              <Typography variant="subtitle1" style={styles.address}>
                contact@fitnessgurulk.com
              </Typography>
              <br />
              <Grid container spacing={2.5}>
                <Grid item>
                  <FacebookRoundedIcon />
                </Grid>
                <Grid item>
                  <InstagramIcon />
                </Grid>
                <Grid item>
                  <TwitterIcon />
                </Grid>
                <Grid item>
                  <LinkedInIcon />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
      <div style={styles.copyright}>
        <Typography sx={{ color: '#FFFFFF' }}>
          &copy; FITNESS GURU 2024
        </Typography>
      </div>
    </footer>
  );
};

export default Footer;
