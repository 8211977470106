import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { finessRecordsActions } from '../../store/finessRecordSlice';
import styled from '@emotion/styled';
import {
  Button,
  Typography,
  Grid,
  OutlinedInput,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { getApi } from '../../utils/axios';
import { useLocation, useNavigate } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';

const Container = styled('div')({
  margin: '3rem 9rem',
  '@media (max-width:600px)': {
    margin: '1rem 2rem',
  },
  '@media (max-width:480px)': {
    margin: '1rem',
  },
});
const PersonalInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [age, setAge] = useState(state?.age || '');
  const [weight, setWeight] = useState(state?.weight || '');
  const [height, setHeight] = useState(state?.height || '');
  const [activityLevel, setActivityLevel] = useState(
    state?.activityLevel || ''
  );
  const [goal, setGoal] = useState(state?.goal || '');
  const [expertiseLevel, setExpertiseLevel] = useState(
    state?.expertiseLevel || ''
  );
  const [unitType, setUnitType] = useState('imperial');

  const handleUnitType = (type) => {
    if (unitType === 'metric' && type === 'imperial') {
      const calcHeight = (height * 0.3937).toFixed(2);
      const calcWeight = (weight * 2.20462).toFixed(2);
      setHeight(calcHeight);
      setWeight(calcWeight);
    } else if (unitType === 'imperial' && type === 'metric') {
      const calcHeight = (height / 0.3937).toFixed(2);
      const calcWeight = (weight / 2.20462).toFixed(2);
      setHeight(calcHeight);
      setWeight(calcWeight);
    }
    setUnitType(type);
  };

  const handleContinue = async () => {
    const response = await getApi()
      .post('/fitness-data/calory-requirements', {
        age: age,
        gender: 'male', //TODO:user profile sex
        weight: {
          value: weight,
          unit: unitType === 'metric' ? 'kg' : 'lb',
        },
        height: {
          value: height,
          unit: unitType === 'metric' ? 'cm' : 'in',
        },
        activityLevel: activityLevel,
        goal: goal,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err?.response?.data?.message);
      });

    const personalDetails = {
      age: age,
      gender: 'male',
      weight: {
        value: weight,
        unit: 'kg',
      },
      height: {
        value: height,
        unit: 'cm',
      },
      activityLevel: activityLevel,
      goal: goal,
      expertiseLevel: expertiseLevel,
    };

    dispatch(finessRecordsActions.updatePersonalDetails(personalDetails));
    dispatch(
      finessRecordsActions.updateCaloriesRequirenment(
        response.caloryRequirements
      )
    );
    navigate('/meal');
  };

  return (
    <div>
      <Container>
        <Typography
          variant="h4"
          sx={{
            marginBottom: 4,
            color: '#a0e220',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Personal Data
        </Typography>

        <div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6.5}>
              <InputLabel
                htmlFor="age"
                sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
              >
                Age
              </InputLabel>
              <OutlinedInput
                value={age}
                placeholder="Enter your age"
                id="age"
                name="age"
                type="number"
                required
                sx={{
                  borderRadius: 4,
                  border: 'none',
                  width: '80%',
                  marginBottom: 2,
                  backgroundColor: '#373736', // set the background color
                  '& label.Mui-focused': { color: '#008000' },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#008000',
                  },
                  '& input::placeholder': { color: '#7b7b7b' },
                  '& input': { color: '#7b7b7b' },
                }}
                onChange={(e) => setAge(e.target.value)}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '80%',
                }}
              >
                <InputLabel
                  htmlFor="weight"
                  sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
                >
                  Weight
                </InputLabel>
                <div>
                  <button
                    style={{
                      backgroundColor:
                        unitType === 'metric' ? '#a0e220' : 'white',
                      padding: '0.2rem 1rem ',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleUnitType('metric')}
                  >
                    Metric
                  </button>
                  <button
                    style={{
                      backgroundColor:
                        unitType === 'imperial' ? '#a0e220' : 'white',
                      padding: '0.2rem 1rem ',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleUnitType('imperial')}
                  >
                    Imperial
                  </button>
                </div>
              </div>

              <OutlinedInput
                endAdornment={
                  unitType === 'metric' ? (
                    <InputAdornment sx={{ color: 'red' }} position="end">
                      <span style={{ color: 'white' }}>Kilogram</span>
                    </InputAdornment>
                  ) : (
                    <InputAdornment sx={{ color: 'red' }} position="end">
                      <span style={{ color: 'white' }}>Pound</span>
                    </InputAdornment>
                  )
                }
                value={weight}
                placeholder="Enter your weight"
                id="weight"
                name="weight"
                type="number"
                required
                sx={{
                  borderRadius: 4,
                  border: 'none',
                  width: '80%',
                  marginBottom: 2,
                  backgroundColor: '#373736', // set the background color
                  '& label.Mui-focused': { color: '#008000' },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#008600',
                  },
                  '& input::placeholder': { color: '#7b7b7b' },
                  '& input': { color: '#7b7b7b' },
                }}
                onChange={(e) => setWeight(e.target.value)}
              />
              <InputLabel
                htmlFor="height"
                sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
              >
                Height
              </InputLabel>
              <OutlinedInput
                endAdornment={
                  unitType === 'metric' ? (
                    <InputAdornment sx={{ color: 'red' }} position="end">
                      <span style={{ color: 'white' }}>Centimeter</span>
                    </InputAdornment>
                  ) : (
                    <InputAdornment sx={{ color: 'red' }} position="end">
                      <span style={{ color: 'white' }}>Inches</span>
                    </InputAdornment>
                  )
                }
                value={height}
                placeholder="Enter your height"
                id="height"
                type="number"
                name="height"
                required
                sx={{
                  borderRadius: 4,
                  border: 'none',
                  width: '80%',
                  marginBottom: 2,
                  backgroundColor: '#373736', // set the background color
                  '& label.Mui-focused': { color: '#008000' },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#008600',
                  },
                  '& input::placeholder': { color: '#7b7b7b' },
                  '& input': { color: '#7b7b7b' },
                }}
                onChange={(e) => setHeight(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={5.5}>
              <InputLabel
                htmlFor="active-level"
                sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
              >
                Active Level
              </InputLabel>
              <RadioGroup
                value={activityLevel}
                aria-label="active-level"
                name="active-level"
                onChange={(e) => setActivityLevel(e.target.value)}
              >
                <FormControlLabel
                  value="sedentary"
                  control={
                    <Radio
                      sx={{
                        color: '#363636',
                        '&.Mui-checked': { color: '#a0e220' },
                        fontSize: '1.8rem',
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                      }}
                    >
                      Sedentary (No Exercise)
                    </Typography>
                  }
                  sx={{ lineHeight: 1.2, ml: 1, my: -0.8 }}
                />
                <FormControlLabel
                  value="light"
                  control={
                    <Radio
                      sx={{
                        color: '#363636',
                        '&.Mui-checked': { color: '#a0e220' },
                        fontSize: '1.8rem',
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                      }}
                    >
                      Light (Exercise 1-2 days per week)
                    </Typography>
                  }
                  sx={{ lineHeight: 1.2, ml: 1, my: -0.8 }}
                />
                <FormControlLabel
                  value="moderate"
                  control={
                    <Radio
                      sx={{
                        color: '#363636',
                        '&.Mui-checked': { color: '#a0e220' },
                        fontSize: '1.8rem',
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                      }}
                    >
                      Moderate (Exercise 3-5 days per week)
                    </Typography>
                  }
                  sx={{ lineHeight: 1.2, ml: 1, my: -0.8 }}
                />
                <FormControlLabel
                  value="active"
                  control={
                    <Radio
                      sx={{
                        color: '#363636',
                        '&.Mui-checked': { color: '#a0e220' },
                        fontSize: '1.8rem',
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                      }}
                    >
                      Active (Exercise 6-7 days per week)
                    </Typography>
                  }
                  sx={{ lineHeight: 1.2, ml: 1, my: -0.8 }}
                />
              </RadioGroup>

              <InputLabel
                htmlFor="goal"
                sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
              >
                What is your goal
              </InputLabel>
              <RadioGroup
                value={goal}
                aria-label="goal"
                name="goal"
                onChange={(e) => setGoal(e.target.value)}
              >
                <FormControlLabel
                  value="weight_gain"
                  control={
                    <Radio
                      sx={{
                        color: '#363636',
                        '&.Mui-checked': { color: '#a0e220' },
                        fontSize: '1.8rem',
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                      }}
                    >
                      Weight Gain
                    </Typography>
                  }
                  sx={{ lineHeight: 1.2, ml: 1, my: -0.8 }}
                />
                <FormControlLabel
                  value="lose_fat" // should be lose
                  control={
                    <Radio
                      sx={{
                        color: '#363636',
                        '&.Mui-checked': { color: '#a0e220' },
                        fontSize: '1.8rem',
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                      }}
                    >
                      Lose Fat
                    </Typography>
                  }
                  sx={{ lineHeight: 1.2, ml: 1, my: -0.8 }}
                />
              </RadioGroup>

              <InputLabel
                htmlFor="expertise-level"
                sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
              >
                Expertise level
              </InputLabel>
              <RadioGroup
                value={expertiseLevel}
                aria-label="expertise-level"
                name="expertise-level"
                onChange={(e) => setExpertiseLevel(e.target.value)}
              >
                <FormControlLabel
                  value="beginner"
                  control={
                    <Radio
                      sx={{
                        color: '#363636',
                        '&.Mui-checked': { color: '#a0e220' },
                        fontSize: '1.8rem',
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                      }}
                    >
                      Beginner
                    </Typography>
                  }
                  sx={{ lineHeight: 1.2, ml: 1, my: -0.8 }}
                />
                <FormControlLabel
                  value="intermediate"
                  control={
                    <Radio
                      sx={{
                        color: '#363636',
                        '&.Mui-checked': { color: '#a0e220' },
                        fontSize: '1.8rem',
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                      }}
                    >
                      Intermediate
                    </Typography>
                  }
                  sx={{ lineHeight: 1.2, ml: 1, my: -0.8 }}
                />
                <FormControlLabel
                  value="advanced"
                  control={
                    <Radio
                      sx={{
                        color: '#363636',
                        '&.Mui-checked': { color: '#a0e220' },
                        fontSize: '1.8rem',
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                      }}
                    >
                      Advanced
                    </Typography>
                  }
                  sx={{ mt: 0, ml: 1, my: -0.8 }}
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundImage: '#a0e220', // add background image
              color: 'white',
              '&:hover': {
                backgroundImage: '#a0e220', // change background image on hover
              },
              fontWeight: 'bold',
              padding: '0.7rem 2rem',
              width: '40%',
              mt: 5,
              mb: 5,
            }}
            // href="/meal"
            onClick={handleContinue}
          >
            <Typography sx={{ fontWeight: 'bold' }}>continue</Typography>
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default PersonalInfo;
