import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from '@emotion/styled';
import Pagination from '@mui/material/Pagination';
import { getApi } from '../../utils/axios';
import axios from 'axios';
import ShippingDetails from '../../components/shipping-card/ShippingDetails';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
//
const Container = styled('div')({
  margin: '1rem 9rem',
  '@media (max-width:600px)': {
    margin: '1rem 2rem',
  },
  '@media (max-width:480px)': {
    margin: '1rem',
  },
});
//
const styles = {
  tableCell: { color: '#9A9A9A', fontSize: '1rem' },
  bodyTableCell: { color: '#9A9A9A', fontSize: '0.9rem' },
  seeMoreBtn: {
    color: '#a0e220',
    backgroundColor: '#1D1D1D',
    border: '1px solid #a0e220',
    padding: 5,
  },
  expandedContent: {
    backgroundColor: '#363636',
    height: '100px',
  },
};
//
export default function MyOrders() {
  const navigate = useNavigate();
  const [expandedRows, setExpandedRows] = useState([]);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  //
  const handleChange = (event, value) => {
    setPage(value);
  };
  //
  const handleExpandRow = (orderId) => {
    if (expandedRows.includes(orderId)) {
      setExpandedRows(expandedRows.filter((id) => id !== orderId));
    } else {
      setExpandedRows([...expandedRows, orderId]);
    }
  };
  //
  useEffect(() => {
    const source = axios.CancelToken.source();
    getApi()
      .get(`orders/self?page=${page}&limit=13&orderBy=desc`)
      .then((res) => {
        setOrders(res.data.content || []);
        setTotalPages(res?.data?.totalPages || 0);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err?.response?.data?.message);
        setIsLoading(false);
      });
    return () => {
      source.cancel();
    };
  }, [page]);
  //
  if (isLoading) {
    return <h1>Loadiing.......</h1>;
  } else
    return (
      <div>
        <Container>
          <h2 style={{ color: '#a0e220' }}>My Orders</h2>
          {orders?.length === 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '100px',
              }}
            >
              <div>
                <Typography variant="h5" sx={{ color: 'white' }}>
                  No orders available
                </Typography>
              </div>
              <div>
                <Button
                  sx={{
                    backgroundColor: '#a0e220',
                    color: 'white',
                    margin: '20px',
                    padding: '5px 15px',
                  }}
                  onClick={() => navigate('/shop')}
                >
                  Shop now
                </Button>
              </div>
            </div>
          ) : (
            <>
              {' '}
              <TableContainer
                component={Paper}
                sx={{ border: '15px solid #1D1D1D' }}
              >
                <Table
                  sx={{
                    minWidth: 650,
                    backgroundColor: '#1D1D1D',
                    '& td, & th': { borderColor: '#9A9A9A' },
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={styles.tableCell}>ORDER ID</TableCell>
                      <TableCell sx={styles.tableCell}>DATE</TableCell>
                      <TableCell sx={styles.tableCell}>STATUS</TableCell>
                      <TableCell sx={styles.tableCell}>TOTAL</TableCell>
                      <TableCell sx={styles.tableCell}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((row) => (
                      <React.Fragment key={row?._id}>
                        <TableRow
                          key={row.orderId}
                          sx={{
                            '& td, & th': { borderColor: '#9A9A9A' },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={styles.bodyTableCell}
                          >
                            {row._id}
                          </TableCell>
                          <TableCell sx={styles.bodyTableCell}>
                            {new Date(row.createdAt).toLocaleString('en-US', {
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric',
                            })}
                          </TableCell>
                          <TableCell sx={styles.bodyTableCell}>
                            {row.status}
                          </TableCell>
                          <TableCell sx={styles.bodyTableCell}>
                            Rs. {row.total.toLocaleString('en-IN')}
                          </TableCell>
                          <TableCell sx={styles.bodyTableCell}>
                            <button
                              style={styles.seeMoreBtn}
                              onClick={() => handleExpandRow(row._id)}
                            >
                              {expandedRows.includes(row._id)
                                ? 'see less'
                                : 'see more'}
                            </button>
                          </TableCell>
                        </TableRow>
                        {expandedRows.includes(row._id) && (
                          <TableRow style={{ backgroundColor: '#363636' }}>
                            <TableCell colSpan={5}>
                              {' '}
                              <ShippingDetails order={row} />
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChange}
              fontWeight={'bold'}
              color="primary"
              shape="rounded"
            />
          </div>
        </Container>
      </div>
    );
}
