import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
  Autocomplete,
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import useExercises from '../../hooks/useExercises'; // Adjust path as needed

const WorkoutTable = ({
  dayIndex,
  workouts,
  handleAddRow,
  handleRemoveRow,
  handleInputChange,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [hoveredExercise, setHoveredExercise] = useState(null);
  const [cardPosition, setCardPosition] = useState({ top: 0, left: 0 });

  // Fetch exercises and filtered results
  const { filteredExercises, loading } = useExercises(inputValue);

  const handleMouseEnter = (event, exercise) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setCardPosition({ top: rect.top - 100, left: rect.right + 20 });
    setHoveredExercise(exercise);
  };

  const handleMouseLeave = () => {
    setHoveredExercise(null);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h7" color={'white'}>
                EXERCISE
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h7" color={'white'}>
                SETS
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h7" color={'white'}>
                REPS
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h7" color={'white'}>
                REST
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workouts.map((workout, index) => (
            <TableRow key={index}>
              <TableCell sx={{ minWidth: '20vw', maxWidth: '20vw' }}>
                <Autocomplete
                  freeSolo
                  disableClearable
                  options={filteredExercises.map((ex) => ({
                    label: ex.name,
                    id: ex._id,
                    image: ex.url,
                    description: ex.description,
                  }))}
                  value={
                    workout?.exercise?.id?.name
                      ? workout?.exercise?.id?.name
                      : workout?.exercise?.id?._id
                      ? workout?.exercise?.id?._id
                      : workout?.exercise?.name
                  }
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  onChange={(event, newValue) => {
                    handleInputChange(
                      dayIndex,
                      index,
                      'exercise',
                      newValue
                        ? { name: newValue.label, id: newValue.id }
                        : { name: '', id: '' }
                    );
                    setHoveredExercise(null);
                    setInputValue('');
                  }}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      onMouseEnter={(e) => handleMouseEnter(e, option)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <img
                        src={option.image}
                        alt={option.label}
                        style={{ width: 50, height: 50, marginRight: 10 }}
                      />
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Type or Search an Exercise"
                      value={workout.exercise.name}
                      InputProps={{
                        ...params.InputProps,
                        style: { color: 'white' },
                        endAdornment: loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          params.InputProps.endAdornment
                        ),
                      }}
                      sx={{
                        input: { color: 'white' },
                        border: '1px solid gray',
                        borderRadius: '5px',
                      }}
                    />
                  )}
                />
              </TableCell>
              <TableCell>
                <Select
                  value={workout.sets}
                  onChange={(e) =>
                    handleInputChange(dayIndex, index, 'sets', e.target.value)
                  }
                  fullWidth
                  sx={{
                    border: '1px solid gray',
                    color: 'white',
                    '& .MuiSelect-select': { color: 'white' },
                    '& .MuiSvgIcon-root': { color: 'white' },
                  }}
                >
                  {[1, 2, 3, 4, 5].map((set) => (
                    <MenuItem key={set} value={set}>
                      {set}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                <Select
                  value={workout.reps}
                  onChange={(e) =>
                    handleInputChange(dayIndex, index, 'reps', e.target.value)
                  }
                  fullWidth
                  sx={{
                    border: '1px solid gray',
                    color: 'white',
                    '& .MuiSelect-select': { color: 'white' },
                    '& .MuiSvgIcon-root': { color: 'white' },
                  }}
                >
                  {[1, 4, 8, 10, , 12, 15, 20, 30].map((rep) => (
                    <MenuItem key={rep} value={rep}>
                      {rep}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                <Select
                  value={workout.rest}
                  onChange={(e) =>
                    handleInputChange(dayIndex, index, 'rest', e.target.value)
                  }
                  fullWidth
                  sx={{
                    border: '1px solid gray',
                    color: 'white',
                    '& .MuiSelect-select': { color: 'white' },
                    '& .MuiSvgIcon-root': { color: 'white' },
                  }}
                >
                  {[30, 60, 90, 120].map((rest) => (
                    <MenuItem key={rest} value={rest}>
                      {rest} sec
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                <IconButton onClick={() => handleAddRow(dayIndex)}>
                  <AddCircleOutlineIcon color="primary" />
                </IconButton>
                <IconButton onClick={() => handleRemoveRow(dayIndex, index)}>
                  <RemoveCircleOutlineIcon color="secondary" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {hoveredExercise && (
        <Card
          sx={{
            position: 'fixed',
            top: cardPosition.top,
            left: cardPosition.left,
            maxWidth: 300,
            zIndex: 1000,
          }}
        >
          <CardMedia
            component="img"
            height="270"
            width="200"
            image={hoveredExercise.image}
            alt={hoveredExercise.label}
          />
          <CardContent>
            <Typography variant="h7">{hoveredExercise.label}</Typography>
            <br />
            <Typography variant="caption">
              {hoveredExercise.description}
            </Typography>
          </CardContent>
        </Card>
      )}
    </TableContainer>
  );
};

export default WorkoutTable;
