import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  OutlinedInput,
  Button,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Box,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/authSlice';
import { useNavigate } from 'react-router-dom';
import { getApi } from '../../utils/axios';
import { popAlert } from '../../utils/alert';
import loginImg from '../../assets/login-img.png';
import { resetWorkout } from '../../store/workoutSlice';
import { finessRecordsActions } from '../../store/finessRecordSlice';
import { userRegisterActions } from '../../store/userRegisterSlice';
import ArrowBack from '@mui/icons-material/ArrowBack';

const RegisterPage = () => {
  const dispatch = useDispatch();
  const registerUserState = useSelector((state) => state.register);
  const [isClicked, setIslicked] = useState(true);
  const [firstName, setFirstName] = useState(
    registerUserState?.user?.firstName
  );
  const [lastName, setLastName] = useState(registerUserState?.user?.lastName);
  const [email, setEmail] = useState(registerUserState?.user?.email);
  const [password, setPassword] = useState(registerUserState?.user?.password);
  const [confirmPassword, setConfirmPassword] = useState(
    registerUserState?.user?.confirmPassword
  );
  const [mobileNumber, setMobileNumber] = useState(
    registerUserState?.user?.mobileNumber
  );
  const [address, setAddress] = useState(registerUserState?.user?.address);
  const [gender, setGender] = useState(registerUserState?.user?.gender);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  //
  const handleContinue = (e) => {
    e.preventDefault();
    if (!email || !firstName || !lastName || !password) {
      popAlert('Error!', 'please fill all the fields', 'error', 'Ok');
      return;
    }
    if (password !== confirmPassword) {
      popAlert('Error!', 'password not matched', 'error', 'Ok');
      return;
    } else {
      dispatch(
        userRegisterActions.setUser({
          firstName,
          lastName,
          email,
          password,
          confirmPassword,
          gender,
          mobileNumber,
          address,
          currentPage: 2,
        })
      );
      setIslicked(false);
    }
  };
  const handleGoBack = () => {
    dispatch(
      userRegisterActions.setUser({
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        gender,
        mobileNumber,
        address,
        currentPage: 1,
      })
    );
    setIslicked(true);
  };
  //
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  //
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    getApi()
      .post('/trainer/register', {
        firstName,
        lastName,
        email,
        password,
        mobileNumber,
        address,
      })
      .then((res) => {
        setLoading(false);
        popAlert(
          'Success!',
          res?.data?.message || 'Registerd Successfuly',
          'success',
          'Ok'
        ).then(() => {
          dispatch(authActions.login(res?.data?.data));
          dispatch(finessRecordsActions.resetMeals());
          dispatch(resetWorkout());
          dispatch(userRegisterActions.resetUser());
          navigate('/home');
        });
      })
      .catch((err) => {
        setLoading(false);
        popAlert('Error!', err?.response?.data?.message, 'error', 'ok');
      });
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={5} md={7}>
        <Paper
          sx={{
            height: '100%',
            backgroundImage: `url(${loginImg})`,
            backgroundSize: 'cover',
          }}
        ></Paper>
      </Grid>
      <Grid item xs={12} sm={7} md={5}>
        <Paper
          sx={{
            p: 3,
            bgcolor: '#1d1d1d',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            {registerUserState?.user?.currentPage !== 1 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => handleGoBack()}
              >
                <ArrowBack sx={{ color: '#a0e220', cursor: 'pointer' }} />
              </Box>
            )}

            <Typography
              variant="h5"
              sx={{
                color: '#a0e220',
                fontWeight: 'bold',
              }}
            >
              REGISTER FORM
            </Typography>
          </Box>

          <form
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {isClicked && registerUserState?.user?.currentPage !== 2 ? (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <InputLabel
                      htmlFor="email"
                      sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
                    >
                      First Name
                    </InputLabel>
                    <OutlinedInput
                      placeholder="Enter your first name"
                      id="fname"
                      name="fname"
                      value={firstName}
                      sx={{
                        borderRadius: 4,
                        border: 'none',
                        width: '100%',
                        marginBottom: 2,
                        backgroundColor: '#373736', // set the background color
                        '& label.Mui-focused': { color: '#008000' },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#008000',
                        },
                        '& input::placeholder': { color: '#7b7b7b' },
                        '& input': { color: '#7b7b7b' },
                      }}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel
                      htmlFor="email"
                      sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
                    >
                      Last Name
                    </InputLabel>
                    <OutlinedInput
                      placeholder="Enter your last name"
                      id="lname"
                      name="lname"
                      value={lastName}
                      sx={{
                        borderRadius: 4,
                        border: 'none',
                        width: '100%',
                        marginBottom: 2,
                        backgroundColor: '#373736', // set the background color
                        '& label.Mui-focused': { color: '#008000' },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: '#008000',
                        },
                        '& input::placeholder': { color: '#7b7b7b' },
                        '& input': { color: '#7b7b7b' },
                      }}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <InputLabel
                  htmlFor="email"
                  sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
                >
                  Email
                </InputLabel>
                <OutlinedInput
                  placeholder="Enter your email"
                  id="email"
                  name="email"
                  value={email}
                  sx={{
                    borderRadius: 4,
                    border: 'none',
                    width: '100%',
                    marginBottom: 2,
                    backgroundColor: '#373736', // set the background color
                    '& label.Mui-focused': { color: '#008000' },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008000',
                    },
                    '& input::placeholder': { color: '#7b7b7b' },
                    '& input': { color: '#7b7b7b' },
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <InputLabel
                  htmlFor="password"
                  sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
                >
                  Password
                </InputLabel>
                <OutlinedInput
                  placeholder="Enter your password"
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  sx={{
                    borderRadius: 4,
                    border: 'none',
                    width: '100%',
                    marginBottom: 2,
                    backgroundColor: '#373736', // set the background color
                    '& label.Mui-focused': { color: '#008000' },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008600',
                    },
                    '& input::placeholder': { color: '#7b7b7b' },
                    '& input': { color: '#7b7b7b' },
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputLabel
                  htmlFor="password"
                  sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
                >
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  placeholder="Retype your password"
                  type="password"
                  id="password2"
                  name="password2"
                  value={confirmPassword}
                  sx={{
                    borderRadius: 4,
                    border: 'none',
                    width: '100%',
                    marginBottom: 2,
                    backgroundColor: '#373736', // set the background color
                    '& label.Mui-focused': { color: '#008000' },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008600',
                    },
                    '& input::placeholder': { color: '#7b7b7b' },
                    '& input': { color: '#7b7b7b' },
                  }}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <Button
                  variant="contained"
                  sx={{
                    padding: '12px 24px',
                    backgroundImage: '#a0e220',
                    color: 'black',
                    '&:hover': {
                      backgroundImage: '#a0e220',
                    },
                    borderRadius: 4,
                    fontWeight: 'bold',
                    width: '100%',
                    mt: 2,
                  }}
                  onClick={handleContinue}
                >
                  Continue
                </Button>
                <Grid
                  container
                  spacing={1}
                  style={{ justifyContent: 'center' }}
                >
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        marginTop: 1,
                        color: '#8e8e8f',
                      }}
                    >
                      Already a member?
                    </Typography>
                  </Grid>
                  <Grid item>
                    <a href="/login" style={{ textDecoration: 'none' }}>
                      <Typography
                        noWrap
                        variant="subtitle1"
                        sx={{
                          marginTop: 1,
                          color: '#a0e220',
                        }}
                      >
                        Login
                      </Typography>
                    </a>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <InputLabel
                  htmlFor="text"
                  sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
                >
                  Gender
                </InputLabel>
                <RadioGroup
                  aria-label="options"
                  name="options"
                  value={gender}
                  onChange={handleGenderChange}
                  style={{ marginLeft: '20px', color: '#7b7b7b' }}
                >
                  <div>
                    <FormControlLabel
                      value="male"
                      control={
                        <Radio
                          sx={{
                            color: '#363636',
                            '&.Mui-checked': { color: '#a0e220' },
                          }}
                        />
                      }
                      label="Male"
                    />
                    <FormControlLabel
                      value="female"
                      control={
                        <Radio
                          sx={{
                            color: '#363636',
                            '&.Mui-checked': { color: '#a0e220' },
                          }}
                        />
                      }
                      label="Female"
                    />
                  </div>
                </RadioGroup>
                <InputLabel
                  htmlFor="password"
                  sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
                >
                  Mobile Number
                </InputLabel>
                <OutlinedInput
                  placeholder="Enter your mobile number"
                  type="phone"
                  id="phone"
                  name="phone"
                  sx={{
                    borderRadius: 4,
                    border: 'none',
                    width: '100%',
                    marginBottom: 2,
                    backgroundColor: '#373736',
                    '& label.Mui-focused': { color: '#008000' },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008600',
                    },
                    '& input::placeholder': { color: '#7b7b7b' },
                    '& input': { color: '#7b7b7b' },
                  }}
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
                <InputLabel
                  htmlFor="password"
                  sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
                >
                  Address
                </InputLabel>
                <OutlinedInput
                  placeholder="Enter your address"
                  type="address"
                  id="address"
                  name="address"
                  sx={{
                    borderRadius: 4,
                    border: 'none',
                    width: '100%',
                    marginBottom: 2,
                    backgroundColor: '#373736',
                    '& label.Mui-focused': { color: '#008000' },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#008600',
                    },
                    '& input::placeholder': { color: '#7b7b7b' },
                    '& input': { color: '#7b7b7b' },
                  }}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <Box sx={{ display: 'flex' }}>
                  <Checkbox
                    defaultChecked
                    style={{
                      color: '#a0e220',
                    }}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{
                      marginTop: 1,
                      color: '#8e8e8f',
                    }}
                  >
                    I here by accept{' '}
                    <span
                      style={{ color: '#a0e220', cursor: 'pointer' }}
                      onClick={() => window.open('/term-conditions', '_blank')}
                    >
                      Terms & Conditions
                    </span>{' '}
                    of Fitness Guru
                  </Typography>
                </Box>

                <Button
                  variant="contained"
                  sx={{
                    padding: '12px 24px',
                    backgroundImage: '#a0e220',
                    color: 'black',
                    '&:hover': {
                      backgroundImage: '#a0e220',
                    },
                    borderRadius: 4,
                    fontWeight: 'bold',
                    width: '100%',
                    mt: 2,
                  }}
                  onClick={handleSubmit}
                >
                  Register
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: 'black',
                        position: 'absolute',
                        left: '60%',
                        top: '50%',
                        marginLeft: '-12px', // Half of the size
                        marginTop: '-12px', // Half of the size
                      }}
                    />
                  )}
                </Button>
                <Grid
                  container
                  spacing={1}
                  style={{ justifyContent: 'center' }}
                >
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        marginTop: 1,
                        color: '#8e8e8f',
                      }}
                    >
                      Already a member?
                    </Typography>
                  </Grid>
                  <Grid item>
                    <a href="/login" style={{ textDecoration: 'none' }}>
                      <Typography
                        noWrap
                        variant="subtitle1"
                        sx={{
                          marginTop: 1,
                          color: '#a0e220',
                        }}
                      >
                        Login
                      </Typography>
                    </a>
                  </Grid>
                </Grid>
              </>
            )}

            {/* 2nd form  */}
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default RegisterPage;
