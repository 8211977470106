import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    tokens: {
      accessToken: null,
      refreshToken: null,
    },
    user: {},
    subscription: {},
    isFirstExternalClient: false,
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    login(state, action) {
      const { accessToken, refreshToken, trainer, subscription } =
        action.payload;
      state.isLoggedIn = true;
      state.user = trainer;
      state.subscription = subscription;
      state.tokens = {
        accessToken,
        refreshToken,
      };
    },
    setAuthTokens(state, action) {
      state.tokens.accessToken = action.payload.accessToken;
      state.tokens.refreshToken = action.payload.refreshToken;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.tokens = {
        accessToken: null,
        refreshToken: null,
      };
      state.user = {};
      state.subscription = null;
    },
    setSubscription(state, action) {
      state.subscription = action.payload;
    },
    setIsFirstExtenalClients(state, action) {
      state.isFirstExternalClient = action.payload;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
