import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '../../../assets/deleteIcon.png';
import UpdateIcon from '../../../assets/updateIcon.png';
import { Typography } from '@mui/material';
import AddProductForm from '../component/AddProductForm';
import axios from 'axios';
import { getApi } from '../../../utils/axios';
import { popAlert, deletePopAlert } from '../../../utils/alert';
import Pagination from '@mui/material/Pagination';
import UpdateProductForm from '../component/UpdateProductForm';
import { getDownloadURLFromFirebaseRef } from '../../../utils/firebase';
import Loader from '../../../components/Loader/Loader';

const styles = {
  tableCell: { color: '#9A9A9A', fontSize: '1rem' },
  bodyTableCell: { color: '#9A9A9A', fontSize: '0.9rem' },
  expandedContent: {
    backgroundColor: '#363636',
    height: '100px',
  },
  image: {
    height: '55px',
    width: '55px',
    borderRadius: '10px',
    objectFit: 'cover',
  },
  actions: {
    height: '25px',
    width: '25px',
    marginLeft: '20px',
  },
  seeMoreBtn: {
    color: '#a0e220',
    backgroundColor: '#1D1D1D',
    border: '1px solid #a0e220',
    padding: '10px 15px ',
    cursor: 'pointer',
  },
  publishBtn: {
    color: 'black',
    backgroundColor: '#A5A5A5',
    border: '1px solid black',
    padding: '10px 15px ',
    cursor: 'pointer',
  },
};

//

const ProductManage = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [updatedProduct, setUpdatedProduct] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchProducts = async () => {
      try {
        getApi()
          .get(`/products?type=both&page=${page}&limit=13&orderBy=desc`)
          .then(async (res) => {
            const tempProducts = res?.data?.content || [];

            for (const product of tempProducts) {
              if (product?.image?.firebaseStorageRef) {
                const url = await getDownloadURLFromFirebaseRef(
                  product.image.firebaseStorageRef
                );
                product.image = url;
              } else {
                // TODO: set default image
                product.image = undefined;
              }
            }

            setProducts(tempProducts);
            setTotalPages(res?.data?.totalPages || 0);
            setIsLoading(false);
          });
      } catch (err) {
        if (axios.isCancel(err)) {
          console.error('Request canceled:', err.message);
          setIsLoading(false);
        } else {
          const errorMessage =
            err.response?.data?.message || 'An error occurred';
          popAlert('Error!', errorMessage, 'error', 'OK');
          setIsLoading(false);
        }
      }
    };
    fetchProducts();
    return () => {
      source.cancel();
    };
  }, [page]);
  //
  const handleDeleteProduct = async (id) => {
    try {
      const confirmed = await deletePopAlert(
        'Are you sure?',
        "You won't be able to revert this!",
        'warning',
        'Yes, delete it!',
        'Product has been deleted.'
      );
      if (confirmed) {
        getApi().delete(`/products/${id}`);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  //
  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '80vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader />
      </div>
    );
  } else
    return (
      <div>
        {/* All products table  */}
        {!isAddModalOpen && !isUpdateModalOpen && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  marginBottom: 1,
                  color: '#a0e220',
                  fontWeight: 'bold',
                }}
              >
                Manage Products
              </Typography>
              <button
                style={styles.seeMoreBtn}
                onClick={() => setIsAddModalOpen(true)}
              >
                Add New
              </button>
            </div>

            <TableContainer
              component={Paper}
              sx={{ border: '15px solid #1D1D1D' }}
            >
              <Table
                sx={{
                  minWidth: 650,
                  backgroundColor: '#1D1D1D',
                  '& td, & th': { borderColor: '#9A9A9A' },
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.tableCell}>IMAGE</TableCell>
                    <TableCell sx={styles.tableCell}>PRODUCT TYPE</TableCell>
                    <TableCell sx={styles.tableCell}>NAME</TableCell>
                    <TableCell sx={styles.tableCell}>PRICE</TableCell>
                    <TableCell sx={styles.tableCell}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((product, index) => (
                    <React.Fragment key={index}>
                      <TableRow
                        sx={{
                          '& td, & th': { borderColor: '#9A9A9A' },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={styles.bodyTableCell}
                        >
                          {/* TODO: product image */}
                          <img
                            src={product.image}
                            alt="item"
                            style={styles.image}
                          />
                        </TableCell>
                        <TableCell sx={styles.bodyTableCell}>
                          {product.type}{' '}
                        </TableCell>
                        <TableCell sx={styles.bodyTableCell}>
                          {product.name}
                        </TableCell>
                        <TableCell sx={styles.bodyTableCell}>
                          {product.price}
                        </TableCell>
                        <TableCell sx={styles.bodyTableCell}>
                          <img
                            src={DeleteIcon}
                            style={styles.actions}
                            alt="delete"
                            onClick={() => {
                              handleDeleteProduct(product?._id);
                            }}
                          />
                          <img
                            src={UpdateIcon}
                            style={styles.actions}
                            alt="update"
                            onClick={() => {
                              setIsUpdateModalOpen(true);
                              setUpdatedProduct(product);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <Pagination
                count={totalPages}
                page={page}
                onChange={handleChange}
                fontWeight={'bold'}
                color="primary"
                shape="rounded"
              />
            </div>
          </>
        )}
        {/* Add a new product table  */}
        {isAddModalOpen && (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  marginBottom: 1,
                  color: '#a0e220',
                  fontWeight: 'bold',
                }}
              >
                Manage Products
              </Typography>
              <button
                style={styles.seeMoreBtn}
                onClick={() => {
                  setIsAddModalOpen(false);
                  setIsUpdateModalOpen(false);
                }}
              >
                See All
              </button>
            </div>

            <AddProductForm />
          </div>
        )}
        {/* Update a product table  */}
        {isUpdateModalOpen && (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  marginBottom: 1,
                  color: '#a0e220',
                  fontWeight: 'bold',
                }}
              >
                Manage Products
              </Typography>
              <button
                style={styles.seeMoreBtn}
                onClick={() => setIsUpdateModalOpen(false)}
              >
                See All
              </button>
            </div>

            <UpdateProductForm product={updatedProduct} />
          </div>
        )}
      </div>
    );
};

export default ProductManage;
