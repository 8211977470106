import { configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import finessRecordSlice from './finessRecordSlice';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import cartSlice from './cartSlice';
import workoutSlice from './workoutSlice';
import clientSlice from './clientSlice';
import userRegisterSlice from './userRegisterSlice';

const reducers = combineReducers({
  auth: authSlice.reducer,
  fitnessRecords: finessRecordSlice.reducer,
  cart: cartSlice.reducer,
  workout: workoutSlice.reducer,
  client: clientSlice.reducer,
  register: userRegisterSlice.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export default store;
