// PricingCard.jsx
import { Box, CardContent, CardMedia, Typography, Button } from '@mui/material';
import React from 'react';

const PricingCard = ({ plan, onSelectPricingPlan }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <CardMedia
        component="img"
        height="500"
        image={plan.cardBackground}
        alt="Card background"
      />
      <CardContent
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          color: plan.color,
          padding: 2,
        }}
      >
        <Typography
          variant="h5"
          fontWeight={'bold'}
          component="div"
          sx={{ mb: 1, mt: 5, ml: 2 }}
        >
          {plan.name}
        </Typography>
        <Typography
          variant="h3"
          component="div"
          sx={{
            fontWeight: 'bold',
            mb: 18,
            mt: 9,
            textAlign: 'center',
            color: 'white',
          }}
        >
          {plan.price}
        </Typography>
        <Typography
          variant="h6"
          component="div"
          sx={{ mb: 4, mt: 0, textAlign: 'center' }}
        >
          ✓ {plan.benefits[0]}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: plan.color,
              color: '#fff',
              borderRadius: '18px',
              textTransform: 'none',
              px: 5,
            }}
            onClick={() => onSelectPricingPlan(plan?._id)}
          >
            <Typography variant="h6" sx={{ textAlign: 'center' }}>
              Start Now
            </Typography>
          </Button>
        </Box>
      </CardContent>
    </Box>
  );
};

export default PricingCard;
