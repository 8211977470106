import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Button, Box, IconButton } from '@mui/material';
import { Container } from '../../../components/commonStyle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  addDay,
  addRow,
  removeRow,
  removeDay,
  updateInput,
  setWorkoutData,
  resetWorkout,
} from '../../../store/workoutSlice';
import { getApi } from '../../../utils/axios';
import { popAlert } from '../../../utils/alert';
import { useNavigate, useParams } from 'react-router-dom';
import WorkoutTable from '../../../components/workout-tables/WorkoutTable ';
import Loader from '../../../components/Loader/Loader';

const CustomWorkoutForm = () => {
  const dispatch = useDispatch();
  const { clientID, reqID } = useParams();
  const navigate = useNavigate();
  const days = useSelector((state) => state?.workout?.days);
  const [workoutId, setWorkoutId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWorkout = async () => {
      try {
        if (reqID) {
          const response = await getApi().get(
            `/workout/by-trainer/${clientID}`
          );
          if (response.data.data) {
            setWorkoutId(response.data.data._id);
            dispatch(setWorkoutData(response.data.data));
          } else {
            dispatch(resetWorkout());
          }
        } else if (clientID !== 'new') {
          const response = await getApi().get(
            `/workout/by-trainer-for-external-clients/${clientID}`
          );
          if (response.data.data) {
            setWorkoutId(response.data.data._id);
            dispatch(setWorkoutData(response.data.data));
          } else {
            dispatch(resetWorkout());
          }
        }
      } catch (err) {
        // console.error('Failed to fetch workout', err);
        // popAlert('Error!', 'Failed to fetch workout data', 'error', 'ok');
      } finally {
        setLoading(false);
      }
    };

    fetchWorkout();
  }, [clientID]);

  const handleAddDay = () => {
    dispatch(addDay());
  };

  const handleAddRow = (dayIndex) => {
    dispatch(addRow({ dayIndex }));
  };

  const handleRemoveRow = (dayIndex, rowIndex) => {
    dispatch(removeRow({ dayIndex, rowIndex }));
  };

  const handleRemoveDay = (dayIndex) => {
    dispatch(removeDay({ dayIndex }));
  };

  const handleInputChange = (dayIndex, rowIndex, field, value) => {
    dispatch(updateInput({ dayIndex, rowIndex, field, value }));
  };

  const handleContinue = async () => {
    try {
      const exerciseDays = days.map((day) => ({
        day: parseInt(day.day.replace('Day ', '')),
        exercises: day.exercises.map((exercise) => ({
          order: exercise.order,
          exercise: exercise.exercise.id._id
            ? exercise.exercise.id._id
            : exercise.exercise.id,
          sets: exercise.sets,
          reps: exercise.reps,
          rest: exercise.rest,
        })),
      }));

      const data = {
        clientId: clientID,
        exerciseDays,
      };

      if (workoutId) {
        if (reqID) {
          await getApi()
            .put(`/workout/by-trainer/${workoutId}`, data)
            .then(() => {
              if (reqID) {
                navigate(`/meal/${reqID}/${clientID}`);
              } else {
                navigate(`/meal/${clientID}`);
              }
            });
          dispatch(resetWorkout());
        } else {
          const workoutData = { exerciseDays: data.exerciseDays };
          await getApi()
            .put(
              `/workout/by-trainer-for-external-clients/${clientID}`,
              workoutData
            )
            .then(() => {
              navigate(`/meal/${clientID}`);
            });
          dispatch(resetWorkout());
        }
        popAlert('Success!', 'Workout plan saved', 'success', 'Ok').then(() => {
          if (reqID) {
            navigate(`/meal/${reqID}/${clientID}`);
          } else {
            navigate(`/meal/${clientID}`);
          }
        });
      } else {
        if (reqID) {
          await getApi().post('/workout/by-trainer', data);
          dispatch(resetWorkout());
          popAlert('Success!', 'Workout plan saved', 'success', 'Ok').then(
            () => {
              if (reqID) {
                navigate(`/meal/${reqID}/${clientID}`);
              } else {
                navigate(`/meal/${clientID}`);
              }
            }
          );
        } else if (clientID !== 'new') {
          await getApi().post('/workout/by-trainer-for-external-clients', data);
          dispatch(resetWorkout());
          popAlert('Success!', 'Workout plan saved', 'success', 'Ok').then(
            () => {
              if (reqID) {
                navigate(`/meal/${reqID}/${clientID}`);
              } else {
                navigate(`/meal/${clientID}`);
              }
            }
          );
        } else {
          navigate(`/meal/new`);
        }
      }
    } catch (err) {
      popAlert(
        'Error!',
        err?.response?.data?.message || 'An error occurred',
        'error',
        'ok'
      );
    }
  };

  if (loading) {
    return (
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Loader />
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ color: '#a0e220', fontWeight: 'bold' }}
      >
        Create or Update Workout
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '2rem' }}>
        <Button
          variant="outlined"
          startIcon={<AddCircleOutlineIcon color="secondary" />}
          onClick={handleAddDay}
        >
          <Typography variant="h7" color={'white'}>
            Add new day
          </Typography>
        </Button>
      </Box>
      {days.map((day, dayIndex) => (
        <div key={day.day} style={{ marginBottom: '2rem' }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              color: '#a0e220',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {day.day}
            <IconButton
              onClick={() => handleRemoveDay(dayIndex)}
              disabled={days.length === 1}
              sx={{ ml: 2 }}
            >
              <RemoveCircleOutlineIcon color="secondary" />
            </IconButton>
          </Typography>
          <WorkoutTable
            dayIndex={dayIndex}
            workouts={day.exercises}
            handleAddRow={handleAddRow}
            handleRemoveRow={handleRemoveRow}
            handleInputChange={handleInputChange}
          />
        </div>
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: '2rem' }}>
        <Button
          variant="contained"
          sx={{
            backgroundImage: '#a0e220',
            color: 'black',
            '&:hover': {
              backgroundImage: '#a0e220',
            },
            fontWeight: 'bold',
            padding: '0.7rem 3rem',
            borderRadius: 4,
            mt: 3,
          }}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </Box>
    </Container>
  );
};

export default CustomWorkoutForm;
