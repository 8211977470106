import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Button,
  Grid,
  OutlinedInput,
  Pagination,
  Typography,
} from '@mui/material';
import ItemCard from '../../components/item-card/ItemCard';
import { getApi } from '../../utils/axios';
import Search from '../../components/search/Search';
import Loader from '../../components/Loader/Loader';
const Container = styled('div')({
  margin: '3rem 9rem',
  '@media (max-width:600px)': {
    margin: '1rem 2rem',
  },
  '@media (max-width:480px)': {
    margin: '1rem',
  },
  '@media (max-width:1391px)': {
    margin: '1rem',
  },
});

const Shop = () => {
  const [products, setProducts] = useState();
  const [navigater, setNavigater] = useState('supplement');
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleEmptySearchInput = () => {
    setKeyword('');
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    setIsLoading(true);
    getApi()
      .get(
        `products?keyword=${keyword}&type=${navigater}&page=${page}&limit=10&orderBy=desc`
      )
      .then((res) => {
        setTotalPages(res?.data?.totalPages || 0);
        setProducts(res.data.content);
        setIsLoading(false);
      })
      .catch((err) => console.error(err?.response?.data?.message));
  }, [navigater, keyword, page]);

  return (
    <Container sx={{ mb: 10 }}>
      <Container>
        <Search
          placeholderText={'Search Items..'}
          onSearch={handleSearch}
          onEmptySearchInput={handleEmptySearchInput}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h5"
            sx={{ p: 2, cursor: 'pointer' }}
            onClick={() => {
              setNavigater('supplement');
            }}
            color={navigater === 'supplement' ? '#a0e220' : 'white'}
          >
            Supplements
          </Typography>
          <Typography
            variant="h5"
            sx={{ p: 2, cursor: 'pointer' }}
            onClick={() => setNavigater('gym-wear')}
            color={navigater === 'gym-wear' ? '#a0e220' : 'white'}
          >
            Gym Wear
          </Typography>
        </div>
      </Container>

      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        mt={3}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {products?.map((item) => {
              return (
                <Grid item xs={6} md={3} lg={3} key={item._id}>
                  <div
                    onClick={() =>
                      navigate(`/shop/${item._id}`, { state: item })
                    }
                  >
                    <ItemCard
                      key={item.id}
                      data={{
                        name: item.name,
                        price: item.price,
                        firebaseRef: item.image.firebaseStorageRef,
                      }}
                    />
                  </div>
                </Grid>
              );
            })}
          </>
        )}
      </Grid>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          fontWeight={'bold'}
          color="primary"
          shape="rounded"
        />
      </div>
    </Container>
  );
};

export default Shop;
