// ProfileImageUpload.jsx
import React from 'react';
import { TableRow, TableCell, Grid, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const ImageBox = styled(Box)({
  width: 80,
  height: 80,
  border: '1px dashed grey',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 4,
});

const ProfileImageUpload = ({ label }) => {
  return (
    <TableRow>
      <TableCell
        component="th"
        scope="row"
        sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
      >
        {label}
      </TableCell>
      <TableCell>
        <Grid container spacing={1}>
          {Array.from({ length: 4 }).map((_, index) => (
            <ImageBox key={index}>
              <Typography variant="caption">+</Typography>
            </ImageBox>
          ))}
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default ProfileImageUpload;
