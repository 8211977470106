import React, { useEffect, useRef, useState } from 'react';
import { Divider, Menu, MenuItem, Typography } from '@mui/material';
import ProfileImg from '../profile-card/ProfileImg';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { getApi } from '../../utils/axios';
import { useNavigate } from 'react-router-dom';

const NotificationMenu = styled(Menu)({
  '& .MuiList-root': {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    position: 'relative',
    outline: 0,
    backgroundColor: '#1D1D1D',
  },
});
const NotificationMenuItem = styled(MenuItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#363636',
    color: 'white', // text color when hovering
  },
}));

const NotificationPanel = (props) => {
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);

  const [menuState, setMenuState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal, open } = menuState;
  const { isOpen } = props;
  //
  const handleClose = () => {
    setMenuState({ ...menuState, open: false });
  };
  //
  useEffect(() => {
    const source = axios.CancelToken.source();
    const getNotification = async () => {
      await getApi()
        .get('notifications?page=1&limit=10&orderBy=desc') //TODO:add pagination
        .then((res) => {
          setNotifications(res?.data?.content);
        })
        .catch((err) => console.error(err?.response?.data?.message));
    };

    getNotification();

    return () => {
      source.cancel();
    };
  }, []);
  //
  useEffect(() => {
    setMenuState((prevState) => ({
      ...prevState,
      open: isOpen, // update the open state when the open prop changes
    }));
  }, [isOpen]);
  //
  return (
    <div>
      <NotificationMenu
        anchorOrigin={{ vertical, horizontal }}
        anchorEl={menuRef.current}
        open={open}
        onClose={handleClose}
        sx={{ mt: 6, paddingTop: '10px !important ' }}
      >
        {notifications?.length === 0 && (
          <NotificationMenuItem
            sx={{
              color: 'white',
              p: 1,
            }}
          >
            <Typography sx={{ width: '15rem' }}>No notifications</Typography>
          </NotificationMenuItem>
        )}
        {notifications?.map((message, index) => {
          return (
            <NotificationMenuItem
              key={index}
              sx={{
                color: 'white',
                p: 1,
              }}
            >
              <div>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                >
                  <ProfileImg
                    data={{
                      size: '50px',
                      image: message?.client?.user?.image?.firebaseStorageRef,
                      fullName: `${message?.client?.user?.firstName}`,
                    }}
                  />
                  <div>
                    <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                      {message?.client?.user?.firstName}{' '}
                      {message?.client?.user?.lastName}
                      <span
                        style={{
                          fontSize: '12px',
                          marginLeft: '10px',
                          color: '#a0e220',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          navigate(`/client/${message?.client?.user?._id}`, {
                            state: message,
                          })
                        }
                      >
                        View
                      </span>
                    </Typography>
                    <Typography sx={{ fontSize: '12px' }}>
                      {message?.client?.user?.firstName}{' '}
                      {message?.client?.user?.lastName} just added you as a
                      trainer.
                    </Typography>
                  </div>
                </div>

                <Divider
                  variant="fullWidth"
                  sx={{ backgroundColor: '#A5A5A5', mt: 1 }}
                />
              </div>
            </NotificationMenuItem>
          );
        })}
      </NotificationMenu>
    </div>
  );
};

export default NotificationPanel;
