import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import ProfileImg from '../profile-card/ProfileImg';
import { useNavigate } from 'react-router-dom';
import mealPlanIcon from '../../assets/Meal-icon.png';
import workoutPlanIcon from '../../assets/Workout-Icon.png';
import { formatDate } from '../../utils/helpers';
import SettingsIcon from '@mui/icons-material/Settings';

const ExternalClientCard = ({ client }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:660px)');

  return (
    <Card
      sx={{
        width: '100%',
        maxHeight: isMobile ? '100%' : '7rem',
        borderRadius: 5,
        border: '2px solid black',
        borderColor: '#373736',
      }}
    >
      <CardActionArea>
        <Box
          sx={{
            backgroundColor: '#363636',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          <CardContent
            sx={{
              backgroundColor: '#363636',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ProfileImg
              data={{
                size: '70px',
                image: client?.profilePhotoUrl,
                fullName: client?.name,
              }}
            />
            <Box
              sx={{
                ml: '2rem',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '0.8rem',
                }}
              >
                <Typography variant="h6" color="#ffffff" fontWeight={700}>
                  {client?.name}
                </Typography>
                {isMobile ? (
                  <SettingsIcon
                    color="secondary"
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      navigate(`/update-external-client/${client?._id}`)
                    }
                  />
                ) : (
                  <Button
                    size="small"
                    style={{
                      color: '#a0e220',
                      border: '1px solid #a0e220',
                      alignItems: 'center',
                      borderRadius: '10px',
                      padding: '1px 20px',
                      marginLeft: '10px',
                    }}
                    onClick={() =>
                      navigate(`/update-external-client/${client?._id}`)
                    }
                  >
                    <Typography
                      gutterBottom
                      variant="h7"
                      color="#a0e220"
                      sx={{ fontSize: '0.6rem', mt: 0.5 }}
                      fontWeight={700}
                    >
                      Update
                    </Typography>
                  </Button>
                )}
              </Box>
              <Typography
                variant={isMobile ? 'caption' : 'h7'}
                color="#939292"
                fontWeight={700}
                style={{ fontSize: '1rem' }}
              >
                Age : {client?.personalInfo.age}
              </Typography>
              <Typography
                variant={isMobile ? 'caption' : 'h7'}
                color="#939292"
                fontWeight={700}
                style={{ fontSize: '1rem', marginLeft: '1rem' }}
              >
                Member Since : {formatDate(client?.createdAt)}
              </Typography>
            </Box>
          </CardContent>
          <CardContent
            sx={{
              backgroundColor: '#363636',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '1rem',
            }}
          >
            <Box
              sx={{
                borderRadius: '5px',
              }}
              onClick={() => navigate(`/meal-plan/${client?._id}`)}
            >
              <CardMedia
                component="img"
                height="50"
                image={mealPlanIcon}
                alt="Card 1"
              />
            </Box>
            <Box
              sx={{
                borderRadius: '5px',
              }}
              onClick={() => navigate(`/workout-plan/${client?._id}`)}
            >
              <CardMedia
                component="img"
                height="50"
                image={workoutPlanIcon}
                alt="Card 1"
              />
            </Box>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default ExternalClientCard;
