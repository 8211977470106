import { CardMedia } from '@mui/material';
import React, { useEffect, useState } from 'react';

const ProfileImg = ({ data }) => {
  const [initials, setInitials] = useState('');
  const [image, setImage] = useState(null);

  useEffect(() => {
    let unmounted = false;

    const prepareData = async () => {
      // set initials
      const fullName = data.fullName;
      if (!fullName) return;

      let pInitials;

      const splitArr = fullName?.split(' ');
      if (splitArr.length >= 2 && !unmounted) {
        pInitials =
          splitArr[0]?.charAt(0)?.toUpperCase() +
          splitArr[1]?.charAt(0)?.toUpperCase();
      } else if (splitArr.length === 1 && !unmounted) {
        pInitials =
          splitArr[0]?.charAt(0)?.toUpperCase() +
          splitArr[0]?.charAt(1)?.toUpperCase();
      }

      if (!unmounted && pInitials) setInitials(pInitials);

      // set image
      if (data.image) {
        const url = data.image || null;
        if (!unmounted && url) setImage(url);
      }
    };

    prepareData();

    return () => {
      unmounted = true;
    };
  }, [data]);

  return (
    <div>
      <CardMedia
        sx={{
          height: data.size,
          width: data.size,
          borderRadius: '100%',
          backgroundColor: '#f3f3f3',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#666666',
          fontSize: '1.2rem',
          fontWeight: 'bold',
          objectFit: 'cover',
        }}
        image={image}
        title="profile"
        component={image ? undefined : 'div'}
      >
        {!image && initials}
      </CardMedia>
    </div>
  );
};

export default ProfileImg;
