import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const styles = {
  text: {
    color: '#D0D3D4',
  },
};

const PrivacyPolicy = () => {
  return (
    <Container>
      <Box sx={{ marginTop: '20px' }}>
        <Typography style={styles.text} variant="h4" gutterBottom>
          Privacy Policy for Fitness Guru
        </Typography>

        <Typography style={styles.text} variant="h6" gutterBottom>
          1. Introduction
        </Typography>
        <Typography style={styles.text} paragraph>
          Welcome to Fitness Guru. Your privacy is important to us. This Privacy
          Policy explains how we collect, use, and protect your personal
          information when you use our website and services. By using Fitness
          Guru, you agree to the terms of this policy.
        </Typography>

        <Typography style={styles.text} variant="h6" gutterBottom>
          2. Information We Collect
        </Typography>
        <Typography style={styles.text} component="ul" gutterBottom>
          <li>
            <strong>Personal Information:</strong> Name, email address, phone
            number, date of birth, and other identifying information.
          </li>
          <li>
            <strong>Health Information:</strong> Information about your health,
            fitness goals, and workout routines.
          </li>
          <li>
            <strong>Payment Information:</strong> Credit card information and
            billing address for processing payments.
          </li>
        </Typography>

        <Typography style={styles.text} variant="h6" gutterBottom>
          3. How We Use Your Information
        </Typography>
        <Typography style={styles.text} component="ul" gutterBottom>
          <li>Provide and personalize our services.</li>
          <li>Communicate with you about your account and our services.</li>
          <li>Process your transactions and send you related information.</li>
          <li>Improve our website and services.</li>
          <li>Comply with legal obligations.</li>
        </Typography>

        <Typography style={styles.text} variant="h6" gutterBottom>
          4. How We Share Your Information
        </Typography>
        <Typography style={styles.text} component="ul" gutterBottom>
          <li>With your consent.</li>
          <li>With service providers who perform services on our behalf.</li>
          <li>As required by law or to protect our rights.</li>
          <li>
            In connection with a merger, sale, or acquisition of our business.
          </li>
        </Typography>

        <Typography style={styles.text} variant="h6" gutterBottom>
          5. Data Security
        </Typography>
        <Typography style={styles.text} paragraph>
          We implement reasonable security measures to protect your personal
          information. However, no method of transmission over the internet or
          electronic storage is completely secure, and we cannot guarantee its
          absolute security.
        </Typography>

        <Typography style={styles.text} variant="h6" gutterBottom>
          6. Your Rights
        </Typography>
        <Typography style={styles.text} paragraph>
          Depending on your location, you may have the following rights
          regarding your personal information:
        </Typography>
        <Typography style={styles.text} component="ul" gutterBottom>
          <li>Accessing and updating your information.</li>
          <li>Deleting your information.</li>
          <li>
            Objecting to or restricting the processing of your information.
          </li>
          <li>
            Receiving a copy of your information in a structured, commonly used,
            and machine-readable format.
          </li>
        </Typography>
        <Typography style={styles.text} paragraph>
          To exercise these rights, please contact us at{' '}
          <a href="mailto:contact@fitnessgurulk.com" style={{ color: 'green' }}>
            contact@fitnessgurulk.com
          </a>
          .
        </Typography>

        <Typography style={styles.text} variant="h6" gutterBottom>
          7. Changes to This Privacy Policy
        </Typography>
        <Typography style={styles.text} paragraph>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on this page. You
          are advised to review this Privacy Policy periodically for any
          changes.
        </Typography>

        <Typography style={styles.text} variant="h6" gutterBottom>
          8. Contact Us
        </Typography>
        <Typography style={styles.text} paragraph>
          If you have any questions about this Privacy Policy, please contact us
          at:
        </Typography>
        <Typography style={styles.text} paragraph>
          Fitness Guru
          <br />
          NO: 186/F/12, Yabaralauwa North Malwana
          <br />
          <a href="mailto:contact@fitnessgurulk.com" style={{ color: 'green' }}>
            contact@fitnessgurulk.com
          </a>
          <br />
          0112465644
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
