import {
  Button,
  CircularProgress,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getApi, getApiForFormData } from '../../../utils/axios';
import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';
import { popAlert } from '../../../utils/alert';
import { Container } from '../../../components/commonStyle';
import { useSelector } from 'react-redux';

const UpdateExternalClient = () => {
  const { clientID } = useParams();
  const subscription = useSelector((state) => state.auth.subscription);
  const navigate = useNavigate();
  const fileRef = useRef(null);
  const [name, setName] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [age, setAge] = useState('');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [activityLevel, setActivityLevel] = useState('');
  const [goal, setGoal] = useState('');
  const [unit, setUnit] = useState('Imperial');
  const [gender, setGender] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchExternalClientData = async () => {
      setLoading(true);
      try {
        // Fetch existing client data
        const response = await getApi().get(`/external-client/${clientID}`);
        const externalClient = response?.data?.data || null;

        if (externalClient) {
          // Set client details in the respective states
          setName(externalClient.name);
          setActivityLevel(externalClient.activityLevel);
          setGoal(externalClient.goal);
          setGender(externalClient.gender);

          const personalInfo = externalClient.personalInfo || {};
          setAge(personalInfo.age || '');
          setWeight(personalInfo.weight || '');
          setHeight(personalInfo.height || '');
          setUnit(personalInfo.unit || 'Imperial');

          // Set profile picture if available
          //   setProfilePicture(externalClient.profilePhotoUrl);
        }
      } catch (error) {
        popAlert('Error!', 'Failed to load client data.', 'error', 'OK');
      } finally {
        setLoading(false);
      }
    };

    fetchExternalClientData();
  }, [clientID]);

  const handleUnitType = (type) => {
    if (unit === 'Metric' && type === 'Imperial') {
      const calcHeight = (height * 0.3937).toFixed(2);
      const calcWeight = (weight * 2.20462).toFixed(2);
      setHeight(calcHeight);
      setWeight(calcWeight);
    } else if (unit === 'Imperial' && type === 'Metric') {
      const calcHeight = (height / 0.3937).toFixed(2);
      const calcWeight = (weight / 2.20462).toFixed(2);
      setHeight(calcHeight);
      setWeight(calcWeight);
    }
    setUnit(type);
  };
  //
  const handlePdfFileChange = (event) => {
    const file = event.target.files[0];
    setProfilePicture(file);
  };
  //
  const handlRemoveFile = () => {
    setProfilePicture(null);
    fileRef.current.value = '';
  };
  //
  const handleContinue = async () => {
    // Check if the subscription is valid and active
    if (!subscription || subscription.status !== 'Active') {
      popAlert(
        'Subscription Required',
        'You need an active subscription to proceed. Please choose a plan.',
        'warning',
        'OK'
      ).then(() => {
        navigate('/pricing');
      });
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('name', name);
    formData.append('activityLevel', activityLevel);
    formData.append('goal', goal);
    formData.append('gender', gender);

    formData.append('personalInfo[age]', age);
    formData.append('personalInfo[unit]', unit);
    formData.append('personalInfo[weight]', weight);
    formData.append('personalInfo[height]', height);
    // formData.append('photo', profilePicture);
    try {
      const response = await getApiForFormData().put(
        `/external-client/${clientID}`,
        formData
      );
      popAlert('Success!', response?.data?.message, 'success', 'Ok').then(
        () => {
          navigate(`/custom-workout/${clientID}`);
        }
      );
    } catch (err) {
      console.log(err);
      popAlert('Error!', err.response?.data?.message, 'error', 'OK');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Typography
        variant="h4"
        sx={{
          marginBottom: 4,
          color: '#a0e220',
          fontWeight: 'bold',
        }}
      >
        Update external client
      </Typography>

      <Container>
        <div>
          <InputLabel
            htmlFor="age"
            sx={{
              color: 'white',
              mt: 2,
              mb: 1,
              fontSize: '1.4rem',
              textAlign: 'left',
            }}
          >
            Name of your client
          </InputLabel>
          <OutlinedInput
            value={name}
            placeholder="Enter your client's name"
            id="age"
            name="age"
            required
            sx={{
              borderRadius: 4,
              border: 'none',
              width: '100%',
              marginBottom: 2,
              backgroundColor: '#373736',
              '& label.Mui-focused': { color: '#008000' },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#008000',
              },
              '& input::placeholder': { color: '#7b7b7b' },
              '& input': { color: '#7b7b7b' },
            }}
            onChange={(e) => setName(e.target.value)}
          />
          <InputLabel
            htmlFor="age"
            sx={{
              color: 'white',
              mt: 2,
              mb: 1,
              fontSize: '1.4rem',
              textAlign: 'left',
            }}
          >
            Client's profile picture
          </InputLabel>
          {profilePicture && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                padding: '10px',
              }}
            >
              <DescriptionIcon sx={{ color: 'red' }} />
              <p style={{ color: 'white' }}>{profilePicture.name}</p>
              <CloseIcon
                sx={{ color: '#707070', cursor: 'pointer' }}
                onClick={handlRemoveFile}
              />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '4rem 3rem ',
              border: 'dotted',
              borderColor: '#818180',
              borderRadius: '10px',
            }}
          >
            <Button variant="outlined" component="label" color="success">
              Upload file
              <input
                ref={fileRef}
                hidden
                name="file"
                type="file"
                onChange={handlePdfFileChange}
              />
            </Button>
          </div>
          <InputLabel
            htmlFor="gender"
            sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
          >
            Gender of your client
          </InputLabel>
          <RadioGroup
            value={gender}
            aria-label="gender"
            name="gender"
            onChange={(e) => setGender(e.target.value)}
          >
            <FormControlLabel
              value="Male"
              control={
                <Radio
                  sx={{
                    color: '#939292',
                    '&.Mui-checked': { color: '#a0e220' },
                    fontSize: '1.8rem',
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                  }}
                >
                  Male
                </Typography>
              }
              sx={{
                lineHeight: 1.2,
                ml: 1,
                my: -0.8,
                borderRadius: 4,
                border: 'none',
                width: '100%',
                marginBottom: 2,
                backgroundColor: '#373736',
              }}
            />
            <FormControlLabel
              value="Female"
              control={
                <Radio
                  sx={{
                    color: '#939292',
                    '&.Mui-checked': { color: '#a0e220' },
                    fontSize: '1.8rem',
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                  }}
                >
                  Female
                </Typography>
              }
              sx={{
                lineHeight: 1.2,
                ml: 1,
                my: -0.8,
                borderRadius: 4,
                border: 'none',
                width: '100%',
                marginBottom: 2,
                backgroundColor: '#373736',
              }}
            />
          </RadioGroup>
          <InputLabel
            htmlFor="age"
            sx={{
              color: 'white',
              mt: 2,
              mb: 1,
              fontSize: '1.4rem',
              textAlign: 'left',
            }}
          >
            Age of your client
          </InputLabel>
          <OutlinedInput
            value={age}
            placeholder="Enter your client's age"
            id="age"
            name="age"
            type="number"
            required
            sx={{
              borderRadius: 4,
              border: 'none',
              width: '100%',
              marginBottom: 2,
              backgroundColor: '#373736', // set the background color
              '& label.Mui-focused': { color: '#008000' },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#008000',
              },
              '& input::placeholder': { color: '#7b7b7b' },
              '& input': { color: '#7b7b7b' },
            }}
            onChange={(e) => setAge(e.target.value)}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <InputLabel
              htmlFor="weight"
              sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
            >
              Weight of your client
            </InputLabel>
            <div>
              <button
                style={{
                  backgroundColor: unit === 'Metric' ? '#a0e220' : 'white',
                  padding: '0.2rem 1rem ',
                  border: 'none',
                  cursor: 'pointer',
                }}
                onClick={() => handleUnitType('Metric')}
              >
                Metric
              </button>
              <button
                style={{
                  backgroundColor: unit === 'Imperial' ? '#a0e220' : 'white',
                  padding: '0.2rem 1rem ',
                  border: 'none',
                  cursor: 'pointer',
                }}
                onClick={() => handleUnitType('Imperial')}
              >
                Imperial
              </button>
            </div>
          </div>

          <OutlinedInput
            endAdornment={
              unit === 'Metric' ? (
                <InputAdornment sx={{ color: 'red' }} position="end">
                  <span style={{ color: 'white' }}>Kilogram</span>
                </InputAdornment>
              ) : (
                <InputAdornment sx={{ color: 'red' }} position="end">
                  <span style={{ color: 'white' }}>Pound</span>
                </InputAdornment>
              )
            }
            value={weight}
            placeholder="Enter your client's weight"
            id="weight"
            name="weight"
            type="number"
            required
            sx={{
              borderRadius: 4,
              border: 'none',
              width: '100%',
              marginBottom: 2,
              backgroundColor: '#373736', // set the background color
              '& label.Mui-focused': { color: '#008000' },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#008600',
              },
              '& input::placeholder': { color: '#7b7b7b' },
              '& input': { color: '#7b7b7b' },
            }}
            onChange={(e) => setWeight(e.target.value)}
          />
          <InputLabel
            htmlFor="height"
            sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
          >
            Height of your client
          </InputLabel>
          <OutlinedInput
            endAdornment={
              unit === 'Metric' ? (
                <InputAdornment sx={{ color: 'red' }} position="end">
                  <span style={{ color: 'white' }}>Centimeter</span>
                </InputAdornment>
              ) : (
                <InputAdornment sx={{ color: 'red' }} position="end">
                  <span style={{ color: 'white' }}>Inches</span>
                </InputAdornment>
              )
            }
            value={height}
            placeholder="Enter your client's height"
            id="height"
            type="number"
            name="height"
            required
            sx={{
              borderRadius: 4,
              border: 'none',
              width: '100%',
              marginBottom: 2,
              backgroundColor: '#373736',
              '& label.Mui-focused': { color: '#008000' },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#008600',
              },
              '& input::placeholder': { color: '#7b7b7b' },
              '& input': { color: '#7b7b7b' },
            }}
            onChange={(e) => setHeight(e.target.value)}
          />
          <InputLabel
            htmlFor="active-level"
            sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
          >
            How do you rank your client's active Level ?
          </InputLabel>
          <RadioGroup
            value={activityLevel}
            aria-label="active-level"
            name="active-level"
            onChange={(e) => setActivityLevel(e.target.value)}
          >
            <FormControlLabel
              value="Sedentary"
              control={
                <Radio
                  sx={{
                    color: '#939292',
                    '&.Mui-checked': { color: '#a0e220' },
                    fontSize: '1.8rem',
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                  }}
                >
                  Sedentary (No Exercise)
                </Typography>
              }
              sx={{
                lineHeight: 1.2,
                ml: 1,
                my: -0.8,
                borderRadius: 4,
                border: 'none',
                width: '100%',
                marginBottom: 2,
                backgroundColor: '#373736',
              }}
            />
            <FormControlLabel
              value="Light"
              control={
                <Radio
                  sx={{
                    color: '#939292',
                    '&.Mui-checked': { color: '#a0e220' },
                    fontSize: '1.8rem',
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                  }}
                >
                  Light (Exercise 1-2 days per week)
                </Typography>
              }
              sx={{
                lineHeight: 1.2,
                ml: 1,
                my: -0.8,
                borderRadius: 4,
                border: 'none',
                width: '100%',
                marginBottom: 2,
                backgroundColor: '#373736',
              }}
            />
            <FormControlLabel
              value="Moderate"
              control={
                <Radio
                  sx={{
                    color: '#939292',
                    '&.Mui-checked': { color: '#a0e220' },
                    fontSize: '1.8rem',
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                  }}
                >
                  Moderate (Exercise 3-5 days per week)
                </Typography>
              }
              sx={{
                lineHeight: 1.2,
                ml: 1,
                my: -0.8,
                borderRadius: 4,
                border: 'none',
                width: '100%',
                marginBottom: 2,
                backgroundColor: '#373736',
              }}
            />
            <FormControlLabel
              value="Active"
              control={
                <Radio
                  sx={{
                    color: '#939292',
                    '&.Mui-checked': { color: '#a0e220' },
                    fontSize: '1.8rem',
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                  }}
                >
                  Active (Exercise 6-7 days per week)
                </Typography>
              }
              sx={{
                lineHeight: 1.2,
                ml: 1,
                my: -0.8,
                borderRadius: 4,
                border: 'none',
                width: '100%',
                marginBottom: 2,
                backgroundColor: '#373736',
              }}
            />
          </RadioGroup>

          <InputLabel
            htmlFor="goal"
            sx={{ color: 'white', mt: 2, mb: 1, fontSize: '1.4rem' }}
          >
            What is your client's goal
          </InputLabel>
          <RadioGroup
            value={goal}
            aria-label="goal"
            name="goal"
            onChange={(e) => setGoal(e.target.value)}
          >
            <FormControlLabel
              value="WeightGain"
              control={
                <Radio
                  sx={{
                    color: '#939292',
                    '&.Mui-checked': { color: '#a0e220' },
                    fontSize: '1.8rem',
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                  }}
                >
                  Weight Gain (250 - 500 Cal)
                </Typography>
              }
              sx={{
                lineHeight: 1.2,
                ml: 1,
                my: -0.8,
                borderRadius: 4,
                border: 'none',
                width: '100%',
                marginBottom: 2,
                backgroundColor: '#373736',
              }}
            />
            <FormControlLabel
              value="WeightLoss"
              control={
                <Radio
                  sx={{
                    color: '#939292',
                    '&.Mui-checked': { color: '#a0e220' },
                    fontSize: '1.8rem',
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                  }}
                >
                  Weight Loss (500 - 1000 Cal)
                </Typography>
              }
              sx={{
                lineHeight: 1.2,
                ml: 1,
                my: -0.8,
                borderRadius: 4,
                border: 'none',
                width: '100%',
                marginBottom: 2,
                backgroundColor: '#373736',
              }}
            />
            <FormControlLabel
              value="FatLoss"
              control={
                <Radio
                  sx={{
                    color: '#939292',
                    '&.Mui-checked': { color: '#a0e220' },
                    fontSize: '1.8rem',
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                  }}
                >
                  Fat Loss (250 - 500 Cal)
                </Typography>
              }
              sx={{
                lineHeight: 1.2,
                ml: 1,
                my: -0.8,
                borderRadius: 4,
                border: 'none',
                width: '100%',
                marginBottom: 2,
                backgroundColor: '#373736',
              }}
            />
            <FormControlLabel
              value="LeanGaining"
              control={
                <Radio
                  sx={{
                    color: '#939292',
                    '&.Mui-checked': { color: '#a0e220' },
                    fontSize: '1.8rem',
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontSize: { xs: '18px', sm: '15px', color: '#939292' },
                  }}
                >
                  Lean Gain (100 - 250 Cal)
                </Typography>
              }
              sx={{
                lineHeight: 1.2,
                ml: 1,
                my: -0.8,
                borderRadius: 4,
                border: 'none',
                width: '100%',
                marginBottom: 2,
                backgroundColor: '#373736',
              }}
            />
          </RadioGroup>
        </div>
      </Container>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          sx={{
            backgroundImage: '#a0e220', // add background image
            color: 'white',
            '&:hover': {
              backgroundImage: '#a0e220', // change background image on hover
            },
            fontWeight: 'bold',
            padding: '0.7rem 2rem',
            width: '40%',
            mt: 5,
            mb: 5,
          }}
          // href="/meal"
          onClick={handleContinue}
        >
          <Typography sx={{ fontWeight: 'bold', color: 'black' }}>
            Update{' '}
          </Typography>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: 'black',
                position: 'absolute',
                left: '60%',
                top: '50%',
                marginLeft: '-12px',
                marginTop: '-12px',
              }}
            />
          )}
        </Button>
      </div>
    </Container>
  );
};

export default UpdateExternalClient;
