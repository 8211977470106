import React from 'react';
import { useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Footer from './components/footer/Footer';
import PrivacyPolicy from './components/footer/PrivacyPolicy';
import ReturnPolicy from './components/footer/ReturnPolicy';
import TermsConditions from './components/footer/TermsConditions';
import NavBar from './components/nav-bar/NavBar';
import AdminDashboard from './pages/admin-pages/pages/AdminDashboard';
import MyCart from './pages/cart/MyCart';
import AddExternalClient from './pages/clients/external-clients/AddExternalClient';
import UpdateExternalClient from './pages/clients/external-clients/UpdateExternalClient';
import ExternalClientsPage from './pages/clients/pages/ExternalClientsPage';
import FitnessGuruClientsPage from './pages/clients/pages/FitnessGuruClientsPage';
import Landing from './pages/landing/Landing';
import Login from './pages/login/Login';
import MealPlan from './pages/meal/MealPlan';
import MealSelector from './pages/meal/MealSelector';
import Checkout from './pages/orders/Checkout';
import MyOrders from './pages/orders/MyOrders';
import PersonalInfo from './pages/personalInfo/PersonalInfo';
import CheckInjeries from './pages/profile/CheckInjeries';
import ClientProfile from './pages/profile/ClientProfile';
import Profile from './pages/profile/Profile';
import Sorry from './pages/profile/Sorry';
import TrainerProfile from './pages/profile/TrainerProfile';
import Register from './pages/register/Register';
import Shop from './pages/shop/Shop';
import SignleProduct from './pages/shop/SignleProduct';
import CareerDetails from './pages/trainer-pages/CareerDetails';
import PackageDetails from './pages/trainer-pages/PackageDetails';
import SingleTrainer from './pages/trainers/SingleTrainer';
import Trainers from './pages/trainers/Trainers';
import WorkoutPlan from './pages/workout/WorkoutPlan';
import CustomWorkoutForm from './pages/clients/pages/CustomWorkoutForm';
import ProfilePage from './pages/clients/pages/ProfilePage';
import PricingPage from './pages/price-plans/PricingPage';
import TrainerInfo from './pages/profile/TrainerInfo';
import ExternalWorkoutPlan from './pages/workout/ExternalWorkoutPlan';
import ExternalMealPlan from './pages/meal/ExternalMealPlan';

const App = () => {
  const authState = useSelector((state) => state.auth);
  const renderRoleBasedRoutes = () => {
    const userType = authState?.user?.role;
    if (userType === 'Client')
      return (
        <>
          <Route path="/profile" element={<Profile />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/shop/:id" element={<SignleProduct />} />
          <Route path="/meal" element={<MealSelector />} />
          <Route path="/personal-info" element={<PersonalInfo />} />
          <Route path="/trainers" element={<Trainers />} />
          <Route path="/trainers/:id" element={<SingleTrainer />} />
          <Route path="/cart" element={<MyCart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/my-orders" element={<MyOrders />} />
          <Route path="/check-injuries" element={<CheckInjeries />} />
          <Route path="/sorry" element={<Sorry />} />
          <Route path="/meal-plan/:id/:user" element={<MealPlan />} />
          <Route
            path="/workout-plan/:id/:level/:user"
            element={<WorkoutPlan />}
          />
        </>
      );
    else if (userType === 'Trainer')
      return (
        <>
          <Route path="/career-details" element={<CareerDetails />} />
          <Route path="/package-details" element={<PackageDetails />} />
          <Route path="/trainer-profile" element={<TrainerProfile />} />
          <Route path="/trainer-info" element={<TrainerInfo />} />
          <Route path="/client/:id" element={<ClientProfile />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/shop/:id" element={<SignleProduct />} />
          <Route path="/meal-plan/:reqID/:clientID" element={<MealPlan />} />
          <Route path="/meal-plan/:clientID" element={<MealPlan />} />
          <Route
            path="/workout-plan/:reqID/:clientID"
            element={<WorkoutPlan />}
          />
          <Route path="/workout-plan/:clientID" element={<WorkoutPlan />} />
          <Route
            path="/fitnessguru-clients"
            element={<FitnessGuruClientsPage />}
          />
          <Route path="/external-clients" element={<ExternalClientsPage />} />
          <Route path="/add-external-client" element={<AddExternalClient />} />
          <Route
            path="/update-external-client/:clientID"
            element={<UpdateExternalClient />}
          />
          <Route path="/meal/:reqID/:clientID" element={<MealSelector />} />
          <Route path="/meal/:clientID" element={<MealSelector />} />
          <Route
            path="/custom-workout/:clientID"
            element={<CustomWorkoutForm />}
          />
          <Route
            path="/custom-workout/:reqID/:clientID"
            element={<CustomWorkoutForm />}
          />
          <Route path="/client/profile/:id" element={<ProfilePage />} />
          <Route path="/pricing" element={<PricingPage />} />
        </>
      );
    else if (userType === 'FitnessGuru ')
      return (
        <>
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
        </>
      );
    else <></>;
  };

  return (
    <>
      <div
        style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
      >
        <div style={{ flexGrow: 1 }}>
          <Router>
            <NavBar />
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/home" element={<Landing />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/term-conditions" element={<TermsConditions />} />
              <Route path="/return-policy" element={<ReturnPolicy />} />
              <Route
                path="/external/workout/:clientID"
                element={<ExternalWorkoutPlan />}
              />
              <Route
                path="/external/meal/:clientID"
                element={<ExternalMealPlan />}
              />
              {/* role guards */}
              {renderRoleBasedRoutes()}
            </Routes>
            <Footer />
          </Router>
        </div>
      </div>
    </>
  );
};

export default App;
