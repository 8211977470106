import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authActions } from '../../store/authSlice';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ProfileImg from '../profile-card/ProfileImg';
import logo from '../../assets/v2/main-logo-white.png';
import { CardMedia, useMediaQuery } from '@mui/material';
import NotificationPanel from '../notification/NotificationPanel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth?.isLoggedIn);
  const userType = useSelector((state) => state.auth?.user?.role);
  const user = useSelector((state) => state.auth?.user);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElClients, setAnchorElClients] = useState(null);
  const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  //
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  //
  const handleOpenClientsMenu = (event) => {
    setAnchorElClients(event.currentTarget);
  };
  //
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  //
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  //
  const handleCloseClientsMenu = () => {
    setAnchorElClients(null);
  };
  //
  const handleNotification = () => {
    setIsNotificationPanelOpen((prevState) => !prevState);
  };
  //
  return (
    <AppBar position="static" style={{ backgroundColor: 'black' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {userType === 'Trainer' && (
            <NotificationPanel isOpen={isNotificationPanelOpen} />
          )}
          <CardMedia
            onClick={() => userType === 'Trainer' && navigate('/home')}
            component="img"
            image={logo}
            sx={{
              display: {
                xs: 'none',
                md: 'flex',
                width: 200,
                cursor: 'pointer',
              },
            }}
          ></CardMedia>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="white"
            >
              <MenuIcon sx={{ color: ' white ' }} />
            </IconButton>
            {anchorElNav && (
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {userType === 'client' && (
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography
                      textAlign="center"
                      component="a"
                      sx={{
                        textDecoration: 'none',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                      onClick={() => navigate('/home')}
                    >
                      Home
                    </Typography>
                  </MenuItem>
                )}
                {isLoggedIn && userType === 'client' && (
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography
                      textAlign="center"
                      component="a"
                      sx={{
                        textDecoration: 'none',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                      onClick={() => navigate('/trainers')}
                    >
                      Trainers
                    </Typography>
                  </MenuItem>
                )}
                {isLoggedIn && userType === 'Trainer' && (
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography
                      textAlign="center"
                      component="a"
                      sx={{
                        textDecoration: 'none',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                      onClick={handleNotification}
                    >
                      Notifications
                    </Typography>
                  </MenuItem>
                )}
                {isLoggedIn && userType === 'Trainer' && (
                  <MenuItem onClick={handleOpenClientsMenu}>
                    <Typography
                      textAlign="center"
                      component="a"
                      sx={{
                        textDecoration: 'none',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                    >
                      My Clients
                    </Typography>
                  </MenuItem>
                )}

                {!isLoggedIn && (
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography
                      textAlign="center"
                      component="a"
                      sx={{
                        textDecoration: 'none',
                        color: 'black',
                        fontWeight: 'bold',
                      }}
                      onClick={() => navigate('/login')}
                    >
                      Login
                    </Typography>
                  </MenuItem>
                )}
              </Menu>
            )}
          </Box>

          <CardMedia
            onClick={() => userType === 'client' && navigate('/home')}
            component="img"
            image={logo}
            sx={{
              display: {
                xs: 'flex',
                md: 'none',
                cursor: 'pointer',
              },
              width: 200,
              marginRight: isMobile ? '50px' : '30%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          ></CardMedia>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'right',
              mr: 3,
              margin: 0,
            }}
          >
            {userType === 'client' && (
              <Button
                // onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: 'white',
                  display: 'block',
                }}
                onClick={() => navigate('/home')}
              >
                Home
              </Button>
            )}

            {isLoggedIn && userType === 'client' && (
              <Button
                sx={{
                  my: 2,
                  color: 'white',
                  display: 'block',
                }}
                onClick={() => navigate('/trainers')}
              >
                Trainers
              </Button>
            )}
            {isLoggedIn && userType === 'Trainer' && (
              <Button
                sx={{
                  my: 2,
                  color: 'white',
                  display: 'block',
                }}
                onClick={handleOpenClientsMenu}
              >
                My Clients
              </Button>
            )}
            {anchorElClients && (
              <Menu
                anchorEl={anchorElClients}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={Boolean(anchorElClients)}
                onClose={handleCloseClientsMenu}
              >
                <MenuItem
                  onClick={() => {
                    navigate('/fitnessguru-clients');
                    handleCloseClientsMenu();
                  }}
                >
                  <Typography textAlign="center" sx={{ color: 'black' }}>
                    Fitness Guru
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('/external-clients');
                    handleCloseClientsMenu();
                  }}
                >
                  <Typography textAlign="center" sx={{ color: 'black' }}>
                    External
                  </Typography>
                </MenuItem>
              </Menu>
            )}

            {isLoggedIn && userType === 'Trainer' && (
              <Button
                sx={{
                  my: 2,
                  color: 'white',
                  display: 'block',
                }}
                onClick={handleNotification}
              >
                Notification
              </Button>
            )}

            {!isLoggedIn && (
              <Button
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: 'white',
                }}
                href={`/login`}
                endIcon={
                  <AccountCircleIcon
                    style={{ color: '#a0e220', fontSize: '2rem' }}
                  />
                }
              >
                login
              </Button>
            )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {isLoggedIn && (
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <ProfileImg
                    data={{
                      size: '40px',
                      image: user?.profileImageFileUrl,
                      fullName: `${user?.firstName} ${user?.lastName}`,
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {isLoggedIn && userType !== 'admin' && (
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography
                    onClick={() => {
                      userType === 'client'
                        ? navigate('/profile')
                        : navigate('/trainer-profile');
                    }}
                    textAlign="center"
                    component="a"
                    sx={{ textDecoration: 'none', color: 'black' }}
                  >
                    Profile
                  </Typography>
                </MenuItem>
              )}
              {isLoggedIn && userType === 'client' && (
                <div>
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography
                      onClick={() => {
                        navigate('/cart');
                      }}
                      textAlign="center"
                      component="a"
                      sx={{ textDecoration: 'none', color: 'black' }}
                    >
                      Cart
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography
                      onClick={() => {
                        navigate('/my-orders');
                      }}
                      textAlign="center"
                      component="a"
                      sx={{ textDecoration: 'none', color: 'black' }}
                    >
                      My Orders
                    </Typography>
                  </MenuItem>
                </div>
              )}
              {isLoggedIn && (
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography
                    onClick={() => {
                      dispatch(authActions.logout());
                      navigate('/home');
                    }}
                    textAlign="center"
                    component="a"
                    sx={{ textDecoration: 'none', color: 'black' }}
                  >
                    Logout
                  </Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;
