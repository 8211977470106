import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Grid,
  Modal,
  useMediaQuery,
} from '@mui/material';
import { Container } from '../../components/commonStyle';
import workoutPlanIcon from '../../assets/Workout-Icon.png';
import { getApi } from '../../utils/axios';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import Loader from '../../components/Loader/Loader';
import { useSelector } from 'react-redux';

const ExternalWorkoutPlan = () => {
  const trainer = useSelector((state) => state.auth?.user);
  const { clientID, reqID } = useParams();
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [workouts, setWorkouts] = useState(null);
  const [client, setClient] = useState(null);
  const isMobile = useMediaQuery('(max-width:660px)');
  const handleOpenModal = (exercise) => setSelectedExercise(exercise);
  const handleCloseModal = () => setSelectedExercise(null);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (reqID) {
      getApi()
        .get(`/workout/by-trainer/${clientID}`)
        .then(async (res) => {
          setWorkouts(res?.data?.data);
        })
        .catch((err) => {
          console.error(err?.response?.data?.message);
        });
    } else {
      getApi()
        .get(`/workout/by-trainer-for-external-clients/${clientID}`)
        .then(async (res) => {
          setWorkouts(res?.data?.data);
        })
        .catch((err) => {
          console.error(err?.response?.data?.message);
        });
    }
    return () => {
      source.cancel();
    };
  }, [clientID, reqID]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    //
    if (reqID) {
      getApi()
        .get(`/trainer-request/${reqID}`)
        .then((res) => setClient(res?.data?.data))
        .catch((err) => console.error(err?.response?.data?.message));
    }
    return () => {
      source.cancel();
    };
  }, [reqID]);

  if (workouts === null)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '20vh',
        }}
      >
        <Loader />
      </Box>
    );
  else
    return (
      <Container>
        {/* User Information */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row', // Adjust layout for mobile
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: 2,
            backgroundImage: 'linear-gradient(to top, #a0e220, #70b380)',
            borderRadius: 2,
            padding: isMobile ? 2 : 0, // Add padding for mobile
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row', // Stack items for mobile
              alignItems: 'center',
              justifyContent: 'space-between',
              borderRadius: 2,
              marginBottom: isMobile ? 2 : 0, // Add margin for mobile
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              {/* First Image or Placeholder */}
              {reqID ? (
                client?.profilePhotoUrl ? (
                  <img
                    src={client?.profilePhotoUrl}
                    alt="user"
                    style={{
                      width: isMobile ? '80px' : '100px', // Adjust size for mobile
                      height: isMobile ? '80px' : '100px',
                      objectFit: 'cover',
                      borderRadius: '8px 0 0 8px',
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: isMobile ? '80px' : '100px', // Adjust size for mobile
                      height: isMobile ? '80px' : '100px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'gray',
                      borderRadius: '8px 0 0 8px',
                    }}
                  >
                    <Typography
                      variant="h5"
                      color={'white'}
                      fontWeight={700}
                      textTransform={'uppercase'}
                    >
                      {client?.fullName?.slice(0, 2) || 'NA'}
                    </Typography>
                  </Box>
                )
              ) : workouts?.externalClientId?.profilePhotoUrl ? (
                <img
                  src={workouts.externalClientId.profilePhotoUrl}
                  alt="user"
                  style={{
                    width: isMobile ? '80px' : '100px',
                    height: isMobile ? '80px' : '100px',
                    objectFit: 'cover',
                    borderRadius: '8px 0 0 8px',
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: isMobile ? '80px' : '100px',
                    height: isMobile ? '80px' : '100px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'gray',
                    borderRadius: '8px 0 0 8px',
                  }}
                >
                  <Typography
                    variant="h5"
                    color={'white'}
                    fontWeight={700}
                    textTransform={'uppercase'}
                  >
                    {workouts?.externalClientId?.name?.slice(0, 2) || 'NA'}
                  </Typography>
                </Box>
              )}

              {/* Icon */}
              <Box
                sx={{
                  position: 'absolute',
                  left: 'calc(50% - 17px)',
                  zIndex: 1,
                  color: 'white',
                }}
              >
                <LinkIcon fontSize="large" />
              </Box>

              {/* Second Image or Placeholder */}
              {!reqID ? (
                workouts?.createdBy?.profileImageFileUrl ? (
                  <img
                    src={workouts.createdBy.profileImageFileUrl}
                    alt="user"
                    style={{
                      width: isMobile ? '80px' : '100px',
                      height: isMobile ? '80px' : '100px',
                      objectFit: 'cover',
                      borderRadius: '0 8px 8px 0',
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: isMobile ? '80px' : '100px',
                      height: isMobile ? '80px' : '100px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'gray',
                      borderRadius: '0 8px 8px 0',
                    }}
                  >
                    <Typography
                      variant="h5"
                      color={'white'}
                      fontWeight={700}
                      textTransform={'uppercase'}
                    >
                      {workouts?.createdBy?.firstName?.slice(0, 2) || 'NA'}
                    </Typography>
                  </Box>
                )
              ) : trainer?.profileImageFileUrl ? (
                <img
                  src={trainer?.profileImageFileUrl}
                  alt="user"
                  style={{
                    width: isMobile ? '80px' : '100px',
                    height: isMobile ? '80px' : '100px',
                    objectFit: 'cover',
                    borderRadius: '0 8px 8px 0',
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: isMobile ? '80px' : '100px',
                    height: isMobile ? '80px' : '100px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'gray',
                    borderRadius: '0 8px 8px 0',
                  }}
                >
                  <Typography
                    variant="h5"
                    color={'white'}
                    fontWeight={700}
                    textTransform={'uppercase'}
                  >
                    {trainer?.firstName?.slice(0, 2) || 'NA'}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                marginLeft: isMobile ? 0 : 2,
                marginTop: isMobile ? 2 : 0,
                textAlign: isMobile ? 'center' : 'start',
              }}
            >
              <Typography
                variant="h5"
                color={'black'}
                fontWeight={700}
                textTransform={'uppercase'}
              >
                {reqID ? client?.fullName : workouts?.externalClientId?.name}
              </Typography>
              <Typography variant="body2" color={'black'} fontWeight={300}>
                Age:{' '}
                {reqID
                  ? client?.age
                  : workouts?.externalClientId?.personalInfo?.age}{' '}
                | DCI:{' '}
                {reqID
                  ? client?.calculatedMetrics?.dci?.toFixed(2)
                  : workouts?.externalClientId?.calculatedMetrics?.dci?.toFixed(
                      2
                    )}{' '}
                | Weight:{' '}
                {reqID
                  ? client?.weight
                  : workouts?.externalClientId?.personalInfo?.weight}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              textAlign: isMobile ? 'center' : 'right',
              paddingRight: isMobile ? 0 : '1rem',
            }}
          >
            <Typography
              variant={isMobile ? 'body1' : 'h7'}
              color={'black'}
              fontWeight={700}
            >
              WORKOUT ROUTINE
            </Typography>
            <Typography variant="body2" color={'black'} fontWeight={200}>
              {new Date(workouts?.updatedAt)
                ?.toLocaleDateString('en-US', {
                  month: 'short',
                  year: 'numeric',
                })
                ?.toUpperCase()}
            </Typography>
          </Box>
        </Box>

        {/* Workout Days and Exercises */}
        {workouts?.exerciseDays?.map((dayData, index) => (
          <Box key={index} sx={{ marginTop: 3 }}>
            <Typography
              variant="h5"
              fontWeight={700}
              sx={{ color: '#a0e220', marginBottom: 2, marginTop: 4 }}
            >
              Day {dayData?.day}
            </Typography>
            <Grid container spacing={2}>
              {dayData?.exercises?.map((exercise, idx) => (
                <Grid
                  item
                  xs={12}
                  key={idx}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleOpenModal(exercise)}
                >
                  <Card
                    sx={{
                      backgroundColor: '#424242',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: isMobile ? 'center' : 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: '#a0e220',
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                          }}
                        >
                          <img
                            src={
                              exercise?.exercise?.url
                                ? exercise?.exercise?.url
                                : workoutPlanIcon
                            }
                            alt="Exercise Icon"
                            style={{ width: 80, height: 80 }}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          variant={isMobile ? 'body2' : 'h6'}
                          sx={{
                            fontWeight: 'bold',
                            marginLeft: 2,
                            textTransform: 'capitalize',
                          }}
                          color={'white'}
                          fontWeight={200}
                        >
                          {exercise?.exercise?.name}
                        </Typography>
                        {isMobile && (
                          <Typography
                            variant={isMobile ? 'caption' : 'body1'}
                            color={'white'}
                            fontWeight={200}
                            sx={{ paddingLeft: 2 }}
                          >
                            Sets: {exercise?.sets} | Reps: {exercise?.reps} |
                            Rest: {exercise?.rest}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    {!isMobile && (
                      <Typography
                        variant={isMobile ? 'caption' : 'body1'}
                        color={'white'}
                        fontWeight={200}
                        sx={{ paddingRight: 2 }}
                      >
                        Sets: {exercise?.sets} | Reps: {exercise?.reps} | Rest:{' '}
                        {exercise?.rest}
                      </Typography>
                    )}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}

        {/* Modal for Exercise Details */}
        <Modal open={!!selectedExercise} onClose={handleCloseModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: isMobile ? 250 : 400,
              bgcolor: '#333',
              color: 'white',
              p: isMobile ? 3 : 4,
              borderRadius: 2,
              textAlign: 'center',
            }}
          >
            {selectedExercise && (
              <>
                <Box
                  sx={{
                    mb: 2,
                  }}
                >
                  <img
                    src={selectedExercise?.exercise?.url}
                    alt="Exercise Details"
                    width={200}
                    height={200}
                    style={{
                      objectFit: 'cover',
                      borderRadius: 5,
                    }}
                  />
                </Box>
                <Typography
                  variant="h5"
                  sx={{ mb: 1, textTransform: 'capitalize' }}
                  fontWeight={isMobile ? 200 : 600}
                >
                  {selectedExercise?.exercise?.name}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '15px 20px',
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      padding: isMobile ? '5px 15px' : '10px 35px',
                      borderRadius: '5px',
                    }}
                  >
                    <Typography variant="h5" color={'black'} fontWeight={600}>
                      {selectedExercise?.sets}
                    </Typography>
                    <Typography variant="h7" color={'black'}>
                      Sets
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      padding: isMobile ? '5px 15px' : '10px 35px',
                      borderRadius: '5px',
                    }}
                  >
                    <Typography variant="h5" color={'black'} fontWeight={600}>
                      {selectedExercise?.reps}
                    </Typography>
                    <Typography variant="h7" color={'black'}>
                      Reps
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      padding: isMobile ? '5px 15px' : '10px 35px',
                      borderRadius: '5px',
                    }}
                  >
                    <Typography variant="h5" color={'black'} fontWeight={600}>
                      {selectedExercise?.rest}
                    </Typography>

                    <Typography variant="h7" color={'black'}>
                      Rest
                    </Typography>
                  </Box>
                </Box>

                <Typography variant="body2">
                  {selectedExercise?.exercise?.description}
                </Typography>
              </>
            )}
          </Box>
        </Modal>
      </Container>
    );
};

export default ExternalWorkoutPlan;
