import React from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
  useTheme,
  useMediaQuery,
  styled,
  Container,
} from '@mui/material';
import img1 from '../../assets/v2/landing-img-1.png';
import img2 from '../../assets/v2/landing-img-2.png';
import img3 from '../../assets/v2/landing-img-3.png';
import img4 from '../../assets/v2/landing-img-4.png';
import img5 from '../../assets/v2/landing-img-5.png';
import img6 from '../../assets/v2/landing-img-6.png';
import img7 from '../../assets/v2/landing-img-7.png';
import img8 from '../../assets/v2/landing-img-8.png';
import img9 from '../../assets/v2/landing-img-9.png';

const features = [
  {
    title: 'Expand Your Reach',
    description:
      'Tap into a broader audience and attract more clients locally and internationally.',
    image: img1,
  },
  {
    title: 'Showcase Your Expertise',
    description:
      'Build a professional portfolio that highlights your skills, certifications, and success stories.',
    image: img2,
  },
  {
    title: 'Grow Your Business',
    description:
      'Boost your client base and income with tools designed to scale your services.',
    image: img3,
  },
  {
    title: 'Client Management',
    description:
      'Save valuable time by managing all your clients in one place with intuitive, easy-to-use tools.',
    image: img4,
  },
  {
    title: 'In-App Messaging',
    description:
      'Stay in touch with your clients and track their progress by messaging them directly.',
    image: img5,
  },
  {
    title: 'Payment Handling',
    description:
      'Get your payments handled, from payment reminders to automatic payment renewals etc.',
    image: img6,
  },
  {
    title: 'Performance Tracking',
    description:
      'Get insights on how your listing performs and make changes based on insights.',
    image: img7,
  },
  {
    title: 'Marketing Support',
    description:
      'Free marketing through our marketing channels and paid promotions.',
    image: img8,
  },
  {
    title: 'Collaboration',
    description:
      'Collaborative opportunities with other like-minded trainers and creators.',
    image: img9,
  },
];

const ResponsiveGridLayout = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Container
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          color: 'white',
          mb: 10,
          fontSize: matchesXs ? '2rem' : matchesSm ? '2.5rem' : '3rem',
        }}
      >
        WHY FITNESS GURU?
      </Typography>

      <Grid container spacing={matchesXs ? 2 : matchesSm ? 3 : 3}>
        {features.map((feature, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Card
              sx={{
                backgroundColor: '#2C2C2C',
                color: 'white',
                position: 'relative',
                borderRadius: '10px',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                // Responsive width
                width: '100%',
                maxWidth: matchesXs ? '100%' : matchesSm ? '90%' : '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '2rem',
                  flexGrow: 1,
                }}
              >
                <CardMedia
                  component="img"
                  image={feature.image}
                  alt={feature.title}
                  sx={{
                    height: matchesXs ? '6rem' : matchesSm ? '7rem' : '6rem',
                    width: matchesXs ? '6rem' : matchesSm ? '7rem' : '6rem',
                    objectFit: 'cover',
                  }}
                />
              </Box>
              <CardContent
                sx={{
                  textAlign: 'center',
                  padding: '1rem',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: '#a0e220',
                    fontWeight: 'bold',
                    fontSize: matchesXs
                      ? '1rem'
                      : matchesSm
                      ? '1.25rem'
                      : '1.2rem',
                  }}
                >
                  {feature.title}
                </Typography>
                <Box sx={{ minHeight: '5rem' }}>
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 1,
                      fontSize: matchesXs
                        ? '0.75rem'
                        : matchesSm
                        ? '0.875rem'
                        : '0.9rem',
                    }}
                  >
                    {feature.description}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ResponsiveGridLayout;
