import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Slide,
} from '@mui/material';
import { Container } from '@mui/system';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { useDispatch, useSelector } from 'react-redux';
import ProfileImg from './ProfileImg';
import { getApiForFormData } from '../../utils/axios';
import { popAlert } from '../../utils/alert';
import { authActions } from '../../store/authSlice';

const styles = {
  typo: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.2rem',
    marginTop: '1rem',
  },
  typo2: {
    textAlign: 'right',
    fontSize: '1.2rem',
    marginTop: '1rem',
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserProfileCard({ user }) {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.auth.user?.type);
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState({
    file: null,
    previewUrl: user?.profileImg,
  });
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setImage({
      file: null,
      previewUrl: null,
    });
  };
  //
  const handleUpload = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', image.file);
    await getApiForFormData()
      .post('/trainer/upload-profile-image', formData)
      .then((res) => {
        setOpen(false);
        setImage({
          file: null,
          previewUrl: null,
        });
        dispatch(authActions.setUser(res?.data?.data));
        setLoading(false);
        popAlert('success!', 'Profile photo updated!', 'success', 'Ok');
      })
      .catch((err) => {
        setLoading(false);
        setOpen(false);
        err.response?.data?.message &&
          popAlert('Error!', err.response?.data?.message, 'error', 'OK');
      });
  };
  const handleProfileImageUpload = () => {
    setOpen(true);
  };
  //
  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    setImage({
      file: selectedFile,
      previewUrl: URL.createObjectURL(selectedFile),
    });
  };
  //

  return (
    <Card sx={{ backgroundColor: '#373736', padding: '1.5rem 1rem' }}>
      <CardContent
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ cursor: 'pointer' }} onClick={handleProfileImageUpload}>
          <ProfileImg
            data={{
              size: '150px',
              image: user.profileImg,
              fullName: `${user.name}`,
            }}
          />
        </Box>
      </CardContent>

      <CardContent>
        <Typography
          gutterBottom
          variant="h4"
          sx={{ textAlign: 'center', fontWeight: 'bold', color: 'white' }}
        >
          {user.name}
        </Typography>
        <Divider
          variant="middle"
          sx={{
            borderColor: '#818180',
            borderWidth: 1,
            my: 2,
          }}
        />
        <Container>
          <Grid container spacing={8}>
            <Grid item xs={7} sm={7}>
              <Typography sx={styles.typo} color="white">
                <FmdGoodIcon color="white" />
                <span style={{ marginLeft: '10px' }}>From</span>
              </Typography>
              <Typography sx={styles.typo} color="white">
                <CalendarMonthIcon color="white" />
                <span style={{ marginLeft: '10px' }}>Member Since</span>
              </Typography>
              <Typography sx={styles.typo} color="white">
                <PersonIcon color="white" />
                <span style={{ marginLeft: '10px' }}>Member Type</span>
              </Typography>
              {userType === 'client' && (
                <Typography sx={styles.typo} color="white">
                  <FitnessCenterIcon color="white" />
                  <span style={{ marginLeft: '10px' }}>Goal</span>
                </Typography>
              )}
            </Grid>
            <Grid item xs={5} sm={5}>
              <Typography
                sx={{
                  ...styles.typo2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                color="white"
              >
                {user.from}
              </Typography>
              <Typography sx={styles.typo2} color="white">
                {user.since}
              </Typography>
              <Typography sx={styles.typo2} color="white">
                {user.type}
              </Typography>
              <Typography sx={styles.typo2} color="white">
                {user.goal}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </CardContent>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Update Profile Image'}</DialogTitle>
        <DialogContent>
          <div
            style={{
              height: '100px',
              borderRadius: '10px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button variant="outlined" component="label" color="primary">
              Upload a photo
              <input
                hidden
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={handleImageChange}
              />
            </Button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {image.previewUrl && (
              <img
                src={image.previewUrl}
                alt="Preview"
                style={{ width: '50%', objectFit: 'cover' }}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleUpload} disabled={!image?.file}>
            Update
            {loading && (
              <CircularProgress
                size={18}
                sx={{
                  color: 'black',
                  marginLeft: '6px',
                }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
