import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Divider, Typography } from '@mui/material';
import CartItem from '../../components/cart-card/CartItem';
import { useNavigate } from 'react-router-dom';
import { getApi } from '../../utils/axios';
import { useSelector } from 'react-redux';
const Container = styled('div')({
  margin: '1rem 9rem',
  '@media (max-width:600px)': {
    margin: '1rem 2rem',
  },
  '@media (max-width:480px)': {
    margin: '1rem',
  },
});
const MyCart = () => {
  const navigate = useNavigate();
  const cartState = useSelector((state) => state.cart);
  const [pCartResult, setPCartResult] = useState([]);

  useEffect(() => {
    getApi()
      .post('/orders/cart', { cartItems: cartState.cart })
      .then((res) => setPCartResult(res.data))
      .catch((err) => console.error(err));
  }, [cartState]);
  return (
    <div>
      <Container>
        <h2 style={{ color: '#a0e220' }}>Cart Page</h2>
        {cartState?.cart?.length === 0 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '100px',
            }}
          >
            <div>
              <Typography variant="h5" sx={{ color: 'white' }}>
                No cart Items available
              </Typography>
            </div>
            <div>
              <Button
                sx={{
                  backgroundColor: '#a0e220',
                  color: 'white',
                  margin: '20px',
                  padding: '5px 15px',
                }}
                onClick={() => navigate('/shop')}
              >
                Shop now
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                color: '#9A9A9A',
              }}
            >
              <h4></h4>
              <h4>PRODUCT</h4>
              <h4>PRICE</h4>
              <h4>QTY.</h4>
              <h4>SUBTOTAL</h4>
              <h4></h4>
            </div>
            <Divider
              variant="fillWidth"
              sx={{ mt: 3, backgroundColor: '#9A9A9A' }}
            />
            {pCartResult?.items?.map((item) => (
              <CartItem key={item?.cartId} cartItem={item} />
            ))}
            <Divider
              variant="fillWidth"
              sx={{ mb: 3, backgroundColor: '#9A9A9A' }}
            />{' '}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                color: '#9A9A9A',
              }}
            >
              <h3 style={{ marginRight: '5rem' }}>TOTAL</h3>
              <h3 style={{ color: '#a0e220' }}>RS.{pCartResult?.subTotal}</h3>
            </div>
            <Container>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    padding: '12px 24px',
                    backgroundImage: '#a0e220',
                    color: 'white',
                    '&:hover': {
                      backgroundImage: '#a0e220',
                    },
                    borderRadius: 4,
                    fontWeight: 'bold',
                    width: '50%',
                    mt: 2,
                  }}
                  onClick={() =>
                    navigate('/checkout', { state: { pCartResult } })
                  }
                >
                  PROCEED TO CHEKOUT
                </Button>
              </div>
            </Container>
          </>
        )}
      </Container>
    </div>
  );
};

export default MyCart;
