import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Tooltip } from '@mui/material';
import { getDownloadURLFromFirebaseRef } from '../../utils/firebase';
import loadingImage from '../../assets/loading.gif';

export default function ItemCard({ data }) {
  const [image, setImage] = useState('');

  useEffect(() => {
    let unmounted = false;
    const fetchImage = async () => {
      const url = await getDownloadURLFromFirebaseRef(data.firebaseRef);
      if (!unmounted && url) setImage(url);
    };

    fetchImage();

    return () => {
      unmounted = true;
    };
  }, [data.firebaseRef]);

  return (
    <Card
      sx={{
        maxWidth: 345,
        borderRadius: 5,
        border: '2px solid black',
        borderColor: '#373736',
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="300"
          image={image ? image : loadingImage}
          alt="wear"
          style={{ objectFit: 'cover' }}
        />
        <CardContent sx={{ backgroundColor: '#1d1d1d', height: 58 }}>
          <div>
            <Tooltip title={data.name} placement="top">
              <Typography
                gutterBottom
                variant="h6"
                color="white"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {data.name}
              </Typography>
            </Tooltip>
          </div>

          <Typography gutterBottom variant="h6" color="#a0e220">
            Rs.{data.price}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
