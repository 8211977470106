// ProfilePage.jsx
import { Box, Button, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Container } from '../../../components/commonStyle';
import ProfileCard from '../components/ProfileCard';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useParams, useNavigate } from 'react-router-dom';
import { getApi } from '../../../utils/axios';
import axios from 'axios';
import { popAlert } from '../../../utils/alert';
import Loader from '../../../components/Loader/Loader';

const ProfilePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [client, setClient] = useState(null);
  const [downloadedClientProfile, setDownloadedClientProfile] = useState(null);

  useEffect(() => {
    const source = axios.CancelToken.source();
    //
    getApi()
      .get(`/trainer-request/${id}`)
      .then((res) => setClient(res?.data?.data))
      .catch((err) => console.error(err?.response?.data?.message));

    return () => {
      source.cancel();
    };
  }, [id]);
  //
  const handleDownload = async () => {
    const requestId = id;
    await getApi()
      .get('/trainer-request/download-client-profile', requestId)
      .then((res) => setDownloadedClientProfile(res?.data?.data))
      .catch((err) => {
        popAlert(
          'Error!',
          err?.response?.data?.message || 'An error occurred',
          'error',
          'ok'
        );
      });
  };
  //
  const handleContinue = () => {
    navigate(`/custom-workout/${id}/${client?.clientId}`);
  };
  //
  if (!client)
    return (
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Loader />
        </Box>
      </Container>
    );
  else
    return (
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: '#a0e220', fontWeight: 'bold' }}
          >
            Daham's Profile
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Button
              variant="outlined"
              startIcon={<DownloadIcon color="secondary" />}
              onClick={() => handleDownload()}
            >
              <Typography variant="h7" color={'white'}>
                Download
              </Typography>
            </Button>
            <Box onClick={() => handleContinue()}>
              <ArrowCircleRightIcon
                color="primary"
                sx={{ fontSize: '2.5rem', cursor: 'pointer' }}
              />
            </Box>
          </Box>
        </Box>
        <Paper sx={{ marginTop: 2, padding: 2 }}>
          <ProfileCard client={client} />
        </Paper>
      </Container>
    );
};

export default ProfilePage;
