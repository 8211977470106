import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Slide,
  Tooltip,
} from '@mui/material';
import { Container } from '@mui/system';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { useSelector } from 'react-redux';
import ProfileImg from './ProfileImg';
import { getApiForFormData } from '../../utils/axios';
import { popAlert } from '../../utils/alert';

const styles = {
  typo: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.2rem',
    marginTop: '1rem',
  },
  typo2: {
    textAlign: 'right',
    fontSize: '1.2rem',
    marginTop: '1rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ClientProfileCard({ user }) {
  const userType = useSelector((state) => state.auth.user?.type);
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState({
    file: null,
    previewUrl: null,
  });

  return (
    <Card sx={{ backgroundColor: '#373736', padding: '1.5rem 1rem' }}>
      <CardContent
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ cursor: 'pointer' }}>
          <ProfileImg
            data={{
              size: '150px',
              image: null,
              fullName: `${user.name}`,
            }}
          />
        </Box>
      </CardContent>

      <CardContent>
        <Typography
          gutterBottom
          variant="h4"
          sx={{ textAlign: 'center', fontWeight: 'bold', color: 'white' }}
        >
          {user.name}
        </Typography>
        <Divider
          variant="middle"
          sx={{
            borderColor: '#818180',
            borderWidth: 1,
            my: 2,
          }}
        />
        <Container>
          <Grid container spacing={8}>
            <Grid item xs={7} sm={7} md={5}>
              <Typography sx={styles.typo} color="white">
                <FmdGoodIcon color="white" />
                <span style={{ marginLeft: '10px' }}>From</span>
              </Typography>
              <Typography sx={styles.typo} color="white">
                <CalendarMonthIcon color="white" />
                <span style={{ marginLeft: '10px' }}>Mobile</span>
              </Typography>
              <Typography sx={styles.typo} color="white">
                <PersonIcon color="white" />
                <span style={{ marginLeft: '10px' }}>Email</span>
              </Typography>
              <Typography sx={styles.typo} color="white">
                <FitnessCenterIcon color="white" />
                <span style={{ marginLeft: '10px' }}>Goal</span>
              </Typography>
            </Grid>
            <Grid item xs={5} sm={5} md={7}>
              <Typography sx={styles.typo2} color="white">
                {user.from}
              </Typography>
              <Typography sx={styles.typo2} color="white">
                {user.mobile}
              </Typography>
              <Tooltip title={user.email} placement="top">
                <Typography sx={styles.typo2} color="white">
                  {user.email}
                </Typography>
              </Tooltip>
              <Typography sx={styles.typo2} color="white">
                {user.goal}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </CardContent>
    </Card>
  );
}
