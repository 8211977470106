import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import store from './store/store';
import './app.css';

import App from './App';
import { ThemeProvider, createTheme } from '@mui/material';
//With the Redux Persist library, can save the Redux store in persistent storage,
//for example, the local storage. Therefore, even after refreshing the browser, the site state will still be preserved
let persistor = persistStore(store);

const theme = createTheme({
  palette: {
    primary: {
      main: '#a0e220',
    },
    secondary: {
      main: '#fff',
    },
    error: {
      main: '#d33',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div style={{ backgroundColor: '#1d1d1d', margin: '-10px' }}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </div>
);
