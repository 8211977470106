import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  days: [
    {
      day: 'Day 1',
      exercises: [
        { order: 1, exercise: { name: '', id: '' }, sets: 0, reps: 0, rest: 0 },
      ],
    },
  ],
};

const workoutSlice = createSlice({
  name: 'workout',
  initialState,
  reducers: {
    addDay(state) {
      const newDay = `Day ${state.days.length + 1}`;
      state.days.push({
        day: newDay,
        exercises: [{ order: 1, exercise: '', sets: 0, reps: 0, rest: 0 }],
      });
    },
    addRow(state, action) {
      const { dayIndex } = action.payload;
      const newOrder = state.days[dayIndex].exercises.length + 1;
      state.days[dayIndex].exercises.push({
        order: newOrder,
        exercise: '',
        sets: 0,
        reps: 0,
        rest: 0,
      });
    },
    removeRow(state, action) {
      const { dayIndex, rowIndex } = action.payload;
      if (state.days[dayIndex].exercises.length > 1) {
        state.days[dayIndex].exercises.splice(rowIndex, 1);
        // Reorder remaining exercises
        state.days[dayIndex].exercises.forEach((exercise, index) => {
          exercise.order = index + 1;
        });
      }
    },
    removeDay(state, action) {
      const { dayIndex } = action.payload;
      if (state.days.length > 1) {
        state.days.splice(dayIndex, 1);
        // Reorder days
        state.days.forEach((day, index) => {
          day.day = `Day ${index + 1}`;
          // Reorder exercises in each day
          day.exercises.forEach((exercise, subIndex) => {
            exercise.order = subIndex + 1;
          });
        });
      }
    },
    updateInput(state, action) {
      const { dayIndex, rowIndex, field, value } = action.payload;
      state.days[dayIndex].exercises[rowIndex][field] = value;
    },
    setWorkoutData(state, action) {
      const workoutData = action.payload;
      state.days = workoutData.exerciseDays.map((day) => ({
        day: `Day ${day.day}`,
        exercises: day.exercises.map((exercise) => ({
          order: exercise.order,
          exercise: { name: '', id: exercise.exercise }, //NOTE: need to set the name
          sets: exercise.sets,
          reps: exercise.reps,
          rest: exercise.rest,
        })),
      }));
    },
    resetWorkout(state) {
      return initialState;
    },
  },
});

export const {
  addDay,
  addRow,
  removeRow,
  removeDay,
  updateInput,
  setWorkoutData,
  resetWorkout,
} = workoutSlice.actions;

export default workoutSlice;
