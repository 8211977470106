import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileImg from '../profile-card/ProfileImg';

const TrainerCard = ({ trainer }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        maxWidth: 345,
        borderRadius: 5,
        backgroundColor: '#373736',
        padding: '1rem 1rem',
      }}
    >
      <CardActionArea>
        <CardContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ProfileImg
            data={{
              size: '100px',
              image: trainer?.image?.firebaseStorageRef,
              fullName: `${trainer?.firstName} ${trainer?.lastName}`,
            }}
          />
        </CardContent>
        <CardContent sx={{ backgroundColor: '#373736', textAlign: 'center' }}>
          <Typography
            gutterBottom
            variant="h6"
            color="white"
            style={{ fontWeight: 'bold', margin: 0 }}
          >
            {trainer?.firstName} {trainer?.lastName}
          </Typography>
          <Typography gutterBottom variant="h6" color="white">
            {trainer?.trainer?.careerLength} Years Experience
          </Typography>
          <div
            style={{
              backgroundColor: '#D9D9D9',
              borderRadius: '10px',
              textAlign: 'center',
            }}
          >
            <Typography gutterBottom variant="h6">
              {trainer?.trainer?.preferredGender}
            </Typography>
          </div>

          <Button
            sx={{
              color: '#a0e220',
              border: '1px solid #a0e220',
              width: '100%',
              borderRadius: '10px',
              margin: '10px 0px 10px 0px',
            }}
            onClick={() =>
              navigate(`/trainers/${trainer._id}`, { state: trainer })
            }
          >
            <span>Compare Packages</span>
          </Button>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TrainerCard;
