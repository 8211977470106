import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardMedia, Grid, Typography } from '@mui/material';
import SingleTrainerCard from '../../components/trainer-card/SingleTrainerCard';
import { useLocation } from 'react-router-dom';
import mealPlan from '../../assets/meal-plan.jpg';
import { getDownloadURLFromFirebaseRef } from '../../utils/firebase';

const Container = styled('div')({
  margin: '1rem 9rem',
  '@media (max-width:600px)': {
    margin: '1rem 2rem',
  },
  '@media (max-width:480px)': {
    margin: '1rem',
  },
});
const SingleTrainer = () => {
  const { state } = useLocation();
  const [packages, setPackages] = useState('basic');
  const [pItems, setPItems] = useState([]);

  useEffect(() => {
    let unmounted = false;
    const fetchImage = async () => {
      const items = state.trainer.portfolioImages;
      if (!items) return;
      for (const item of items) {
        if (item.firebaseStorageRef) {
          const url = await getDownloadURLFromFirebaseRef(
            item.firebaseStorageRef
          );
          item.image = url;
        } else {
          // TODO: set default image
          item.image = undefined;
        }
      }
      if (!unmounted) {
        setPItems(items);
        // setIsLoading(false);
      }
    };

    fetchImage();

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <div>
      <Container>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={5} lg={6}>
            <SingleTrainerCard
              data={{
                id: state._id,
                name: state.firstName + ' ' + state.lastName,
                experience: state.trainer.careerLength,
                image: state?.image?.firebaseStorageRef,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={7} lg={6}>
            <Card
              sx={{
                backgroundColor: '#373736',
                borderColor: '#818180',
                height: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  {state?.trainer?.packages?.map((pkg, index) => {
                    return (
                      <Button
                        key={index}
                        variant="contained"
                        sx={{
                          flex: 1,
                          backgroundImage:
                            packages === pkg.name
                              ? '#a0e220'
                              : 'linear-gradient(to right,#D9D9D9, gray)', // add background image
                          color: packages === pkg.name ? 'white' : 'black',
                          '&:hover': {
                            backgroundImage:
                              packages === pkg.name
                                ? '#a0e220'
                                : 'linear-gradient(to top,#D9D9D9, #D9D9D9)', // change background image on hover
                          },
                          fontWeight: 'bold',
                          padding: '0.7rem 3rem',
                          borderRadius: 0,
                        }}
                        onClick={() => setPackages(pkg?.name)}
                      >
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {pkg?.name}
                        </Typography>
                      </Button>
                    );
                  })}
                </div>
                {state?.trainer?.packages?.map((pkg, index) => {
                  return (
                    <React.Fragment key={index}>
                      {packages === pkg.name ? (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              margin: '2rem 2rem 0 2rem',
                            }}
                          >
                            {' '}
                            <Typography
                              variant="h5"
                              sx={{
                                color: 'white',
                              }}
                            >
                              {pkg.period} MONTHS
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{
                                color: '#a0e220',
                              }}
                            >
                              RS.{pkg.price}
                            </Typography>
                          </div>
                          <Typography
                            variant="p"
                            sx={{
                              margin: 4,
                              color: 'white',
                              textAlign: 'left',
                              lineHeight: '1.5rem',
                            }}
                          >
                            {pkg.description}
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </Card>
          </Grid>
        </Grid>
        <Box sx={{ mt: 3, width: '100%' }}>
          <Grid container spacing={0}>
            {pItems?.map((item, index) => {
              return (
                <Grid item md={6} sx={6} lg={6} key={index}>
                  <CardMedia
                    component="img"
                    height="auto"
                    image={item.image}
                    alt="Card 1"
                    style={{ border: '2px solid #707070' }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default SingleTrainer;
