import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: [],
  },
  reducers: {
    addToCart(state, action) {
      const { productId, qty, gymWear } = action.payload;
      state.cart.push({
        cartId: new Date().valueOf(),
        product: {
          _id: productId,
        },
        gymWear: {
          size: gymWear.size,
          color: gymWear.color,
        },
        quantity: qty,
      });
    },
    removeFromCart(state, action) {
      const cartId = action.payload;
      state.cart = state.cart.filter((item) => item.cartId !== cartId);
    },
    changeQty(state, action) {
      const { cartId, quantity } = action.payload;
      const index = state.cart.findIndex((item) => item.cartId === cartId);
      state.cart[index].quantity = quantity;
    },
  },
});
export const cartActions = cartSlice.actions;
export default cartSlice;
