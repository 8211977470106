import styled from '@emotion/styled';
import { Box, Button, Divider, InputBase, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import MealItemCard from '../../components/meal-card/MealItemCard';
import { finessRecordsActions } from '../../store/finessRecordSlice';
import { popAlert } from '../../utils/alert';
import { getApi } from '../../utils/axios';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowNextIcon from '@mui/icons-material/ArrowForward';

const Container = styled('div')({
  margin: '3rem 5rem',
  '@media (max-width:600px)': {
    margin: '1rem 2rem',
  },
  '@media (max-width:480px)': {
    margin: '1rem',
  },
});

const meals = ['breakfast', 'lunch', 'dinner'];

const MealSelector = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientID, reqID } = useParams();
  const [foods, setFoods] = useState([]);
  const [mealIndex, setMealIndex] = useState(0);
  const fitnessRecordState = useSelector((state) => state.fitnessRecords);
  const [mealID, setMealId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [scrollerToTop, setScrollerToTop] = useState(0);
  const [client, setClient] = useState();
  const [totalCalIntake, setTotalCalIntake] = useState({
    low: 0,
    high: 1,
  });
  const itemsPerPage = 10;

  const handleContinue = async () => {
    if (mealIndex < 2) {
      setScrollerToTop(mealIndex + 1);
      setCurrentPage(1);
      // Move to the next meal index
      setMealIndex(mealIndex + 1);
    } else {
      // Transform the meal details into the required structure
      const formatMealDetails = (mealArray) => {
        return mealArray.map((item) => ({
          mealItemId: item.foodId,
          count: item.quantity,
        }));
      };

      const data = {
        breakfast: formatMealDetails(
          fitnessRecordState?.mealDetails?.breakfast || []
        ),
        lunch: formatMealDetails(fitnessRecordState?.mealDetails?.lunch || []),
        dinner: formatMealDetails(
          fitnessRecordState?.mealDetails?.dinner || []
        ),
        clientId: clientID,
      };

      try {
        if (mealID) {
          // Update existing meal plan
          if (reqID) {
            await getApi()
              .put(`/meal-plan/by-trainer/${mealID}`, data)

              .then(() => {
                popAlert('Success!', 'Meal plan updated', 'success', 'Ok').then(
                  navigate(`/client/${reqID}`)
                );
              });
          } else {
            const mealData = {
              breakfast: data.breakfast,
              lunch: data.lunch,
              dinner: data.dinner,
            };
            await getApi()
              .put(
                `/meal-plan/by-trainer-for-external-clients/${clientID}`,
                mealData
              )
              .then(() => {
                popAlert('Success!', 'Meal plan updated', 'success', 'Ok').then(
                  navigate(`/external-clients`)
                );
              });
          }
        } else {
          // Create new meal plan
          if (reqID) {
            await getApi()
              .post('/meal-plan/by-trainer', data)
              .then(() => {
                popAlert('Success!', 'Meal plan created', 'success', 'Ok').then(
                  () => {
                    navigate(`/client/${reqID}`);
                  }
                );
              });
          } else if (clientID !== 'new') {
            await getApi()
              .post('/meal-plan/by-trainer-for-external-clients', data)
              .then(() => {
                popAlert('Success!', 'Meal plan created', 'success', 'Ok').then(
                  () => {
                    navigate(`/external-clients`);
                  }
                );
              });
          } else {
            navigate(`/pricing`);
          }
        }
      } catch (err) {
        // Handle error
        popAlert(
          'Error!',
          err?.response?.data?.message || 'An error occurred',
          'error',
          'ok'
        );
      }
    }
  };
  //
  const handleBack = () => {
    if (mealIndex > 0) {
      setMealIndex(mealIndex - 1);
      setScrollerToTop(mealIndex - 1);
      setCurrentPage(1);
    }
  };
  //
  const handleFoodStep1 = (foodId, stepCount) => {
    const food = foods.find((food) => food._id === foodId);
    dispatch(
      finessRecordsActions.updateMealFood({
        meal: meals[mealIndex],
        foodId,
        quantity: parseInt(stepCount),
        unitCalories: food?.calPerUnit,
        unitAmount: food?.unitAmount,
      })
    );
  };
  //
  const isFoodInMealDetails = (foodId) => {
    const mealType = meals[mealIndex];
    if (!fitnessRecordState.mealDetails[mealType]) {
      return null;
    }
    // Find the food item in the specified meal array
    const mealArray = fitnessRecordState.mealDetails[mealType];
    const foodItem = mealArray.find((item) => item.foodId === foodId);
    return foodItem || null;
  };
  //
  useEffect(() => {
    const source = axios.CancelToken.source();
    getApi()
      .get('/meal-plan/meal-items')
      .then((res) => {
        setFoods(res?.data?.data);
      })
      .catch((err) =>
        popAlert('Error!', err?.response?.data?.message, 'error', 'ok')
      );
    return () => {
      source.cancel();
    };
  }, [clientID]);
  //
  useEffect(() => {
    const fetchMealPlan = async () => {
      let response;
      try {
        if (reqID) {
          response = await getApi().get(`/meal-plan/by-trainer/${clientID}`);
        } else if (clientID !== 'new') {
          response = await getApi().get(
            `/meal-plan/by-trainer-for-external-clients/${clientID}`
          );
        }
        if (response?.data?.data) {
          const mealPlan = response?.data?.data;
          setMealId(response?.data?.data?._id);

          // Process breakfast items
          mealPlan.breakfast.forEach((item) => {
            const food = foods.find((f) => f._id === item.mealItemId._id);
            if (food) {
              dispatch(
                finessRecordsActions.updateMealFood({
                  meal: 'breakfast',
                  foodId: item.mealItemId._id,
                  quantity: item.count,
                  unitCalories: food.calPerUnit,
                  unitAmount: food.unitAmount,
                })
              );
            }
          });

          // Process lunch items
          mealPlan.lunch.forEach((item) => {
            const food = foods.find((f) => f._id === item.mealItemId._id);
            if (food) {
              dispatch(
                finessRecordsActions.updateMealFood({
                  meal: 'lunch',
                  foodId: item.mealItemId._id,
                  quantity: item.count,
                  unitCalories: food.calPerUnit,
                  unitAmount: food.unitAmount,
                })
              );
            }
          });

          // Process dinner items
          mealPlan.dinner.forEach((item) => {
            const food = foods.find((f) => f._id === item.mealItemId._id);
            if (food) {
              dispatch(
                finessRecordsActions.updateMealFood({
                  meal: 'dinner',
                  foodId: item.mealItemId._id,
                  quantity: item.count,
                  unitCalories: food.calPerUnit,
                  unitAmount: food.unitAmount,
                })
              );
            }
          });
        } else if (clientID !== 'new') {
          dispatch(finessRecordsActions.resetMeals());
        }
      } catch (err) {
        console.log(err);
        console.error('Failed to fetch meal', err);
        dispatch(finessRecordsActions.resetMeals());
        // popAlert('Error!', 'Failed to fetch emal data', 'error', 'ok');
      } finally {
        // setLoading(false);
      }
    };

    fetchMealPlan();
  }, [clientID, foods]);
  //
  const handleCaloryRequirementChaneges = (e, type) => {
    const perMealRequirement = 200;
    const totalLowerLimit =
      type === 'totalLowerLimit'
        ? e
        : fitnessRecordState?.caloryRequirements?.totalLowerLimit;
    const totalUpperLimit =
      type === 'totalUpperLimit'
        ? e
        : fitnessRecordState?.caloryRequirements?.totalUpperLimit;

    dispatch(
      finessRecordsActions.updateCaloriesRequirenment({
        perMealRequirement,
        totalLowerLimit,
        totalUpperLimit,
      })
    );
  };
  //
  useEffect(() => {
    const source = axios.CancelToken.source();
    //
    if (reqID) {
      getApi()
        .get(`/trainer-request/${reqID}`)
        .then((res) => setClient(res?.data?.data))
        .catch((err) => console.error(err?.response?.data?.message));
    } else if (clientID !== 'new') {
      getApi()
        .get(`/external-client/${clientID}`)
        .then((res) => {
          const client = res.data?.data;
          switch (res.data?.data?.goal) {
            case 'WeightGain':
              setTotalCalIntake({
                low: 250,
                high: 500,
              });
            case 'fatLoss':
              setTotalCalIntake({
                low: 250,
                high: 500,
              });
            case 'leanGain':
              setTotalCalIntake({
                low: 100,
                high: 50,
              });
            case 'weightLoss':
              setTotalCalIntake({
                low: 500,
                high: 1000,
              });

            default:
              setTotalCalIntake({
                low: 250,
                high: 500,
              });
          }
          setClient(client);
        })
        .catch((err) => console.error(err?.response?.data?.message));
    }

    return () => {
      source.cancel();
    };
  }, [reqID, clientID]);
  // Filter the foods based on the search term
  const filteredFoods = foods
    ?.filter((food) =>
      food.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      // Prioritize selected foods
      const quantityA = isFoodInMealDetails(a._id)?.quantity || 0;
      const quantityB = isFoodInMealDetails(b._id)?.quantity || 0;
      return quantityB - quantityA;
    });

  const indexOfLastFood = currentPage * itemsPerPage;
  const indexOfFirstFood = indexOfLastFood - itemsPerPage;
  const currentFoods = filteredFoods.slice(indexOfFirstFood, indexOfLastFood);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage, scrollerToTop]);

  return (
    <div>
      <Container>
        <Typography
          variant="h5"
          sx={{
            marginBottom: 1,
            color: '#a0e220',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Select Your Client's Meals
        </Typography>
        <Container
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            margin: 0,
            padding: 0,
          }}
        >
          <Container
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              borderRadius: 10,
              width: '100%',
              margin: 0,
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            <div
              style={{
                backgroundColor: '#373736',
                marginRight: '1rem',
                display: 'flex',
                flexDirection: 'row',
                borderRadius: 10,
                width: '25vw',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  borderRight: '2px solid #4d4c4c',
                  width: '100%',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ textAlign: 'center' }}
                  color="white"
                >
                  BMR
                </Typography>
                <Typography
                  variant="h7"
                  sx={{ textAlign: 'center' }}
                  color="#a0e220"
                >
                  {client?.calculatedMetrics?.bmr?.toFixed(2) || 0} Cal
                </Typography>
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ textAlign: 'center' }}
                  color="white"
                >
                  DCI
                </Typography>
                <Typography
                  variant="h7"
                  sx={{ textAlign: 'center' }}
                  color="#a0e220"
                >
                  {client?.calculatedMetrics?.dci?.toFixed(2) || 0} Cal
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: '#373736',
                  borderRight: '2px solid #4d4c4c',
                  borderRadius: '10px 0px 0px 10px',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ p: 1, textAlign: 'center' }}
                  color="white"
                >
                  {meals[mealIndex].charAt(0).toUpperCase() +
                    meals[mealIndex].slice(1)}{' '}
                  Calories
                </Typography>
                <Container
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    margin: 0,
                  }}
                >
                  <div
                    style={{
                      // perMealLowerLimit > breakfastCalories = color yellow
                      // perMealLowerLimit  < breakfastCalories <  perMealUpperLimit = color green
                      // perMealUpperLimit < breakfastCalories = color red
                      backgroundColor:
                        fitnessRecordState?.[`${meals[mealIndex]}Calories`] <
                        fitnessRecordState?.caloryRequirements
                          ?.perMealLowerLimit
                          ? '#FFEDB1'
                          : fitnessRecordState?.caloryRequirements
                              ?.perMealLowerLimit <=
                              fitnessRecordState?.[
                                `${meals[mealIndex]}Calories`
                              ] &&
                            fitnessRecordState?.[
                              `${meals[mealIndex]}Calories`
                            ] <=
                              fitnessRecordState?.caloryRequirements
                                ?.perMealUpperLimit
                          ? '#a0e220'
                          : '#FF6767',
                      padding: '0.5rem 2rem',
                      margin: '1rem',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: 'black', fontWeight: 'bold' }}
                    >
                      {Number(
                        fitnessRecordState?.[`${meals[mealIndex]}Calories`]
                      ).toFixed(2)}
                    </Typography>
                  </div>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ backgroundColor: '#4d4c4c' }}
                  />
                  <div
                    style={{
                      border: '1px solid white',
                      padding: '0.5rem 2rem',
                      margin: '1rem',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: 'white', fontWeight: 'bold' }}
                    >
                      {Number(
                        fitnessRecordState?.caloryRequirements
                          ?.perMealLowerLimit
                      )?.toFixed(2)}
                    </Typography>
                  </div>
                  <div
                    style={{
                      border: '1px solid white',
                      padding: '0.5rem 2rem',
                      margin: '1rem',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: 'white', fontWeight: 'bold' }}
                    >
                      {Number(
                        fitnessRecordState?.caloryRequirements
                          ?.perMealUpperLimit
                      )?.toFixed(2)}
                    </Typography>
                  </div>
                </Container>
              </div>
              <div
                style={{
                  backgroundColor: '#373736',
                  borderRadius: '0px 10px 10px 0px',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ p: 1, textAlign: 'center' }}
                  color="white"
                >
                  Total Calorie Intake
                </Typography>
                <Container
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    margin: 0,
                  }}
                >
                  <div
                    style={{
                      backgroundColor:
                        fitnessRecordState?.totalCalories <
                        fitnessRecordState?.caloryRequirements?.totalLowerLimit
                          ? '#FFEDB1'
                          : fitnessRecordState?.caloryRequirements
                              ?.totalLowerLimit <=
                              fitnessRecordState?.totalCalories &&
                            fitnessRecordState?.totalCalories <=
                              fitnessRecordState?.caloryRequirements
                                ?.totalUpperLimit
                          ? '#a0e220'
                          : '#FF6767',
                      padding: '0.5rem 2rem',
                      margin: '1rem',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: 'black', fontWeight: 'bold' }}
                    >
                      {Number(fitnessRecordState?.totalCalories).toFixed(2)}
                    </Typography>
                  </div>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ backgroundColor: '#4d4c4c' }}
                  />
                  <div
                    style={{
                      border: '1px solid white',
                      padding: '0.3rem',
                      margin: '1rem',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {/* NOTE: should be discuss about dispaly of the value  */}
                    <input
                      style={{
                        backgroundColor: '#373736',
                        color: 'white',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        textAlign: 'center',
                      }}
                      type="number"
                      value={
                        fitnessRecordState?.caloryRequirements?.totalLowerLimit
                      }
                      onChange={(e) => {
                        handleCaloryRequirementChaneges(
                          e.target.value,
                          'totalLowerLimit'
                        );
                      }}
                    />
                  </div>
                  <div
                    style={{
                      border: '1px solid white',
                      padding: '0.3rem',
                      margin: '1rem',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <input
                      style={{
                        backgroundColor: '#373736',
                        color: 'white',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        textAlign: 'center',
                      }}
                      type="number"
                      value={
                        fitnessRecordState?.caloryRequirements?.totalUpperLimit
                      }
                      onChange={(e) => {
                        handleCaloryRequirementChaneges(
                          e.target.value,
                          'totalUpperLimit'
                        );
                      }}
                    />
                  </div>
                </Container>
              </div>
            </div>
          </Container>
        </Container>
        <Container
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: 0,
            flexDirection: 'column',
          }}
        >
          {/* Search Bar */}
          <Container
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '1rem 0',
              padding: 0,
              flexDirection: 'row',
            }}
          >
            <Typography
              variant="h6"
              sx={{ p: 1 }}
              color="white"
              fontWeight={'bold'}
            >
              {meals[mealIndex].charAt(0).toUpperCase() +
                meals[mealIndex].slice(1)}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                backgroundColor: '#f1f1f1',
                borderRadius: 5,
                padding: '0.5rem 1rem',
                width: '30%',
                alignItems: 'center',
              }}
            >
              <SearchIcon />
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search foods..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>
          </Container>
          {currentFoods.map((food) => {
            const foodDetails = isFoodInMealDetails(food._id);
            const quantity = foodDetails ? foodDetails.quantity : 0;
            return (
              <Box
                key={food._id}
                sx={{
                  marginTop: '1rem',
                }}
              >
                <MealItemCard
                  food={food}
                  onFoodStep={handleFoodStep1}
                  quantity={quantity}
                />
              </Box>
            );
          })}
          <div
            style={{
              marginTop: '1rem',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span style={{ margin: '0 1rem', color: 'white' }}>
              Page {currentPage}
            </span>
            <Button
              variant="outlined"
              size="small"
              onClick={() =>
                setCurrentPage((prev) =>
                  prev < Math.ceil(filteredFoods.length / itemsPerPage)
                    ? prev + 1
                    : prev
                )
              }
              disabled={
                currentPage === Math.ceil(filteredFoods.length / itemsPerPage)
              }
            >
              Next
            </Button>
          </div>
        </Container>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '3rem' }}>
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              mt: 6,
              backgroundImage: '#a0e220', // add background image
              color: 'white',
              '&:hover': {
                backgroundImage: '#a0e220', // change background image on hover
              },
              fontWeight: 'bold',
              padding: '0.7rem 2rem',
              width: '5%',
            }}
            onClick={handleBack}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                color: 'black',
              }}
            >
              <ArrowBackIcon color="secondary" />
            </Typography>
          </Button>
          <Button
            variant="contained"
            sx={{
              mt: 6,
              backgroundImage: '#a0e220', // add background image
              color: 'white',
              '&:hover': {
                backgroundImage: '#a0e220', // change background image on hover
              },
              fontWeight: 'bold',
              padding: '0.7rem 2rem',
              width: '20%',
            }}
            onClick={handleContinue}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                color: 'black',
              }}
            >
              continue <ArrowNextIcon />
            </Typography>
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default MealSelector;
