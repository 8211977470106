import React, { useState } from 'react';
import { Container } from '../../../components/commonStyle';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import Search from '../../../components/search/Search';
import Loader from '../../../components/Loader/Loader';
import ClientCard from '../../../components/client-cards/ClientCard';
import GroupsIcon from '@mui/icons-material/Groups';
import { useNavigate } from 'react-router-dom';
import ExternalClientCard from '../../../components/client-cards/ExternalClientCard';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useDispatch, useSelector } from 'react-redux';
import { popAlert } from '../../../utils/alert';
import { authActions } from '../../../store/authSlice';

const ClientDashboardContainer = ({ type, clients, isLoading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subscription = useSelector((state) => state.auth.subscription);
  const [keyword, setKeyword] = useState('');
  const isMobile = useMediaQuery('(max-width:660px)');

  const handleSearch = (value) => {
    setKeyword(value);
  };
  const handleEmptySearchInput = () => {
    setKeyword('');
  };

  let filteredClients = [];
  if (type === 'External') {
    filteredClients = clients.filter((client) =>
      client.name?.toLowerCase().includes(keyword?.toLowerCase())
    );
  } else
    filteredClients = clients.filter((client) =>
      client.fullName?.toLowerCase().includes(keyword?.toLowerCase())
    );

  const handleAddNewClient = () => {
    // If no subscription and client length is not 0, navigate to pricing with popAlert
    if (!subscription && clients?.length > 0) {
      popAlert(
        'Subscription Required',
        'You need an active subscription to add more clients.',
        'warning',
        'OK'
      ).then(() => {
        navigate('/pricing');
      });
    }
    // If subscription is available but client count has reached the limit, navigate to pricing
    else if (
      clients?.length ===
        subscription?.trainerSubscriptionPlanId?.memberCount &&
      subscription?.status === 'Active'
    ) {
      popAlert(
        'Client Limit Reached',
        'You have reached the maximum number of clients for your plan.',
        'warning',
        'OK'
      ).then(() => {
        navigate('/pricing');
      });
    }
    // If no subscription and client length is 0, allow adding a new client
    else if (clients?.length === 0 && !subscription) {
      dispatch(authActions.setIsFirstExtenalClients(true));
      navigate('/add-external-client');
    }
    // Allow adding a new client for other cases
    else {
      navigate('/add-external-client');
    }
  };

  return (
    <div>
      <Container>
        <Typography
          variant="h5"
          sx={{
            marginBottom: 4,
            color: '#a0e220',
            fontWeight: 'bold',
          }}
        >
          {`${type === 'FitnessGuru' ? 'Fitness Guru' : 'External'} Clients`}
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: isMobile ? 'flex-start' : 'space-between',
            padding: isMobile ? 0 : 0,
          }}
        >
          <div style={{ width: isMobile ? '90vw' : '60vw' }}>
            <Search
              placeholderText={'Search all clients'}
              onSearch={handleSearch}
              onEmptySearchInput={handleEmptySearchInput}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1.5rem',
              gap: '1rem',
            }}
          >
            <div>
              <Typography
                variant={isMobile ? 'body1' : 'h6'}
                sx={{ textAlign: 'center' }}
                color="gray"
              >
                Total clients
              </Typography>
              {type === 'External' && (
                <Box
                  onClick={() => navigate('/pricing')}
                  sx={{ cursor: 'pointer' }}
                >
                  <Typography
                    variant={isMobile ? 'body2' : 'h7'}
                    sx={{ textAlign: 'center' }}
                    color="#a0e220"
                  >
                    + Add more
                  </Typography>
                </Box>
              )}
            </div>
            <div
              style={{
                backgroundColor: '#373736',
                marginRight: isMobile ? 0 : '1rem',
                display: 'flex',
                flexDirection: 'row',
                borderRadius: 10,
                width: isMobile ? '18vw' : '6vw',
                height: isMobile ? '3rem' : '3.5rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  borderRight:
                    type === 'External' ? '2px solid #4d4c4c' : 'none',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  variant={isMobile ? 'body2' : 'h6'}
                  sx={{ textAlign: 'center' }}
                  color="white"
                >
                  {clients?.length || 0}
                </Typography>
              </div>
              {type === 'External' && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant={isMobile ? 'body2' : 'h6'}
                    sx={{ textAlign: 'center' }}
                    color="#a0e220"
                  >
                    {subscription?.trainerSubscriptionPlanId?.memberCount || 0}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>

        <Grid
          container
          spacing={5}
          justifyContent={type === 'FitnessGuru' ? 'flex-start' : 'flex-end'}
          alignItems="center"
          my={3}
        >
          {isLoading ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                padding: '8rem',
              }}
            >
              <Loader />
            </div>
          ) : (
            <>
              {type === 'External' && (
                <Button
                  size={isMobile ? 'small' : ''}
                  variant="outlined"
                  startIcon={<PersonAddIcon color="secondary" />}
                  onClick={() => handleAddNewClient()}
                >
                  <Typography
                    variant={isMobile ? 'body2' : 'h7'}
                    color={'white'}
                  >
                    Add new member
                  </Typography>
                </Button>
              )}

              {filteredClients &&
                filteredClients.map((client) => {
                  return type === 'FitnessGuru' ? (
                    <Grid item xs={12} md={4} lg={4} xl={2} key={client?._id}>
                      <ClientCard client={client} />
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={12} lg={12} key={client?._id}>
                      <ExternalClientCard client={client} />
                    </Grid>
                  );
                })}
              {clients?.length === 0 && (
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: isMobile ? '90%' : '100%',
                    gap: isMobile ? '0.5rem' : '1rem',
                    pb: '5rem',
                  }}
                >
                  <GroupsIcon
                    color="secondary"
                    sx={{ fontSize: isMobile ? '8rem' : '15rem' }}
                  />
                  <Typography
                    variant={isMobile ? 'body1' : 'h6'}
                    color={'white'}
                  >
                    {type === 'FitnessGuru'
                      ? 'You have no clients yet'
                      : 'You have not added any clients yet'}
                  </Typography>

                  {type !== 'FitnessGuru' && (
                    <Button
                      variant="contained"
                      sx={{
                        backgroundImage: '#a0e220',
                        color: 'black',
                        '&:hover': {
                          backgroundImage: '#a0e220',
                        },
                        fontWeight: 'bold',
                        padding: isMobile ? '1.5rem 2rem' : '0.7rem 3rem',
                        borderRadius: 4,
                        ml: 2,
                        height: isMobile ? '2rem' : '3.4rem',
                      }}
                      type="submit"
                      onClick={() => handleAddNewClient()}
                    >
                      <Typography variant={isMobile ? 'body2' : 'h7'}>
                        Add clients
                      </Typography>
                    </Button>
                  )}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default ClientDashboardContainer;
