import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { deletePopAlert, popAlert } from '../../utils/alert';
import { getApi } from '../../utils/axios';

const PackageCard = React.memo(
  ({ index, setPkg, packageData, setDeletePackage }) => {
    const [name, setName] = useState('');
    const [period, setPeriod] = useState(null);
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState(null);
    const [updating, setUpdating] = useState(false);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
      if (packageData) {
        setName(packageData.name || '');
        setPeriod(Number(packageData.trainingPeriod) || null);
        setDescription(packageData.packageBrief || '');
        setPrice(Number(packageData.price) || null);
      }
    }, [packageData]);

    const setNewPackage = () => {
      setPkg(index, {
        name,
        trainingPeriod: period,
        packageBrief: description,
        price,
      });
    };

    const updatePackage = async () => {
      setUpdating(true);
      try {
        const response = await getApi().put(
          `/trainer-package/${packageData?._id}`,
          {
            name,
            trainingPeriod: period,
            packageBrief: description,
            price,
          }
        );
        popAlert('Success!', response?.data?.message, 'success', 'Ok');
      } catch (err) {
        setUpdating(false);
        popAlert('Error!', err.response?.data?.message, 'error', 'OK');
      } finally {
        setUpdating(false);
      }
    };

    const deletePackage = async () => {
      setDeleting(true);
      try {
        const confirmed = await deletePopAlert(
          'Are you sure?',
          "You won't be able to revert this!",
          'warning',
          'Yes, delete it!',
          'Package will be deleted.'
        );
        if (confirmed) {
          const response = await getApi().delete(
            `/trainer-package/${packageData._id}`
          );
          popAlert('Success!', response?.data?.message, 'success', 'Ok');
          setDeletePackage(packageData._id);
        }
      } catch (err) {
        popAlert('Error!', err.response?.data?.message, 'error', 'OK');
      } finally {
        setDeleting(false);
      }
    };

    return (
      <div
        style={{
          backgroundColor: '#363636',
          padding: '1.5rem 3rem',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          color: '#D9D9D9',
          marginBottom: '2rem',
        }}
      >
        <form>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <InputLabel
              htmlFor="name"
              sx={{
                color: 'white',
                mt: 2,
                mb: 1,
                fontSize: '1.3rem',
                width: '250px',
              }}
            >
              Package Name
            </InputLabel>
            <OutlinedInput
              value={name}
              placeholder="Enter Package Name"
              id="name"
              name="name"
              required
              sx={{
                borderRadius: 4,
                border: '1px solid #707070',
                width: '100%',
                marginBottom: 2,
                backgroundColor: '#373736',
                '& input::placeholder': { color: '#7b7b7b' },
                '& input': { color: '#7b7b7b' },
              }}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <InputLabel
              htmlFor="period"
              sx={{
                color: 'white',
                mt: 2,
                mb: 1,
                fontSize: '1.3rem',
                width: '250px',
              }}
            >
              Training Period
            </InputLabel>
            <OutlinedInput
              value={period}
              placeholder="Enter Training Period"
              type="number"
              id="period"
              endAdornment={
                <InputAdornment position="end">
                  <Typography sx={{ color: 'white' }}>Month</Typography>
                </InputAdornment>
              }
              sx={{
                borderRadius: 4,
                border: '1px solid #707070',
                width: '100%',
                marginBottom: 2,
                backgroundColor: '#373736',
                '& input::placeholder': { color: '#7b7b7b' },
                '& input': { color: '#7b7b7b' },
              }}
              onChange={(e) => setPeriod(e.target.value)}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <InputLabel
              sx={{
                color: 'white',
                mt: 2,
                mb: 1,
                fontSize: '1.3rem',
                width: '250px',
              }}
            >
              Package Brief
            </InputLabel>
            <OutlinedInput
              value={description}
              multiline
              rows={4}
              placeholder="Write your package details explaining what you offer…"
              id="description"
              name="description"
              required
              sx={{
                borderRadius: 4,
                border: '1px solid #707070',
                width: '100%',
                marginBottom: 2,
                backgroundColor: '#373736',
                color: '#7b7b7b',
                '& input::placeholder': { color: '#7b7b7b' },
                '& input': { color: '#7b7b7b' },
              }}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <InputLabel
              htmlFor="price"
              sx={{
                color: 'white',
                mt: 2,
                mb: 1,
                fontSize: '1.3rem',
                width: '250px',
              }}
            >
              Price
            </InputLabel>
            <OutlinedInput
              value={price}
              placeholder="Enter Price"
              type="number"
              id="price"
              endAdornment={
                <InputAdornment position="end">
                  <Typography sx={{ color: 'white' }}>LKR</Typography>
                </InputAdornment>
              }
              sx={{
                borderRadius: 4,
                border: '1px solid #707070',
                width: '100%',
                marginBottom: 2,
                backgroundColor: '#373736',
                '& input::placeholder': { color: '#7b7b7b' },
                '& input': { color: '#7b7b7b' },
              }}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {!Boolean(packageData._id) ? (
              <Button
                variant="outlined"
                size="small"
                onClick={() => setNewPackage()}
                disabled={Object.keys(packageData).length !== 0}
              >
                Add package
              </Button>
            ) : (
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => updatePackage()}
                  sx={{ marginRight: 2 }}
                >
                  {updating ? <CircularProgress size={24} /> : 'Update package'}
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={() => deletePackage()}
                >
                  {deleting ? <CircularProgress size={24} /> : 'Delete package'}
                </Button>
              </Box>
            )}
          </div>
        </form>
      </div>
    );
  }
);

export default PackageCard;
