import React, { useEffect, useState } from 'react';
import ClientDashboardContainer from '../containers/ClientDashboardContainer';
import axios from 'axios';
import { popAlert } from '../../../utils/alert';
import { getApi } from '../../../utils/axios';

const FitnessGuruClientsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [clientsRequests, setClientsRequests] = useState([]);

  const props = {
    type: 'FitnessGuru',
    clients: clientsRequests,
    isLoading: isLoading,
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    setIsLoading(true);
    //
    getApi()
      .get('/trainer-request')
      .then((res) => {
        setClientsRequests(res?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        popAlert('Error!', err?.response?.data?.message, 'error', 'ok');
      });
    return () => {
      source.cancel();
    };
  }, []);
  return <ClientDashboardContainer {...props} />;
};

export default FitnessGuruClientsPage;
