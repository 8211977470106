import axios from 'axios';
import store from '../store/store';

const NewRequest = axios.create({
  baseURL: 'https://fitness-guru.onrender.com',
  headers: {
    'Content-Type': 'application/json',
  },
});
//
NewRequest.interceptors.request.use((config) => {
  const state = store.getState();
  const tokens = state.auth.tokens;
  if (tokens && tokens.refreshToken) {
    config.headers['Authorization'] = `Bearer ${tokens.refreshToken}`;
  }
  return config;
});

export async function refreshAccessTokenFn() {
  const { data } = await NewRequest.post('/refreshToken');
  return data;
}
// Function to format the date
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', { month: 'short', year: 'numeric' });
};
//
// Function to capitalize the first letter of the word
export const capitalizeWords = (fullName) => {
  if (!fullName) return '';
  return fullName.charAt(0).toUpperCase() + fullName.slice(1);
};
