import React from 'react';
import LoaderJson from '../../assets/loader.json';
import { useLottie } from 'lottie-react';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoaderJson,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
  style: {
    height: 100,
    width: 100,
  },
};

const Loader = () => {
  const { View } = useLottie(defaultOptions);

  const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40vh', // Full screen height
  };

  return <div style={loaderContainerStyle}>{View}</div>;
};

export default Loader;
