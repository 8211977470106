import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Container } from '../../components/commonStyle';
import PricingCard from '../../components/price-plans/PricingCard';
import premiumCard from '../../assets/pricing-card-1.svg';
import standardCard from '../../assets/pricing-card-2.svg';
import basicCard from '../../assets/pricing-card-3.svg';
import { getApi, getApiForFormData } from '../../utils/axios';
import Loader from '../../components/Loader/Loader';
import { popAlert } from '../../utils/alert';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/authSlice';
import { clientActions } from '../../store/clientSlice';
import { finessRecordsActions } from '../../store/finessRecordSlice';
import { resetWorkout } from '../../store/workoutSlice';
import { useNavigate } from 'react-router-dom';

const predefinedPlans = [
  {
    title: 'Basic',
    color: '#a00',
    cardBackground: basicCard,
  },
  {
    title: 'Standard',
    color: '#2a426c',
    cardBackground: standardCard,
  },
  {
    title: 'Premium',
    color: '#6aaa7d',
    cardBackground: premiumCard,
  },
];

const PricingPage = () => {
  const navigate = useNavigate();
  const externalClientState = useSelector((state) => state.client);
  const workoutState = useSelector((state) => state.workout?.days);
  const fitnessRecordsState = useSelector((state) => state.fitnessRecords);
  const isFirstExternalClient = useSelector(
    (state) => state.auth.isFirstExternalClient
  );
  const dispatch = useDispatch();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingAlert, setLoadingAlert] = useState(false);

  const getPackageInfo = async () => {
    try {
      const response = await getApi().get('/trainer-subscription-plan');
      const fetchedPlans = response?.data?.data;

      const combinedPlans = fetchedPlans?.map((fetchedPlan) => {
        const predefinedPlan = predefinedPlans.find(
          (plan) => plan.title === fetchedPlan?.name
        );
        return {
          ...fetchedPlan,
          color: predefinedPlan?.color,
          cardBackground: predefinedPlan?.cardBackground,
          price: `LKR ${fetchedPlan.price}`,
        };
      });

      setPlans(combinedPlans);
    } catch (err) {
      console.error('Failed to fetch package info:', err);
      popAlert('Error!', err.response?.data?.message, 'error', 'OK');
    } finally {
      setLoading(false);
    }
  };

  const onSelectPricingPlan = async (id) => {
    try {
      setLoadingAlert(true);

      // Select the pricing plan
      const response = await getApi().post('/trainer-subscription', {
        trainerSubscriptionPlanId: id,
      });
      // Handle successful subscription
      dispatch(authActions.setSubscription(response?.data?.data));

      if (isFirstExternalClient) {
        // Proceed to add client
        const clientResponse = await getApiForFormData().post(
          '/external-client',
          externalClientState?.client
        );
        const clientID = clientResponse?.data?.data?._id;

        // Add workout
        await handleAddWorkout(clientID);
        // Add meal plan
        await handleAddMealPlan(clientID);

        // All operations are successful
        popAlert(
          'Success!',
          'External client added successfully!',
          'success',
          'Ok'
        );
      } else {
        popAlert(
          'Success!',
          'Subscription updated successfully!',
          'success',
          'Ok'
        );
      }
      dispatch(authActions.setIsFirstExtenalClients(false));
      dispatch(clientActions.resetClient());
      dispatch(clientActions.resetClient());
      dispatch(finessRecordsActions.resetMeals());
      dispatch(resetWorkout());
      navigate('/external-clients');
    } catch (err) {
      popAlert(
        'Error!',
        err.response?.data?.message || 'An error occurred',
        'error',
        'OK'
      );
    } finally {
      setLoadingAlert(false);
    }
  };

  const handleAddWorkout = async (clientID) => {
    try {
      const exerciseDays = workoutState.map((day) => ({
        day: parseInt(day.day.replace('Day ', '')),
        exercises: day.exercises.map((exercise) => ({
          order: exercise.order,
          exercise: exercise.exercise.id._id
            ? exercise.exercise.id._id
            : exercise.exercise.id,
          sets: exercise.sets,
          reps: exercise.reps,
          rest: exercise.rest,
        })),
      }));

      const data = {
        clientId: clientID,
        exerciseDays,
      };

      await getApi().post('/workout/by-trainer-for-external-clients', data);
    } catch (err) {
      popAlert(
        'Error!',
        err?.response?.data?.message ||
          'An error occurred while creating the workout plan',
        'error',
        'ok'
      );
    }
  };

  const handleAddMealPlan = async (clientID) => {
    const formatMealDetails = (mealArray) => {
      return mealArray.map((item) => ({
        mealItemId: item.foodId,
        count: item.quantity,
      }));
    };

    const data = {
      breakfast: formatMealDetails(
        fitnessRecordsState?.mealDetails?.breakfast || []
      ),
      lunch: formatMealDetails(fitnessRecordsState?.mealDetails?.lunch || []),
      dinner: formatMealDetails(fitnessRecordsState?.mealDetails?.dinner || []),
      clientId: clientID,
    };

    try {
      await getApi().post('/meal-plan/by-trainer-for-external-clients', data);
    } catch (err) {
      popAlert(
        'Error!',
        err?.response?.data?.message ||
          'An error occurred while creating the meal plan',
        'error',
        'ok'
      );
    }
  };

  useEffect(() => {
    getPackageInfo();
  }, []);

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'center', pb: '5rem' }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ color: '#a0e220', fontWeight: 'bold' }}
        >
          Select Your Plan
        </Typography>
      </Box>
      <Grid container spacing={2} justifyContent="center">
        {loading || loadingAlert ? (
          <Loader />
        ) : (
          plans.map((plan, index) => (
            <Grid item key={index}>
              <PricingCard
                plan={plan}
                onSelectPricingPlan={onSelectPricingPlan}
              />
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
};

export default PricingPage;
