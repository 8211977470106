import { createSlice } from '@reduxjs/toolkit';

const clientSlice = createSlice({
  name: 'client',
  initialState: {
    clintType: '',
    client: {},
  },
  reducers: {
    setClient(state, action) {
      state.client = action.payload;
    },
    resetClient(state) {
      state.client = {};
    },
  },
});

export const clientActions = clientSlice.actions;

export default clientSlice;
