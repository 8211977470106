import React from 'react';
import img from '../../assets/apology.png';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Sorry = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2rem',
          flexDirection: 'column',
        }}
      >
        <img src={img} alt="img" style={{ width: '170px' }} />
        <div
          style={{
            color: '#FFFFFF',
            width: '90%',
            textAlign: 'center',
            fontWeight: 'lighter',
          }}
        >
          <div style={{ padding: '1rem' }}>
            <h1 style={{ fontWeight: 'lighter' }}>
              SORRY WE HAVE TO STOP YOU HERE <br />
              AS WE CARE ABOUT YOUR HEALTH.
            </h1>
          </div>

          <h2 style={{ fontWeight: 'lighter', padding: '1rem' }}>
            FITNESS GURU do not provide workout routines and meal plans for
            clients <br />
            who are having health issues or injuries.{' '}
          </h2>
          <h2 style={{ fontWeight: 'lighter', padding: '1rem' }}>
            We highly recommend you to get the help of our professional
            <br /> trainers to guide you through your fitness journey.
          </h2>
        </div>
        <div>
          <Button
            type="submit"
            variant="contained"
            sx={{
              padding: '12px 24px',
              backgroundImage: '#a0e220',
              color: 'white',
              '&:hover': {
                backgroundImage: '#a0e220',
              },
              borderRadius: 4,
              fontWeight: 'bold',
              width: '30rem',
              mt: 2,
            }}
            onClick={() => navigate('/trainers')}
          >
            Search Trainers
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Sorry;
