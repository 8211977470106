import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import PackageCard from '../../components/trainer-card/PackageCard';
import { getApi, getApiForFormData } from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
import { popAlert } from '../../utils/alert';
import CloseIcon from '@mui/icons-material/Close';
import { authActions } from '../../store/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUploadRounded';
import { Container } from '../../components/commonStyle';

const PackageDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth.user);
  const [packages, setPackages] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deletePackage, setDeletePackage] = useState('');
  useEffect(() => {
    const fetchTrainerData = async () => {
      setLoading(true);
      try {
        const trainerId = authState._id;

        // Fetch existing packages
        const packageResponse = await getApi().get(
          `/trainer-package/${trainerId}`
        );
        const existingPackages = packageResponse?.data?.data || [];
        setPackages(existingPackages);
      } catch (error) {
        popAlert('Error!', 'Failed to load trainer data.', 'error', 'OK');
      } finally {
        setLoading(false);
      }
    };

    fetchTrainerData();
  }, [deletePackage]);

  const setPkg = useCallback((index, pkgBody) => {
    setPackages((prevItems) => [
      ...prevItems.slice(0, index),
      { ...prevItems[index], ...pkgBody },
      ...prevItems.slice(index + 1),
    ]);
  }, []);

  const handleImageChange = (event) => {
    const files = event.target.files;
    const uploadedImages = [];

    for (let i = 0; i < files?.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onload = (event) => {
        uploadedImages.push({ src: event.target.result, file });

        if (uploadedImages.length === files.length) {
          setFiles((prevState) => [...prevState, ...uploadedImages]);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = (imageIndex) => {
    setFiles((prevState) =>
      prevState.filter((image, index) => index !== imageIndex)
    );
  };

  const handleSubmit = async () => {
    setLoading(true);

    const trainerId = authState._id;
    const formData = new FormData();
    const newPackages = [];
    const hasNewFiles = files.length > 0;

    files.forEach((file) => {
      if (file.file) {
        // Only append new files
        formData.append('files', file.file);
      }
    });

    try {
      // Fetch existing packages
      const existingPackagesResponse = await getApi().get(
        `/trainer-package/${trainerId}`
      );
      const existingPackages = existingPackagesResponse?.data?.data || [];

      // Identify valid new packages
      packages.forEach((pkg) => {
        const isExistingPackage = existingPackages.some(
          (existingPkg) => existingPkg.name === pkg.name
        );

        const isValidPackage =
          pkg.name &&
          pkg.trainingPeriod > 0 &&
          pkg.packageBrief &&
          pkg.price > 0;

        if (!isExistingPackage && isValidPackage) {
          newPackages.push(pkg);
        }
      });

      const hasNewPackages = newPackages.length > 0;

      // Call /trainer-package for each new and valid package
      if (hasNewPackages) {
        for (const newPackage of newPackages) {
          const packageBody = {
            name: newPackage.name,
            trainingPeriod: Number(newPackage.trainingPeriod),
            packageBrief: newPackage.packageBrief,
            price: Number(newPackage.price),
          };

          const packageResponse = await getApi().post(
            '/trainer-package',
            packageBody
          );

          const packageDetails = packageResponse?.data?.data;
          if (packageDetails) {
            popAlert(
              'Success!',
              packageResponse.data.message,
              'success',
              'Ok'
            ).then(() => {
              navigate('/trainer-profile');
            });
          }
        }
      }

      // Call /trainer/upload-portfolios if new portfolio images were added
      if (hasNewFiles) {
        const portfolioResponse = await getApiForFormData().post(
          '/trainer/upload-portfolios',
          formData
        );
        const careerDetails = portfolioResponse?.data?.data;

        if (careerDetails) {
          dispatch(authActions.setUser(careerDetails));
          popAlert(
            'Success!',
            portfolioResponse.data.message,
            'success',
            'Ok'
          ).then(() => {
            navigate('/trainer-profile');
          });
        }
      }
    } catch (err) {
      if (err.response?.data?.message) {
        popAlert('Error!', err.response.data.message, 'error', 'OK');
      } else {
        popAlert('Error!', 'Something went wrong', 'error', 'OK');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteImage = async (portfolioFileKey) => {
    try {
      const response = await getApi().delete('/trainer/delete-portfolio', {
        data: {
          fileKey: portfolioFileKey,
        },
      });

      if (response?.data?.data) {
        dispatch(authActions.setUser(response?.data?.data));
        popAlert('Success!', response.data.message, 'success', 'Ok');
      }
    } catch (err) {
      console.error(err);
      popAlert(
        'Error!',
        err?.response?.data?.message || 'An error occurred',
        'error',
        'OK'
      );
    } finally {
      // setLoading(false);
    }
  };

  return (
    <Container>
      <h1 style={{ textAlign: 'center', color: '#a0e220' }}>Package Details</h1>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid #818180',
          borderRadius: '10px',
          padding: '1rem 5rem',
          margin: '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            color: 'white',
          }}
        >
          <h2>Create Packages </h2>
          <Button
            style={{
              color: '#a0e220',
              border: '1px solid #a0e220',
              borderRadius: '10px',
              padding: '0rem 2rem ',
              height: '40px',
              margin: '15px 0px 10px 0px',
            }}
            onClick={() => setPackages([...packages, {}])} // Allow adding a new empty package
          >
            Add New
          </Button>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {packages.length === 0 && (
            <PackageCard index={0} setPkg={setPkg} packageData={{}} />
          )}
          {packages.map((pkg, index) => (
            <PackageCard
              key={index}
              index={index}
              setPkg={setPkg}
              packageData={pkg}
              setDeletePackage={setDeletePackage}
            />
          ))}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid #818180',
          borderRadius: '10px',
          padding: '1rem 5rem',
          margin: '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <h2 style={{ color: 'white' }}>Upload your Portfolio</h2>
          <Button
            variant="text"
            component="label"
            color="success"
            sx={{
              fontWeight: 'bold',
              fontSize: '1.3rem',
              textTransform: 'capitalize',
            }}
            endIcon={<DriveFolderUploadRoundedIcon />}
          >
            Upload photos
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              onChange={handleImageChange}
            />
          </Button>
        </div>
        <div
          style={{
            border: '1px solid #818180',
            padding: '1rem',
            borderRadius: '10px',
            marginBottom: '2rem',
          }}
        >
          <div
            style={{
              border: 'dotted',
              borderColor: '#818180',
              height: '200px',
              borderRadius: '10px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {files.length > 0 || authState?.portfolioFileData.length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  overflowX: 'scroll',
                  scrollbarWidth: 'none',
                  '-ms-overflow-style': 'none',
                }}
              >
                {files.map((image, index) => (
                  <Box key={index} position="relative">
                    <img
                      src={image.src}
                      alt={`upload-${index}`}
                      style={{
                        objectFit: 'cover',
                        width: '150px',
                        height: '150px',
                        borderRadius: '10px',
                      }}
                    />
                    <Button
                      onClick={() => handleRemoveImage(index)}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        minWidth: 'auto',
                        padding: 0,
                        borderRadius: '50%',
                        background: '#333',
                        color: 'white',
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Button>
                  </Box>
                ))}

                {authState?.portfolioFileData.map((portfolio, index) => (
                  <Box key={index} position="relative">
                    <img
                      src={portfolio.portfolioFileUrl}
                      alt={`upload-${index}`}
                      style={{
                        objectFit: 'cover',
                        width: '150px',
                        height: '150px',
                        borderRadius: '10px',
                      }}
                    />
                    <Button
                      onClick={() =>
                        handleDeleteImage(portfolio.portfolioFileKey)
                      }
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        minWidth: 'auto',
                        padding: 0,
                        borderRadius: '50%',
                        background: '#333',
                        color: 'white',
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Button>
                  </Box>
                ))}
              </div>
            ) : (
              <Typography variant="h6" color="gray">
                No photos yet. Upload your portfolio photos here.
              </Typography>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Save changes'}
        </Button>
      </div>
    </Container>
  );
};

export default PackageDetails;
