import { styled } from '@mui/material';

export const Container = styled('div')({
  margin: '3rem 9rem',
  '@media (max-width:600px)': {
    margin: '1rem 2rem',
  },
  '@media (max-width:480px)': {
    margin: '1rem',
  },
  '@media (max-width:1391px)': {
    margin: '1rem',
  },
  minHeight: '100vh',
});
