import React, { useState } from 'react';
import {
  Box,
  Button,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const Search = ({ onSearch, onEmptySearchInput, placeholderText }) => {
  const [keyword, setKeyword] = useState('');
  const isMobile = useMediaQuery('(max-width:660px)');

  const handleChange = (e) => {
    const { value } = e.target;
    if (value) setKeyword(value);
    else onEmptySearchInput();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(keyword);
  };

  return (
    <form
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: isMobile ? 0 : '2rem',
        alignItems: isMobile ? 'center' : 'baseline',
      }}
      onSubmit={handleSubmit}
    >
      <div style={{ flex: isMobile ? 150 : 5 }}>
        <OutlinedInput
          placeholder={placeholderText || 'Search..'}
          type="search"
          id="search"
          name="search"
          endAdornment={
            isMobile && (
              <Box onClick={handleSubmit}>
                <SearchIcon color="secondary" sx={{ fontSize: 32, mt: 1 }} />
              </Box>
            )
          }
          sx={{
            borderRadius: 4,
            border: 'none',
            width: '100%',
            marginBottom: 2,
            backgroundColor: '#373736', // set the background color
            '& label.Mui-focused': { color: '#008000' },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#008600',
            },
            '& input::placeholder': { color: '#7b7b7b' },
            '& input': { color: '#7b7b7b' },
          }}
          onChange={handleChange}
        />
      </div>
      {!isMobile && (
        <Button
          variant="contained"
          sx={{
            backgroundImage: '#a0e220',
            color: 'black',
            '&:hover': {
              backgroundImage: '#abe332',
            },
            fontWeight: 'bold',
            padding: '0.7rem 3rem',
            borderRadius: 4,
            ml: 2,
            height: '3.4rem',
          }}
          type="submit"
        >
          <Typography>Search</Typography>
        </Button>
      )}
      <div style={{ flex: 1 }}></div>
    </form>
  );
};

export default Search;
