import React, { useEffect, useState } from "react";
import TrainerCard from "../../components/trainer-card/TrainerCard";
import {
  Button,
  Grid,
  OutlinedInput,
  Pagination,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import axios from "axios";
import { getApi } from "../../utils/axios";
import { popAlert } from "../../utils/alert";
import Loader from "../../components/Loader/Loader";
import Search from "../../components/search/Search";

const Container = styled("div")({
  margin: "1rem 9rem",
  "@media (max-width:600px)": {
    margin: "1rem 2rem",
  },
  "@media (max-width:480px)": {
    margin: "1rem",
  },
});

const Trainers = () => {
  const [trainers, setTrainers] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleEmptySearchInput = () => {
    setKeyword("");
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    setIsLoading(true);
    getApi()
      .get(
        `/users/displayable-trainers?keyword=${keyword}&page=${page}&limit=10&orderBy=desc`
      )
      .then((res) => {
        setTrainers(res?.data?.content);
        setTotalPages(res?.data?.totalPages || 0);
        setIsLoading(false);
      })
      .catch((err) =>
        popAlert("Error!", err?.response?.data?.message, "error", "ok")
      );
    return () => {
      source.cancel();
    };
  }, [keyword, page]);

  return (
    <div>
      <Container>
        <Container>
          <Search
            placeholderText={"Search Trainers.."}
            onSearch={handleSearch}
            onEmptySearchInput={handleEmptySearchInput}
          />
        </Container>
        <Grid
          container
          spacing={5}
          justifyContent="center"
          alignItems="center"
          my={3}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {trainers &&
                trainers.map((trainer) => {
                  return (
                    <Grid item xs={6} md={6} lg={3} key={trainer?._id}>
                      <TrainerCard key={trainer?.id} trainer={trainer} />
                    </Grid>
                  );
                })}
              {trainers.length === 0 && (
                <Typography variant="h5" color={"white"}>
                  No Trainers
                </Typography>
              )}
            </>
          )}
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            fontWeight={"bold"}
            color="primary"
            shape="rounded"
          />
        </div>
      </Container>
    </div>
  );
};

export default Trainers;
