import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import React from 'react';
import ProfileImg from '../profile-card/ProfileImg';
import { useNavigate } from 'react-router-dom';
import { capitalizeWords, formatDate } from '../../utils/helpers';

const ClientCard = ({ client }) => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        maxWidth: 345,
        borderRadius: 5,
        border: '2px solid black',
        borderColor: '#373736',
      }}
    >
      <CardActionArea>
        <CardContent
          sx={{
            backgroundColor: '#1d1d1d',
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <ProfileImg
            data={{
              size: '60px',
              image: '',
              fullName: client?.fullName,
            }}
          />
          <CardContent
            sx={{
              minHeight: '5px',
              padding: 0,
              ml: '0.8rem',
            }}
          >
            <Typography variant="h6" color="#ffffff" fontWeight={700}>
              {capitalizeWords(client?.fullName)}
            </Typography>

            <Typography variant="body2" color="#939292">
              Age : {client?.age}
            </Typography>
          </CardContent>
        </CardContent>
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#363636',
          }}
        >
          <Typography gutterBottom variant="h7" color="#939292">
            Member Since :{formatDate(client?.createdAt)}
          </Typography>
          <Button
            size="small"
            style={{
              color: '#a0e220',
              border: '1px solid #a0e220',
              alignItems: 'center',
              borderRadius: '10px',
              padding: '5px 10px',
            }}
            onClick={() => navigate(`/client/${client?._id}`)}
          >
            <Typography
              gutterBottom
              variant="h7"
              color="#a0e220"
              sx={{ fontSize: '0.6rem', mt: 1 }}
            >
              View profile
            </Typography>
          </Button>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ClientCard;
