import Swal from 'sweetalert2';

export const popAlert = (title, text, icon, confirmButtonText) => {
  return Swal.fire({
    title: title,
    text: text,
    icon: icon,
    confirmButtonText: confirmButtonText,
    confirmButtonColor: '#00b5ad',
  });
};

export const deletePopAlert = async (
  title,
  text,
  icon,
  confirmButtonText,
  deletedText
) => {
  const result = await Swal.fire({
    title: title,
    text: text,
    icon: icon,
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '',
    confirmButtonText: confirmButtonText,
  });
  if (result.isConfirmed) {
    await Swal.fire('Deleting...', deletedText, 'info');
    return true; // User confirmed action
  } else {
    return false; // User cancelled action
  }
};
