import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import React from 'react';
import loadingImage from '../../assets/loading.gif';

const MealItemCard = ({ food, onFoodStep, quantity }) => {
  return (
    <Card
      sx={{
        backgroundColor: quantity === 0 ? '#373736' : '#B7FFC8',
        width: '100%',
        borderRadius: 3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '90%',
          alignItems: 'center',
        }}
      >
        <Box sx={{ marginRight: '1rem' }}>
          <CardMedia
            component="img"
            height="70"
            width="70"
            image={food.url || loadingImage}
            alt={food?.name}
            style={{ objectFit: 'cover' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="h6"
            fontWeight={'bold'}
            color={quantity === 0 ? 'whitesmoke' : 'black'}
          >
            {food?.name}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 3,
              mt: 1,
            }}
          >
            <Typography
              variant="h7"
              color={quantity === 0 ? 'whitesmoke' : 'black'}
            >
              Calories:
              <span style={{ fontWeight: 'bold' }}> {food?.calPerUnit}</span>
            </Typography>
            <Typography
              variant="h7"
              color={quantity === 0 ? 'whitesmoke' : 'black'}
            >
              Carbs:
              <span style={{ fontWeight: 'bold' }}>
                {Number(food?.carbs).toFixed(2) || 'N / A'}
              </span>
            </Typography>
            <Typography
              variant="h7"
              color={quantity === 0 ? 'whitesmoke' : 'black'}
            >
              Protein:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                {Number(food?.protein).toFixed(2) || 'N / A'}
              </span>
            </Typography>
            <Typography
              variant="h7"
              color={quantity === 0 ? 'whitesmoke' : 'black'}
            >
              Fat:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                {Number(food?.fats)?.toFixed(2) || 'N / A'}
              </span>
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardContent>
        <Typography>
          <OutlinedInput
            value={quantity || ''}
            type="number"
            endAdornment={
              <InputAdornment position="end">
                <span
                  style={{ color: quantity === 0 ? 'whitesmoke' : 'black' }}
                >
                  {food?.unit}
                </span>
              </InputAdornment>
            }
            inputProps={{
              'aria-label': 'count',
            }}
            style={{
              border: quantity === 0 ? '1px solid white' : '1px solid black',
              color: quantity === 0 ? 'whitesmoke' : 'black',
              width: '12rem',
              fontSize: '1rem',
            }}
            onChange={(e) => onFoodStep(food?._id, e.target.value || 0)}
          />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MealItemCard;
