import React, { useEffect, useState } from 'react';
import ClientDashboardContainer from '../containers/ClientDashboardContainer';
import { getApi } from '../../../utils/axios';
import axios from 'axios';
import { popAlert } from '../../../utils/alert';

const ExternalClientsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [externalClients, setExternalClients] = useState([]);
  const props = {
    type: 'External',
    clients: externalClients,
    isLoading: isLoading,
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    setIsLoading(true);
    //
    getApi()
      .get('/external-client')
      .then((res) => {
        setExternalClients(res?.data?.data);
      })
      .catch((err) => {
        popAlert('Error!', err?.response?.data?.message, 'error', 'ok');
      })
      .finally(setIsLoading(false));
    return () => {
      source.cancel();
    };
  }, []);

  return <ClientDashboardContainer {...props} />;
};

export default ExternalClientsPage;
